// @flow
import { matchPath } from 'react-router-dom';

import reSelect from './libs/re-select';

type TGetCurrent = ($ReadOnly<{ router: { location: { pathname: string } } }>) => ?string;

export const getCurrentProject: TGetCurrent = reSelect(
	({ router }) => router.location.pathname,
	pathname => {
		const { params: { projectId } = {} } =
			matchPath(pathname, {
				path: '/project/:projectId',
			}) || {};

		return projectId;
	},
)(() => `router@getCurrentProject`);

export const getCurrentPage: TGetCurrent = reSelect(
	({ router }) => router.location.pathname,
	pathname => {
		const { params: { pageId } = {} } =
			matchPath(pathname, {
				path: '/project/:projectId/site/:siteId/page/:pageId',
			}) || {};

		return pageId;
	},
)(() => `router@getCurrentPage`);

export const getCurrentSite: TGetCurrent = reSelect(
	({ router }) => router.location.pathname,
	pathname => {
		const { params: { siteId } = {} } =
			matchPath(pathname, {
				path: '/project/:projectId/site/:siteId',
			}) || {};

		return siteId;
	},
)(() => `router@getCurrentSite`);

export default {};
