// @flow

const palette = {
	black: '#000000',
	white: '#FFFFFF',
	lightblue10: '#FAFCFD',
	lightblue20: '#ECF2F5',
	lightblue30: '#DFE7EC',
	lightblue40: '#D2DCE2',
	lightblue50: '#C5CED6',
	lightblue60: '#B5C0CC',
	lightblue70: '#A5B1C0',
	lightblue80: '#939FB1',
	lightblue90: '#7F8A9E',
	darkblue10: '#4A5060',
	darkblue20: '#464B5B',
	darkblue30: '#414555',
	darkblue40: '#3C3F4F',
	darkblue50: '#363847',
	darkblue60: '#2E3040',
	darkblue70: '#252634',
	darkblue80: '#191925',
	darkblue90: '#020203',
	blue10: '#299BFF',
	blue20: '#578CFF',
	blue30: '#507FFF',
	blue40: '#4870FF',
	blue50: '#3D5EFF',
	blue60: '#334AF4',
	blue70: '#2834DD',
	blue80: '#1A1DAE',
	blue90: '#17145B',
	violet10: '#F78DFF',
	violet20: '#F47DFF',
	violet30: '#F06AFF',
	violet40: '#EB53FF',
	violet50: '#E433FF',
	violet60: '#CF2CEC',
	violet70: '#B724D4',
	violet80: '#9A1CB6',
	violet90: '#74148C',
	green10: '#AFEFCA',
	green20: '#8FE9BB',
	green30: '#6AE1AC',
	green40: '#43D89A',
	green50: '#14CC86',
	green60: '#09BC7E',
	green70: '#00AA74',
	green80: '#009569',
	green90: '#007C5A',
	gold10: '#FDF6C2',
	gold20: '#FBEB91',
	gold30: '#F9DD6D',
	gold40: '#F7CD4E',
	gold50: '#F5BA31',
	gold60: '#EEA620',
	gold70: '#E59010',
	gold80: '#D87701',
	gold90: '#C15B00',
	red10: '#FC97B2',
	red20: '#FC89A4',
	red30: '#FB7993',
	red40: '#FB657E',
	red50: '#FA4B62',
	red60: '#E54253',
	red70: '#CC3943',
	red80: '#AE2F33',
	red90: '#842222',
	wood20: '#E8D192',
	wood30: '#DBBC7D',
	wood40: '#C39D63',
	wood50: '#A87C48',
	wood60: '#8F5E33',
	banana20: '#FFFF6D',
	banana30: '#FFF14F',
	banana40: '#FFCB30',
	banana50: '#E6A420',
	banana60: '#CC7F12',
	lemon20: '#FFFF76',
	lemon30: '#F4FF3C',
	lemon40: '#CDFF06',
	lemon50: '#AFEB00',
	lemon60: '#92D600',
	tiffany20: '#36D6C9',
	tiffany30: '#25CFB8',
	tiffany40: '#13BD9E',
	tiffany50: '#0AA881',
	tiffany60: '#039466',
	cyan20: '#24F0F0',
	cyan30: '#1ADAE8',
	cyan40: '#0BBBD6',
	cyan50: '#029BC2',
	cyan60: '#007EAD',
	royal20: '#9DC8FF',
	royal30: '#7EA5FF',
	royal40: '#5C7AFF',
	royal50: '#475CE6',
	royal60: '#3542CC',
	navy20: '#CE7BFF',
	navy30: '#AB62FF',
	navy40: '#864BFF',
	navy50: '#6A39E6',
	navy60: '#502BCC',
	fuchsia20: '#FF62C0',
	fuchsia30: '#FF55B0',
	fuchsia40: '#FF3B93',
	fuchsia50: '#E62978',
	fuchsia60: '#CC165C',
	neon20: '#FF6395',
	neon30: '#FF537B',
	neon40: '#FA3757',
	neon50: '#E0263F',
	neon60: '#BD1324',
	pumpkin20: '#FFBD7F',
	pumpkin30: '#FF9A60',
	pumpkin40: '#FA6F41',
	pumpkin50: '#E0532F',
	pumpkin60: '#C73920',
	facebook: '#3B579D',
	goolgleplus: '#DC4E41',
	linkedin: '#0077B5',
	livejournal: '#11A3DC',
	moymir: '#168DE2',
	ok: '#EE8208',
	pinterest: '#BD081C',
	tumblr: '#304E6C',
	twitter: '#1DA1F2',
	usocial: '#488BFA',
	vkontakte: '#5181B8',
};

const breakpoints = ['768px', '992px', '1200px'];

const fonts = {
	headings: 'Epilogue, sans-serif',
	body: 'PTRootUIWeb, sans-serif',
};

const fontSizes = {
	// headings
	hero1: 84,
	hero2: 60,
	hero3: 50,
	title1: 42,
	title2: 35,
	title3: 29,
	title4: 24,
	// body
	bodylg: 20,
	bodymd: 17,
	bodysm: 14,
	captionlg: 13,
	captionmd: 12,
};

const fontWeights = {
	hero: 900,
	title: 800,
	headline: 700,
};

const fontFeaturesHeadings = {
	WebkitFontSmoothing: 'antialiased',
};

const fontFeaturesBody = {
	WebkitFontSmoothing: 'antialiased',
	fontFeatureSettings:
		"'liga', 'dlig', 'ss01' 0, 'ss02', 'ss03', 'ss04','ss05' 0, 'kern'",
};

const text = {
	// headings

	hero1: {
		fontFamily: 'headings',
		fontWeight: 'hero',
		fontSize: 'hero1',
		lineHeight: '96px',
		...fontFeaturesHeadings,
	},
	hero2: {
		fontFamily: 'headings',
		fontWeight: 'hero',
		fontSize: 'hero2',
		lineHeight: '66px',
		...fontFeaturesHeadings,
	},
	hero3: {
		fontFamily: 'headings',
		fontWeight: 'hero',
		fontSize: 'hero3',
		lineHeight: '57px',
		...fontFeaturesHeadings,
	},
	title1: {
		fontFamily: 'headings',
		fontWeight: 'title',
		fontSize: 'title1',
		lineHeight: '48px',
		...fontFeaturesHeadings,
	},
	title2: {
		fontFamily: 'headings',
		fontWeight: 'title',
		fontSize: 'title2',
		lineHeight: '42px',
		...fontFeaturesHeadings,
	},
	title3: {
		fontFamily: 'headings',
		fontWeight: 'title',
		fontSize: 'title3',
		lineHeight: '39px',
		...fontFeaturesHeadings,
	},
	title4: {
		fontFamily: 'headings',
		fontWeight: 'title',
		fontSize: 'title4',
		lineHeight: '33px',
		...fontFeaturesHeadings,
	},
	title5: {
		fontFamily: 'headings',
		fontWeight: 'title',
		fontSize: 'bodylg',
		lineHeight: '27px',
		...fontFeaturesHeadings,
	},
	title6: {
		fontFamily: 'headings',
		fontWeight: 'title',
		fontSize: 'bodymd',
		lineHeight: '24px',
		...fontFeaturesHeadings,
	},

	// emphasis

	leadmd: {
		fontFamily: 'body',
		fontSize: 'title3',
		lineHeight: '39px',
		...fontFeaturesBody,
	},
	leadsm: {
		fontFamily: 'body',
		fontSize: 'title4',
		lineHeight: '33px',
		...fontFeaturesBody,
	},
	headlinelg: {
		fontFamily: 'body',
		fontStyle: 'normal',
		fontWeight: 'headline',
		fontSize: 'bodylg',
		lineHeight: '27px',
		...fontFeaturesBody,
	},
	headlinemd: {
		fontFamily: 'body',
		fontWeight: 'headline',
		fontSize: 'bodymd',
		lineHeight: '24px',
		...fontFeaturesBody,
	},
	headlinesm: {
		fontFamily: 'body',
		fontWeight: 'headline',
		fontSize: 'bodysm',
		lineHeight: '18px',
		...fontFeaturesBody,
	},
	captionlg: {
		fontFamily: 'body',
		fontSize: 'captionlg',
		fontWeight: 'headline',
		lineHeight: '18px',
		...fontFeaturesBody,
	},
	captionmd: {
		fontFamily: 'body',
		fontSize: 'captionmd',
		fontWeight: 'headline',
		lineHeight: '15px',
		...fontFeaturesBody,
	},

	// body

	bodylg: {
		fontFamily: 'body',
		fontSize: 'bodylg',
		lineHeight: '27px',
		...fontFeaturesBody,
	},
	bodymd: {
		fontFamily: 'body',
		fontSize: 'bodymd',
		lineHeight: '24px',
		...fontFeaturesBody,
	},
	bodysm: {
		fontFamily: 'body',
		fontSize: 'bodysm',
		lineHeight: '18px',
		...fontFeaturesBody,
	},
	notelg: {
		fontFamily: 'body',
		fontSize: 'captionlg',
		lineHeight: '18px',
		...fontFeaturesBody,
	},
	notemd: {
		fontFamily: 'body',
		fontSize: 'captionmd',
		lineHeight: '15px',
		...fontFeaturesBody,
	},
};

const shadows = {
	lg: '0px 20px 40px rgba(0, 0, 0, 0.1)',
	md: '0px 10px 20px rgba(0, 0, 0, 0.1)',
	sm: `0px 0px 1px 0px rgba(0, 0, 0, 0.14),
		0px 6px 12px 0px rgba(0, 0, 0, 0.06),
		0px 9px 24px 3px rgba(0, 0, 0, 0.02)`,
	xsm: '0px 3px 7px rgba(41, 155, 255, 0.2)',
	lgviolet: '0px 20px 40px rgba(199, 76, 255, 0.2)',
	mdviolet: '0px 10px 20px rgba(199, 76, 255, 0.2)',
	smviolet: '0px 5px 10px rgba(199, 76, 255, 0.2)',
	smblue: `0px 0px 1px 0px rgba(0, 0, 0, 0.14),
		0px 4px 12px 0px rgba(41, 155, 255, 0.06),
		0px 8px 21px 3px rgba(0, 0, 0, 0.02)`,
};

const radii = {
	rounded: {
		all: '999px',
		top: '999px 999px 0 0',
		bottom: '0 0 999px 999px',
		left: '999px 0 0 999px',
		right: '0 999px 999px 0',
	},
	lg: {
		all: '12px',
		top: '12px 12px 0 0',
		bottom: '0 0 12px 12px',
		left: '12px 0 0 12px',
		right: '0 12px 12px 0',
	},
	md: {
		all: '6px',
		top: '6px 6px 0 0',
		bottom: '0 0 6px 6px',
		left: '6px 0 0 6px',
		right: '0 6px 6px 0',
	},
	sm: {
		all: '4px',
		top: '4px 4px 0 0',
		bottom: '0 0 4px 4px',
		left: '4px 0 0 4px',
		right: '0 4px 4px 0',
	},
};

function createButtonVariations(buttonColor) {
	//
	// функция которая создает варианты формы и размера для каждой цветовой схемы кнопки
	// в качестве аргумента выступает цветовая схема, которая задается ниже
	//
	// варианты используются в компонентах по шаблону "цвет.форма.размер", например
	// variant="primary.rounded.sm"
	//
	// также сработает такой вариант (размер будет md):
	// variant="primary.rounded"
	//
	// также сработает такой вариант (размер будет md, а форма normal):
	// variant="primary"
	//
	// также сработает такой вариант (форма будет normal):
	// variant="primary.lg"
	//
	const buttonVariation = {
		// дефолтный стиль и размер для упрощенного использования кнопки
		// совпадает с normal md
		variant: buttonColor,
		...text.headlinemd,
		height: '36px',
		padding: '0 16px', // -2px from border
		borderRadius: 'md.all',

		// дефолтные размеры для упрощенного использования кнопки
		// совпадает с normal
		lg: {
			variant: buttonColor,
			...text.headlinemd,
			height: '42px',
			padding: '0 19px', // -2px from border
			borderRadius: 'md.all',
		},
		md: {
			variant: buttonColor,
			...text.headlinemd,
			height: '36px',
			padding: '0 16px', // -2px from border
			borderRadius: 'md.all',
		},
		sm: {
			variant: buttonColor,
			...text.headlinesm,
			height: '30px',
			padding: '0 13px', // -2px from border
			borderRadius: 'md.all',
		},
		normal: {
			// дефолтный размер для упрощенного использования, совпадает с md
			variant: buttonColor,
			...text.headlinemd,
			height: '36px',
			padding: '0 16px', // -2px from border
			borderRadius: 'md.all',
			lg: {
				variant: buttonColor,
				...text.headlinemd,
				height: '42px',
				padding: '0 19px', // -2px from border
				borderRadius: 'md.all',
			},
			md: {
				variant: buttonColor,
				...text.headlinemd,
				height: '36px',
				padding: '0 16px', // -2px from border
				borderRadius: 'md.all',
			},
			sm: {
				variant: buttonColor,
				...text.headlinesm,
				height: '30px',
				padding: '0 13px', // -2px from border
				borderRadius: 'md.all',
			},
		},
		rounded: {
			// дефолтный размер для упрощенного использования, совпадает с md
			variant: buttonColor,
			...text.headlinemd,
			height: '36px',
			padding: '0 19px', // -2px from border
			borderRadius: 'rounded.all',
			lg: {
				variant: buttonColor,
				...text.headlinemd,
				height: '42px',
				padding: '0 23px', // -2px from border
				borderRadius: 'rounded.all',
			},
			md: {
				variant: buttonColor,
				...text.headlinemd,
				height: '36px',
				padding: '0 19px', // -2px from border
				borderRadius: 'rounded.all',
			},
			sm: {
				variant: buttonColor,
				...text.headlinesm,
				height: '30px',
				padding: '0 16px', // -2px from border
				borderRadius: 'rounded.all',
			},
		},
		flat: {
			// дефолтный размер для упрощенного использования, совпадает с md
			variant: buttonColor,
			...text.headlinemd,
			height: '36px',
			padding: '0',
			lg: {
				variant: buttonColor,
				...text.headlinemd,
				height: '42px',
				padding: '0',
			},
			md: {
				variant: buttonColor,
				...text.headlinemd,
				height: '36px',
				padding: '0',
			},
			sm: {
				variant: buttonColor,
				...text.headlinesm,
				height: '30px',
				padding: '0',
			},
		},
		icon: {
			// дефолтный размер для упрощенного использования, совпадает с md
			variant: buttonColor,
			padding: '0',
			height: '36px',
			width: '36px',
			lg: {
				variant: buttonColor,
				padding: '0',
				height: '42px',
				width: '42px',
			},
			md: {
				variant: buttonColor,
				padding: '0',
				height: '36px',
				width: '36px',
			},
			sm: {
				variant: buttonColor,
				padding: '0',
				height: '30px',
				width: '30px',
			},
		},
		iconrounded: {
			// дефолтный размер для упрощенного использования, совпадает с md
			variant: buttonColor,
			borderRadius: 'rounded.all',
			padding: '0',
			height: '36px',
			width: '36px',
			lg: {
				variant: buttonColor,
				borderRadius: 'rounded.all',
				padding: '0',
				height: '42px',
				width: '42px',
			},
			md: {
				variant: buttonColor,
				borderRadius: 'rounded.all',
				padding: '0',
				height: '36px',
				width: '36px',
			},
			sm: {
				variant: buttonColor,
				borderRadius: 'rounded.all',
				padding: '0',
				height: '30px',
				width: '30px',
			},
		},
	};

	return buttonVariation;
}

const buttons = {
	// общие стили
	// не предназначен дл прямого использования в компонентах
	default: {
		display: 'flex', // для правильного отображения как <a>
		alignItems: 'center', // для правильного отображения как <a>
		justifyContent: 'center', // для выравнивания иконок

		borderWidth: '2px',
		borderColor: 'transparent',
		borderStyle: 'solid',
		outline: 'none',

		transitionProperty: 'color, background, borderColor, opacity',
		transitionTimingFunction: 'ease-out',
		transitionDuration: '0.25s',
		svg: {
			transitionProperty: 'fill, opacity',
			transitionTimingFunction: 'ease-out',
			transitionDuration: '0.25s',
		},
		cursor: 'pointer',
	},

	// цветовые схемы для создания цветовых вариантов
	// используются в качестве аругмента для функции
	// не предназначены для прямого использования в компонентах

	primaryColor: {
		variant: 'buttons.default',
		bg: 'bg.primaryalt',
		color: 'text.primary',
		svg: {
			fill: 'text.primary',
		},

		':hover': {
			bg: 'bg.primaryaltplus',
		},

		':active': {
			bg: 'bg.primaryaltminus',
		},
	},

	primaryAltColor: {
		variant: 'buttons.default',
		bg: 'bg.primaryalt',
		color: 'text.primaryalt',
		svg: {
			fill: 'text.primaryalt',
		},

		':hover': {
			bg: 'bg.primaryaltplus',
		},

		':active': {
			bg: 'bg.primaryaltminus',
		},
	},

	secondaryColor: {
		variant: 'buttons.default',
		bg: 'bg.primaryalt',
		color: 'text.secondary',
		svg: {
			fill: 'text.secondary',
		},

		':hover': {
			bg: 'bg.primaryaltplus',
		},

		':active': {
			bg: 'bg.primaryaltminus',
		},
	},

	whiteColor: {
		variant: 'buttons.default',
		bg: 'spec.white',
		color: 'spec.darkblue60',
		svg: {
			fill: 'spec.darkblue60',
		},

		':hover': {
			bg: 'spec.lightblue30',
		},

		':active': {
			bg: 'spec.lightblue60',
		},
	},

	accentColor: {
		variant: 'buttons.default',
		bg: 'bg.accent',
		color: 'text.oncolor',
		svg: {
			fill: 'text.oncolor',
		},

		':hover': {
			bg: 'bg.accentplus',
		},

		':active': {
			bg: 'bg.accentminus',
		},
	},

	successColor: {
		variant: 'buttons.default',
		bg: 'bg.success',
		color: 'text.oncolor',
		svg: {
			fill: 'text.oncolor',
		},

		':hover': {
			bg: 'bg.successplus',
		},

		':active': {
			bg: 'bg.successminus',
		},
	},

	warningColor: {
		variant: 'buttons.default',
		bg: 'bg.warning',
		color: 'spec.darkblue80',
		svg: {
			fill: 'spec.darkblue80',
		},

		':hover': {
			bg: 'bg.warningplus',
		},

		':active': {
			bg: 'bg.warningminus',
		},
	},

	errorColor: {
		variant: 'buttons.default',
		bg: 'bg.error',
		color: 'text.oncolor',
		svg: {
			fill: 'text.oncolor',
		},

		':hover': {
			bg: 'bg.errorplus',
		},

		':active': {
			bg: 'bg.errorminus',
		},
	},

	primaryGhostColor: {
		variant: 'buttons.default',
		bg: 'transparent',
		borderColor: 'bg.secondary',
		color: 'text.primary',
		svg: {
			fill: 'text.primary',
		},

		':hover': {
			bg: 'bg.secondary',
		},

		':active': {
			bg: 'bg.secondaryminus',
		},
	},

	primaryAltGhostColor: {
		variant: 'buttons.default',
		bg: 'transparent',
		borderColor: 'bg.secondary',
		color: 'text.primaryalt',
		svg: {
			fill: 'text.primaryalt',
		},

		':hover': {
			bg: 'bg.secondary',
		},

		':active': {
			bg: 'bg.secondaryminus',
		},
	},

	accentGhostColor: {
		variant: 'buttons.default',
		bg: 'transparent',
		borderColor: 'bg.accent',
		color: 'text.primaryalt',
		svg: {
			fill: 'text.primaryalt',
		},

		':hover': {
			bg: 'bg.accent',
			color: 'text.oncolor',
			svg: {
				fill: 'text.oncolor',
			},
		},

		':active': {
			bg: 'bg.accentminus',
			borderColor: 'bg.accentminus',
			color: 'text.oncolor',
			svg: {
				fill: 'text.oncolor',
			},
		},
	},

	whiteGhostColor: {
		variant: 'buttons.default',
		bg: 'transparent',
		borderColor: 'spec.white',
		color: 'spec.white',
		svg: {
			fill: 'spec.white',
		},
		':hover': {
			opacity: 0.75,
		},
		':active': {
			opacity: 0.33,
		},
	},

	primaryFlatColor: {
		variant: 'buttons.default',
		bg: 'transparent',
		color: 'text.primary',
		svg: {
			fill: 'text.primary',
		},
		':hover': {
			color: 'text.accent',
			svg: {
				fill: 'text.accent',
			},
		},
		':active': {
			color: 'text.accentminus',
			svg: {
				fill: 'text.accentminus',
			},
		},
	},

	primaryAltFlatColor: {
		variant: 'buttons.default',
		bg: 'transparent',
		color: 'text.primaryalt',
		svg: {
			fill: 'text.primaryalt',
		},
		':hover': {
			color: 'text.accent',
			svg: {
				fill: 'text.accent',
			},
		},
		':active': {
			color: 'text.accentminus',
			svg: {
				fill: 'text.accentminus',
			},
		},
	},

	secondaryFlatColor: {
		variant: 'buttons.default',
		bg: 'transparent',
		color: 'text.secondary',
		svg: {
			fill: 'text.secondary',
		},
		':hover': {
			color: 'text.accent',
			svg: {
				fill: 'text.accent',
			},
		},
		':active': {
			color: 'text.accentminus',
			svg: {
				fill: 'text.accentminus',
			},
		},
	},

	tertiaryFlatColor: {
		variant: 'buttons.default',
		bg: 'transparent',
		color: 'text.tertiary',
		svg: {
			fill: 'text.tertiary',
		},
		':hover': {
			color: 'text.accent',
			svg: {
				fill: 'text.accent',
			},
		},
		':active': {
			color: 'text.accentminus',
			svg: {
				fill: 'text.accentminus',
			},
		},
	},

	accentFlatColor: {
		variant: 'buttons.default',
		bg: 'transparent',
		color: 'text.accent',
		svg: {
			fill: 'text.accent',
		},
		':hover': {
			color: 'text.accentplus',
			svg: {
				fill: 'text.accentplus',
			},
		},
		':active': {
			color: 'text.accentminus',
			svg: {
				fill: 'text.accentminus',
			},
		},
	},

	whiteFlatColor: {
		variant: 'buttons.default',
		bg: 'transparent',
		color: 'spec.white',
		svg: {
			fill: 'spec.white',
		},
		':hover': {
			opacity: 0.75,
		},
		':active': {
			opacity: 0.33,
		},
	},

	// создаем вариации, предназначенные для использования в компонентах

	primary: {
		...createButtonVariations('buttons.primaryColor'),
	},
	primaryalt: {
		...createButtonVariations('buttons.primaryAltColor'),
	},
	secondary: {
		...createButtonVariations('buttons.secondaryColor'),
	},
	white: {
		...createButtonVariations('buttons.whiteColor'),
	},
	accent: {
		...createButtonVariations('buttons.accentColor'),
	},
	success: {
		...createButtonVariations('buttons.successColor'),
	},
	warning: {
		...createButtonVariations('buttons.warningColor'),
	},
	error: {
		...createButtonVariations('buttons.errorColor'),
	},
	primaryghost: {
		...createButtonVariations('buttons.primaryGhostColor'),
	},
	primaryaltghost: {
		...createButtonVariations('buttons.primaryAltGhostColor'),
	},
	accentghost: {
		...createButtonVariations('buttons.accentGhostColor'),
	},
	whiteghost: {
		...createButtonVariations('buttons.whiteGhostColor'),
	},
	primaryflat: {
		...createButtonVariations('buttons.primaryFlatColor'),
	},
	primaryaltflat: {
		...createButtonVariations('buttons.primaryAltFlatColor'),
	},
	secondaryflat: {
		...createButtonVariations('buttons.secondaryFlatColor'),
	},
	tertiaryflat: {
		...createButtonVariations('buttons.tertiaryFlatColor'),
	},
	accentflat: {
		...createButtonVariations('buttons.accentFlatColor'),
	},
	whiteflat: {
		...createButtonVariations('buttons.whiteFlatColor'),
	},
};

const inputVariantBase = {
	color: 'text.primary',
	bg: 'bg.primaryalt',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderRadius: 'md.all',
	borderColor: 'transparent',
	p: '10px',
	pt: '0px',
	pb: '0px',
	lineHeight: '36px',
	height: '36px',
	outline: 'none',
	transitionProperty: 'all',
	transitionTimingFunction: 'ease-out',
	transitionDuration: '0.25s',

	':hover': {
		bg: 'bg.primaryaltplus',
	},
	':active': {
		bg: 'transparent',
		borderColor: 'bg.accent',
	},
	':focus': {
		bg: 'transparent',
		borderColor: 'bg.accent',
	},

	'::placeholder': {
		color: 'text.secondary',
	},
};

const variants = {
	input: {
		...text.bodymd,
		...inputVariantBase,
		lg: {
			...inputVariantBase,
			...text.bodymd,
			p: '13px',
			lineHeight: '42px',
			height: '42px',
		},
		md: {
			...inputVariantBase,
			...text.bodymd,
			p: '10px',
			lineHeight: '36px',
			height: '36px',
		},
		sm: {
			...inputVariantBase,
			...text.bodysm,
			p: '7px',
			lineHeight: '30px',
			height: '30px',
		},
	},

	switch: {
		appearance: 'none',
		outline: 'none',
		cursor: 'pointer',
		m: 0,
		p: 0,
		bg: 'bg.secondary',
		height: '18px',
		borderWidth: 0,
		borderRadius: 'lg.all',
		width: '30px',
		'&[aria-checked=true]': {
			bg: 'bg.accent',
		},
		div: {
			marginLeft: '4px',
			width: 10,
			height: 10,
			bg: 'bg.primary',
			borderRadius: 'rounded.all',
			transitionProperty: 'transform',
			transitionTimingFunction: 'ease-out',
			transitionDuration: '0.1s',
		},
	},

	link: {
		textDecoration: 'none',
		color: 'text.accent',
		transitionProperty: 'color',
		transitionTimingFunction: 'ease-out',
		transitionDuration: '0.15s',
		':hover': {
			color: 'text.accentplus',
		},
		':active': {
			color: 'text.accentminus',
		},
	},

	grid: {
		marginRight: '-18px',
		marginLeft: '-18px',
		column: {
			paddingRight: '18px',
			paddingLeft: '18px',
		},
	},

	container: {
		width: '980px',
		paddingRight: '36px',
		paddingLeft: '36px',
		fluid: {
			paddingRight: '36px',
			paddingLeft: '36px',
		},
		negative: {
			marginRight: '-36px',
			marginLeft: '-36px',
		},
	},
};

export const light = {
	colors: {
		spec: palette,
		text: {
			// main neutral
			primary: palette.darkblue40,
			primaryalt: palette.darkblue80,
			secondary: palette.lightblue90,
			tertiary: palette.lightblue60,
			oncolor: palette.lightblue10,
			// main accents
			accent: palette.blue50,
			success: palette.green60,
			warning: palette.gold70,
			error: palette.red50,
			// main accents plus - for hovers and etc
			accentplus: palette.blue20,
			successplus: palette.green30,
			warningplus: palette.gold50,
			errorplus: palette.red30,
			// main accents minus - for click and etc
			accentminus: palette.blue70,
			successminus: palette.green90,
			warningminus: palette.gold90,
			errorminus: palette.red90,
		},
		bg: {
			// main
			primary: palette.lightblue10,
			primaryalt: palette.lightblue20,
			secondary: palette.lightblue30,
			accent: palette.blue40,
			success: palette.green50,
			warning: palette.gold50,
			error: palette.red50,
			// main accents plus - for hovers and etc
			primaryplus: palette.white,
			primaryaltplus: palette.lightblue30,
			secondaryplus: palette.lightblue40,
			accentplus: palette.blue10,
			successplus: palette.green30,
			warningplus: palette.gold30,
			errorplus: palette.red40,
			// main accents minus - for click and etc
			primaryminus: palette.lightblue20,
			primaryaltminus: palette.lightblue40,
			secondaryminus: palette.lightblue50,
			accentminus: palette.blue70,
			successminus: palette.green70,
			warningminus: palette.gold70,
			errorminus: palette.red70,
		},
	},
	fonts,
	fontSizes,
	fontWeights,
	text,
	shadows,
	radii,
	buttons,
	variants,
	breakpoints,
};

export const dark = {
	colors: {
		spec: palette,
		text: {
			// main neutral
			primary: palette.lightblue60,
			primaryalt: palette.lightblue20,
			secondary: palette.lightblue90,
			tertiary: palette.darkblue10,
			oncolor: palette.lightblue10,
			// main accents
			accent: palette.blue30,
			success: palette.green50,
			warning: palette.gold50,
			error: palette.red50,
			// main accents plus - for hovers and etc
			accentplus: palette.blue10,
			successplus: palette.green30,
			warningplus: palette.gold20,
			errorplus: palette.red30,
			// main accents minus - for click and etc
			accentminus: palette.blue70,
			successminus: palette.green70,
			warningminus: palette.gold80,
			errorminus: palette.red70,
		},
		bg: {
			// main
			primary: palette.darkblue80,
			primaryalt: palette.darkblue70,
			secondary: palette.darkblue90,
			accent: palette.blue60,
			success: palette.green60,
			warning: palette.gold50,
			error: palette.red50,
			// main accents plus - for hovers and etc
			primaryplus: palette.darkblue70,
			primaryaltplus: palette.darkblue60,
			secondaryplus: palette.darkblue80,
			accentplus: palette.blue40,
			successplus: palette.green40,
			warningplus: palette.gold30,
			errorplus: palette.red40,
			// main accents minus - for click and etc
			primaryminus: palette.darkblue90,
			primaryaltminus: palette.darkblue80,
			secondaryminus: palette.black,
			accentminus: palette.blue80,
			successminus: palette.green70,
			warningminus: palette.gold80,
			errorminus: palette.red70,
		},
	},
	fonts,
	fontSizes,
	fontWeights,
	text,
	shadows,
	radii,
	buttons,
	variants,
	breakpoints,
};

export default {};
