// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash/fp';
import { Flex, Box } from '@graphite/uneon';
import type { TId } from '@graphite/types';
import { getProjects } from '@graphite/selectors';
import { useDispatch } from '@graphite/use-redux';

import {
	fetchIfNeeded as fetchIfNeededProject,
	insertProject as insertProjectAction,
	removeProject as removeProjectAction,
} from 'Editor/ducks/projects';
import { fetchIfNeeded as fetchIfNeededSite } from 'Editor/ducks/sites';
import {
	insertSite as insertSiteAction,
	removeSite as removeSiteAction,
} from 'Editor/ducks/projectsSites';
import Header from './Header';
import Project from './Project';
import Footer from './Footer';
import EmptyProject from './EmptyProject';

type TProps = $ReadOnly<{|
	user: $ReadOnly<{
		_id: TId,
	}>,
	logOut: () => void,
|}>;

// push footer to bottom

const bodySx = {
	minHeight: '100vh',
	flexDirection: 'column',
	backgroundColor: 'bg.primary',
};

const bottomSx = {
	marginTop: 'auto',
};

// main layout

const contentSx = {
	marginTop: '30px',
	marginBottom: '60px',
};

const projectSx = {
	marginBottom: '60px',
	':last-of-type': {
		marginBottom: '0px',
	},
};

const Dashboard = ({ logOut, user: { _id } }: TProps) => {
	const dispatch = useDispatch();
	const projects = useSelector(getProjects);

	React.useEffect(() => {
		dispatch(fetchIfNeededProject(_id));
		dispatch(fetchIfNeededSite(_id));

		return () => {
			dispatch(fetchIfNeededProject(null));
			dispatch(fetchIfNeededSite(null));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_id]);

	const insertProject = React.useCallback(() => {
		dispatch(insertProjectAction(_id));
	}, [_id, dispatch]);

	const removeProject = React.useCallback(
		id => {
			dispatch(removeProjectAction(id));
			// FixMe: всем сайтам проекта нужно проставить removeAt
		},
		[dispatch],
	);

	const insertSite = React.useCallback(
		projectId => {
			dispatch(insertSiteAction(_id, projectId));
		},
		[_id, dispatch],
	);

	const removeSite = React.useCallback(
		(projectId, siteId) => {
			dispatch(removeSiteAction(projectId, siteId));
		},
		[dispatch],
	);

	return (
		<Flex sx={bodySx}>
			<Header logOut={logOut} insertProject={insertProject} />

			<Box sx={contentSx} variant="container.fluid">
				{_.isEmpty(projects) ? (
					<EmptyProject insertProject={insertProject} />
				) : (
					_.map(
						project => (
							<Box key={project._id} sx={projectSx}>
								<Project
									project={project}
									removeProject={removeProject}
									insertSite={insertSite}
									removeSite={removeSite}
								/>
							</Box>
						),
						projects,
					)
				)}
			</Box>

			<Box sx={bottomSx}>
				<Footer />
			</Box>
		</Flex>
	);
};

export default React.memo<TProps>(Dashboard);
