// @flow

import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'emotion-theming';
import { Button, PopupWhite, themes, Flex } from '@graphite/uneon';
import type { TEditorLang } from '@graphite/types';
import { useSelector } from '@graphite/use-redux';
import { getLanguage, getGridHighlight } from 'Editor/selectors/editor';
import Design from './Design';
import Layers from './Layers';
import ButtonsHistory from './ButtonsHistory';
import ButtonsDevices from './ButtonsDevices';
import ButtonsPanels from './ButtonsPanels';
import ButtonExtra from './ButtonExtra';
import ButtonPublish from './ButtonPublish';
import Burger from './Burger';

const panels = [
	{ title: 'Design', value: 'design' },
	{ title: 'Layers', value: 'layers' },
];

type TProps = $ReadOnly<{|
	goToPreview: () => void,
	panelRef: {| current: ?React$ElementRef<any> |},
	goToDashboard: () => void,
	publishUrl: string,
|}>;

const HEIGHT = 54;
const PANEL_DX = 12;
const PANEL_DY = 12;

const flexLeftSx = {
	flexBasis: '50%',
	alignItems: 'center',
};

const flexRightSx = {
	flexBasis: '50%',
	alignItems: 'center',
	justifyContent: 'flex-end',
};

const WrapPreviewButton = styled.div`
	position: relative;
	margin-left: 21px;
`;

function Builder({ goToDashboard, goToPreview, panelRef, publishUrl }: TProps) {
	const language: TEditorLang = useSelector(getLanguage);
	const currentGridHighlight: boolean = useSelector(getGridHighlight);

	const { t } = useTranslation();

	const [currentPanel, setCurrentPanel] = React.useState(null);

	const togglePanel = React.useCallback(
		panel => setCurrentPanel(panel === currentPanel ? null : panel),
		[currentPanel],
	);

	const fullHeightPanel = currentPanel === 'layers' ? 'full' : 'auto';

	const hidePanel = React.useCallback(() => setCurrentPanel(null), []);

	return (
		<>
			<Flex sx={flexLeftSx}>
				<Burger goToDashboard={goToDashboard} />
				<ButtonsPanels
					active={currentPanel}
					onClick={togglePanel}
					panels={panels}
				/>
			</Flex>
			<ButtonsDevices />
			<Flex sx={flexRightSx}>
				<ButtonsHistory />
				<ButtonExtra
					currentGridHighlight={currentGridHighlight}
					language={language}
				/>
				<WrapPreviewButton>
					<Button variant="primary.rounded.sm" onClick={goToPreview}>
						{t('Preview')}
					</Button>
				</WrapPreviewButton>

				<ButtonPublish publishUrl={publishUrl} />
			</Flex>

			<ThemeProvider theme={themes.light}>
				<PopupWhite
					isOpen={!!currentPanel}
					anchorEl={panelRef}
					offsetTop={HEIGHT + PANEL_DY}
					offsetLeft={PANEL_DX}
					onClose={hidePanel}
					mutex="topleftcorner"
					height={fullHeightPanel}
					isFixed
				>
					{(currentPanel === 'design' && <Design />) ||
						(currentPanel === 'layers' && <Layers />)}
				</PopupWhite>
			</ThemeProvider>
		</>
	);
}

export default React.memo<TProps>(Builder);
