// @flow
import React from 'react';
import _ from 'lodash/fp';
import type { TPopupOnClick, TUnitParam } from '@graphite/types';
import { Icon, List, PopupSelect, InputInplace, Text, Flex } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	value: string,
	valueUnit?: ?string,
	param: TUnitParam,
	unit: number,
	onChange?: ?(number) => void,
	onClick?: ?TPopupOnClick,
|}>;

const paramRightSx = {
	width: 'auto',
	position: 'relative',
	height: '100%',
	alignItems: 'center',
	flexDirection: 'column',
};

const paramHoverSx = {
	...paramRightSx,
	'> div:last-child': {
		opacity: 0,
	},
	':hover > div:last-child': {
		opacity: 1,
	},
};

const paramLeftUnitSx = {
	minWidth: 'auto',
	flexShrink: 0,
};

const autoTextSx = {
	padding: '7px',
};

const selectSx = {
	position: 'absolute',
	right: 0,
	bottom: 0,
	height: '30px',
	// FIXME: надо параметризировать, потому что зависит от паддинга листайтема
	marginRight: '-24px',
	alignItems: 'center',
	justifyContent: 'flex-end',
	transition: 'opacity 0.15s ease-in',
};

const domains = {
	any: value => value !== undefined,
	positive: value => value > 0,
	nonnegative: value => value >= 0,
};

const validateReq = value => value.length > 0 && !Number.isNaN(+value);
const validateNreq = value => !value || validateReq(value);

const getSuffix = ({ showUnits, unitKey }, valueUnit) => {
	if (!showUnits || (unitKey && !valueUnit)) {
		return '';
	}
	if (!unitKey) {
		return 'px';
	}
	if (valueUnit === 'auto') {
		return '';
	}
	return valueUnit;
};

const emptyUnits = [];

function UnitInput({ value, valueUnit, param, unit, onChange, onClick }: TProps) {
	const { info } = param;
	const units = info.units || emptyUnits;
	const isSelectable: boolean = units.length > 1;
	const [isOpen, setIsOpen] = React.useState(false);

	const toggleOpen = React.useCallback(() => {
		const newOpen = !isOpen;
		setIsOpen(newOpen);
	}, [isOpen, setIsOpen]);

	const suffix = getSuffix(info, valueUnit);

	const changeParam = React.useMemo(
		() => _.debounce(150, changed => (onChange && onChange(+changed)) || undefined),
		[onChange],
	);

	const boundClick = React.useCallback(
		(e, itemName, buttons) => {
			setIsOpen(false);
			if (!onClick || (typeof itemName !== 'string' && itemName !== null)) {
				return;
			}
			onClick(e, itemName, buttons);
		},
		[onClick],
	);

	const list = React.useMemo(
		() => ({
			items: units.map(name => ({ name, label: name })),
			active: info.unitKey ? valueUnit : null,
			colors: 'primaryflat',
			activeColors: 'accentflat',
			behavior: 'radio',
		}),
		[info.unitKey, valueUnit, units],
	);

	const anchorRef = React.useRef();

	const validator = info.isRequired === false ? validateNreq : validateReq;
	const validate = React.useCallback(
		changed => {
			const preValid = validator(changed);
			if (changed) {
				return preValid && domains[info.domain || 'any'](+changed);
			}
			return preValid;
		},
		[info.domain, validator],
	);

	const keyDown = React.useCallback(
		e => {
			if (!onChange || !['ArrowUp', 'ArrowDown'].includes(e.key)) {
				return;
			}
			e.preventDefault();
			const direction = e.key === 'ArrowUp' ? 1 : -1;
			const factor =
				(e.ctrlKey && 100) || (e.shiftKey && 10) || (e.altKey && 0.1) || 1;
			const newValue = (+value || 0) + direction * factor;
			if (validate(`${newValue}`)) {
				onChange(newValue);
			}
		},
		[onChange, value, validate],
	);

	const isUnit = info.showUnits && info.unitKey && valueUnit === 'unit';
	const isAuto = info.showUnits && info.unitKey && valueUnit === 'auto';
	return (
		<>
			<Flex sx={isSelectable ? paramHoverSx : paramRightSx}>
				{(!isAuto && (
					<Flex sx={paramLeftUnitSx}>
						<InputInplace
							value={value}
							max={4}
							suffix={suffix}
							onValidate={validate}
							onEnter={changeParam}
							onBlur={changeParam}
							onKeyDown={keyDown}
						/>
					</Flex>
				)) || (
					<Text variant="bodysm" color="text.primary" sx={autoTextSx}>
						Auto
					</Text>
				)}
				{isUnit && (
					<Flex>
						<InputInplace value={`${+value * unit}`} suffix="px" isDisabled />
					</Flex>
				)}
				{isSelectable && (
					<Flex ref={anchorRef} sx={selectSx}>
						<Icon
							name="expand-small-top"
							rotation={isOpen ? 0 : 180}
							colors="secondaryflat"
							size="sm"
							onClick={toggleOpen}
						/>
					</Flex>
				)}
			</Flex>
			{isSelectable && (
				<PopupSelect
					isOpen={isOpen}
					anchorEl={anchorRef}
					offsetTop={0}
					offsetLeft={0}
					onClose={toggleOpen}
				>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<List {...list} onClick={boundClick} />
				</PopupSelect>
			)}
		</>
	);
}

UnitInput.defaultProps = {
	valueUnit: null,
	onChange: null,
	onClick: null,
};

export default React.memo<TProps>(UnitInput);
