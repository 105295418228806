// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import { themes, Portal, Flex, Box, Text } from '@graphite/uneon';

import { useDispatch } from '@graphite/use-redux';
import { usePageId } from '@graphite/use-location';
import type {
	TId,
	TWidget,
	TWidgets,
	TSpecsGrid,
	TSpecsWidget,
	TSpecsColor,
	TSpecsEffect,
	TSpecsGridBreakpoint,
	TGridBreakpointName,
} from '@graphite/types';
import {
	getGridspec,
	getColorspec,
	getWidgetspec,
	getEffectspec,
	getWidgets,
	getWidget,
} from '@graphite/selectors';
import { Global, css } from '@emotion/core';
import ComponentContext from 'ComponentContext';
import { getCurrentWidget, getCurrentDevice } from 'Editor/selectors/editor';
import Widget from 'Widget';
import { anyKind } from 'Widget/libs';
import { DNDProvider } from 'Widget/libs/dnd/dndContext';
import getBreakpointWidth from 'libs/get-breakpoint-width';
import Poper from 'Widget/Poper';

import Loader from '../../Loader';
import SymbioteProvider from '../libs/with-symbiote/Provider';
import { getCurrentBreakpoint } from '../selectors/editor';
import Frame from '../Frame';
import FrameProvider from '../Frame/Provider';
import {
	wrapperSx,
	overlayOpenSx,
	overlayClosedSx,
	grabStyle,
	grabbingStyle,
	resizeStyle,
	resizeLeftSx,
	resizeLeftActiveSx,
	resizeRightSx,
	resizeRightActiveSx,
} from './constants/styles';
import useControls from './libs/use-controls';
import useScrollEffect from './libs/use-scroll-effect';
import AbsoluteProvider from './AbsoluteProvider';

const globalStyle = css`
	body {
		overflow: hidden;
	}
`;

type TProps = $ReadOnly<{||}>;

const getOverlayStyle = (pageState, isScroll) => {
	if (pageState === 'scroll') {
		return isScroll ? grabbingStyle : grabStyle;
	}
	if (pageState === 'resize') {
		return resizeStyle;
	}
	return null;
};

const Page = () => {
	const dispatch = useDispatch();
	const pageId: ?TId = usePageId();
	const currentDevice: TGridBreakpointName = useSelector(getCurrentDevice);
	const currentWidget = useSelector(getCurrentWidget);
	const widgets: TWidgets = useSelector(getWidgets);
	const page: ?TWidget = useSelector(state => getWidget(state, { id: pageId || '' }));
	const gridspec: TSpecsGrid = useSelector(getGridspec);
	const widgetspec: TSpecsWidget = useSelector(getWidgetspec);
	const colorspec: TSpecsColor = useSelector(getColorspec);
	const effectspec: TSpecsEffect = useSelector(getEffectspec);
	const breakpoint: TSpecsGridBreakpoint = useSelector(getCurrentBreakpoint);
	const initialWidth = getBreakpointWidth(breakpoint);
	const [width, setWidth] = React.useState<number>(initialWidth);
	const [scale] = React.useState<number>(1);
	const ref = React.useRef<?HTMLElement>(null);

	const {
		pageState,
		isScroll,
		isResizeLeft,
		mouseDown,
		mouseDownResizes,
		mouseUp,
		preventMenu,
		mouseMove,
		ownDevice,
	} = useControls({
		gridspec,
		currentDevice,
		ref,
		initialWidth,
		dispatch,
		setWidth,
	});

	const widgetChain = React.useMemo(() => [page ? page._id : ''], [page]);

	const isLoadWidgets: boolean =
		!page?.children?.length ||
		!!Object.keys(widgets).find((key: TId): boolean => !!page?.children?.[key]);

	useScrollEffect({ pageId: (page && page._id) || null, ref });

	React.useEffect(() => {
		// Если выход из редактирования виджета, то надо забрать фокус в главное окно
		if (!currentWidget) {
			// Если не забрать фокус из ифрейма, то он продолжит захватывать события клавиш
			// Тогда перестают работать хоткеи, навсегда
			window.document.body.focus();
		}
	}, [currentWidget]);

	if (!page || !breakpoint || !isLoadWidgets) return <Loader />;

	const overlayStyle = getOverlayStyle(pageState, isScroll);
	const isResize = pageState === 'resize';

	return (
		<>
			<Global styles={globalStyle} />
			<Portal>
				<Box
					sx={pageState === 'edit' ? overlayClosedSx : overlayOpenSx}
					style={overlayStyle}
					onMouseDown={mouseDown}
					onContextMenu={preventMenu}
					onMouseMove={pageState !== 'edit' ? mouseMove : null}
					onMouseUp={mouseUp}
				/>
			</Portal>
			<Box
				sx={wrapperSx}
				ref={ref}
				onMouseDown={mouseDown}
				onMouseMove={pageState !== 'edit' ? mouseMove : null}
				onMouseUp={mouseUp}
				id="frame-wrapper"
			>
				<FrameProvider>
					<Flex
						sx={isResize && isResizeLeft ? resizeLeftActiveSx : resizeLeftSx}
						onMouseDown={mouseDownResizes.left}
					/>
					<Flex
						sx={
							isResize && !isResizeLeft
								? resizeRightActiveSx
								: resizeRightSx
						}
						onMouseDown={mouseDownResizes.right}
					/>
					<ThemeProvider theme={themes.light}>
						<SymbioteProvider currentWidget={currentWidget}>
							<Frame width={width} scale={scale}>
								<DNDProvider dispatch={dispatch} widgets={widgets}>
									<AbsoluteProvider
										currentDevice={ownDevice}
										widgets={widgets}
										dispatch={dispatch}
										widgetChain={widgetChain}
										widgetspec={widgetspec}
										colorspec={colorspec}
										gridspec={gridspec}
										effectspec={effectspec}
									>
										<ComponentContext.Provider
											value={anyKind('get-edit-component')}
										>
											<Widget
												id={page._id}
												containerId={null}
												instanceId={null}
												widgetChain={widgetChain}
												widgetspec={widgetspec}
												colorspec={colorspec}
												gridspec={gridspec}
												effectspec={effectspec}
											/>
										</ComponentContext.Provider>
									</AbsoluteProvider>
								</DNDProvider>
							</Frame>
						</SymbioteProvider>
					</ThemeProvider>
				</FrameProvider>
				{isResize && (
					<Poper>
						<Text variant="captionlg" color="spec.lightblue10">
							{`${width}px`}
						</Text>
					</Poper>
				)}
			</Box>
		</>
	);
};

export default React.memo<TProps>(Page);
