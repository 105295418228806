// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAbsoluteDropPlace } from '@graphite/abs-grid';

import withSymbiote from 'Editor/libs/with-symbiote';
import withDrag from 'Widget/libs/dnd/drag';
import withDragReorder from 'Widget/libs/dnd/dragReorder';
import type { TWidgetOwnProps, Dispatch, TAction, TId } from '@graphite/types';
import withUnedit from 'Widget/libs/with-unedit';
import withRef from 'Widget/libs/with-ref';

import { insertImages, removeImage, reset } from 'Editor/ducks/imageLibrary';

import Col from './Col';
import type { TConnectPropsEdit } from './constants/types';
import mapStateToPropsEdit from './libs/mapStateToPropsEdit';
import withResizeCol from './libs/with-resize-col';
import withControls from './libs/with-controls';
import withFilter from './libs/with-filter';
import withDropPlace from './libs/with-drop-place';

const mapDispatchToProps = (dispatch: Dispatch<TAction>) => {
	return {
		// FixMe: выпилить dispatch
		dispatch,
		insertImage: (files: FileList) => {
			dispatch(insertImages(files));
		},
		removeImage: (imageId: TId) => {
			dispatch(removeImage(imageId));
		},
		resetImage: () => {
			dispatch(reset());
		},
	};
};

const ColEdit = compose(
	// берём данные из стейта
	connect<TConnectPropsEdit, TWidgetOwnProps, _, _, _, _>(
		mapStateToPropsEdit,
		mapDispatchToProps,
	),
	// добавляет реф, который прокидывается через все ХОКи прямо вглубь
	Component => withRef<TConnectPropsEdit>(Component),
	// позволяет выходить из виджета по клику в пустое место колнки
	Component => withUnedit<TConnectPropsEdit>(Component),
	// меняет порядок виджетов в колонке при драге
	withDragReorder,
	// добавляет драг и флип колонке
	withDrag,
	// добавляет в пропсы sizes из контекста
	withResizeCol,
	// выносим в симбиот компонеты
	withSymbiote(withDropPlace, {
		level: 'grid-drag-place',
	}),
	// выносим в симбиот компонеты
	withSymbiote(withControls, {
		margin: false,
	}),
	// выносим в симбиот компонеты
	withSymbiote(
		// добавляет подсвеику дропа абсолтного виджета
		Component =>
			withAbsoluteDropPlace(
				{ name: 'Column', type: 'col', kind: 'absolute' },
				Component,
			),
		{
			margin: false,
			level: 'abs-drag-place',
		},
	),
	// Удаляем лишние пропсы
	withFilter,
)(Col);

export default ColEdit;
