// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { useInjectReducer } from 'libs/inject-reducer';

import { themes } from '@graphite/uneon';

import { useInjectSaga } from 'libs/inject-saga';
import { useDispatch } from '@graphite/use-redux';
import Login from '../Login';
import SignUp from '../SignUp';
import Loader from '../Loader';
import Dashboard from './Dashboard';
import Site from './Site';

import { saga as sagaEditor } from './ducks/editor';
import {
	saga as sagaUsers,
	signInWithGoogle,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	logOut,
} from './ducks/users';
import { saga as sagaProjects } from './ducks/projects';
import { saga as sagaSite } from './ducks/sites';
import { saga as sagaProjectsSites } from './ducks/projectsSites';
import { saga as sagaSpecs } from './ducks/specs';
import imageLibraryReducer, { saga as sagaImageLibrary } from './ducks/imageLibrary';
import {
	saga as sagaWidgets,
	syncScopedWidgets,
	unsyncScopedWidgets,
} from './ducks/widgets';
import { saga as sagaHistory } from './ducks/history';
import {
	getIsLoading as getIsLoadingUsers,
	getCurrentUser,
	getAuthError,
	getIsAuth,
} from './selectors/users';

type TProps = $ReadOnly<{||}>;

const Editor = () => {
	useInjectSaga({ key: 'editor', saga: sagaEditor });
	useInjectSaga({ key: 'users', saga: sagaUsers });
	useInjectSaga({ key: 'projects', saga: sagaProjects });
	useInjectSaga({ key: 'site', saga: sagaSite });
	useInjectSaga({ key: 'projectsSites', saga: sagaProjectsSites });
	useInjectSaga({ key: 'specs', saga: sagaSpecs });
	useInjectSaga({ key: 'widgets', saga: sagaWidgets });
	useInjectSaga({ key: 'constructorHistory', saga: sagaHistory });
	useInjectSaga({ key: 'imageLibrary', saga: sagaImageLibrary });

	useInjectReducer({ key: 'imageLibrary', reducer: imageLibraryReducer });

	const dispatch = useDispatch();

	const signInWithGoogleHandler = React.useCallback(
		e => {
			e.preventDefault();
			dispatch(signInWithGoogle());
		},
		[dispatch],
	);
	const signInWithEmailAndPasswordHandler = React.useCallback(
		({ email, password }) => {
			dispatch(signInWithEmailAndPassword(email, password));
		},
		[dispatch],
	);
	const createUserWithEmailAndPasswordHandler = React.useCallback(
		({ email, password, name }) => {
			dispatch(createUserWithEmailAndPassword(email, password, name));
		},
		[dispatch],
	);
	// eslint-disable-next-line no-void
	const logOutHandler = React.useCallback(() => void dispatch(logOut()), [dispatch]);
	const authError = useSelector(getAuthError);
	const isAuth = useSelector(getIsAuth);
	const isLoadingUsers = useSelector(getIsLoadingUsers);
	const user = useSelector(getCurrentUser);

	React.useEffect(() => {
		if (user) {
			dispatch(syncScopedWidgets('system', null));
			dispatch(syncScopedWidgets('user', user._id));
		}
		return () => {
			if (user) {
				dispatch(unsyncScopedWidgets('system', null));
				dispatch(unsyncScopedWidgets('user', user._id));
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoadingUsers)
		return (
			<ThemeProvider theme={themes.light}>
				<Loader />
			</ThemeProvider>
		);

	return (
		<ThemeProvider theme={themes.light}>
			<Switch>
				<Route exact path="/login">
					{!user ? (
						<Login
							signInWithGoogle={signInWithGoogleHandler}
							signInWithEmailAndPassword={signInWithEmailAndPasswordHandler}
							error={authError}
							isAuth={isAuth}
						/>
					) : (
						<Redirect to="/" />
					)}
				</Route>
				<Route exact path="/signup">
					{!user ? (
						<SignUp
							signInWithGoogle={signInWithGoogleHandler}
							createUserWithEmailAndPassword={
								createUserWithEmailAndPasswordHandler
							}
							error={authError}
							isAuth={isAuth}
						/>
					) : (
						<Redirect to="/" />
					)}
				</Route>
				<Route path="/project/:projectId/site/:siteId">
					{user ? <Site user={user} /> : <Redirect to="/login" />}
				</Route>
				<Route path="/">
					{user ? (
						<Dashboard user={user} logOut={logOutHandler} />
					) : (
						<Redirect to="/login" />
					)}
				</Route>
			</Switch>
		</ThemeProvider>
	);
};

export default React.memo<TProps>(Editor);
