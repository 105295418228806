// @flow
import React from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { Section, Separator, PopupWhite, Box } from '@graphite/uneon';
import type { TSpecsColor, TSpecsGrid } from '@graphite/types';
import { defaultTheme } from '@graphite/constants';
import Library from '@graphite/library';

import logger from 'libs/logger';
import { genId } from 'libs/firebase';
import { useDispatch } from '@graphite/use-redux';
import { updateSpecs } from 'Editor/ducks/specs';
import { ThemeColors as ListThemeColors, Themes as ListThemes } from '@graphite/lists';

type TProps = $ReadOnly<{|
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
|}>;

const anchorBoxOuterStyle = {
	position: 'relative',
	margin: '0 -24px 0px -24px',
	height: 0,
};

const anchorBoxInnerStyle = {
	position: 'absolute',
	width: 0,
	height: 0,
	right: 0,
	top: 0,
};

const libColorPalette = { color: { tabs: ['palette', 'custom'] } };

const themeButtons = {
	margin: 4,
	buttons: [
		{
			name: 'remove',
			icons: [
				{
					name: 'minus',
					iconSize: 18,
				},
			],
			colors: 'primary',
		},
		{
			name: 'insert',
			icons: [
				{
					name: 'plus',
					iconSize: 18,
				},
			],
			colors: 'primary',
		},
	],
};

function Themes({ colorspec, gridspec }: TProps) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { themes, activeTheme } = colorspec;
	const [editedColor, setEditedColor] = React.useState(null);

	const themeAt = React.useMemo(
		() => themes.findIndex(theme => theme.id === activeTheme),
		[themes, activeTheme],
	);

	const theme = themeAt < 0 ? defaultTheme : themes[themeAt];

	const editTheme = React.useCallback(
		id => {
			if (themeAt >= 0) {
				setEditedColor(id);
			}
		},
		[themeAt],
	);

	const anchorRef = React.useRef();

	const changeColor = React.useCallback(
		value => {
			try {
				if (
					!editedColor ||
					value.kind !== 'color' ||
					typeof value.value === 'string'
				) {
					return;
				}
				const updated = _.set(editedColor, value.value, colorspec);
				dispatch(updateSpecs({ [updated._id]: updated }));
			} catch (e) {
				logger.error(e);
			}
		},
		[editedColor, colorspec, dispatch],
	);

	const uneditColor = React.useCallback(() => setEditedColor(null), [setEditedColor]);

	const editedColorValue = React.useMemo(
		() =>
			editedColor && {
				kind: 'color',
				value: _.get(editedColor, colorspec),
			},
		[editedColor, colorspec],
	);

	const clickThemeButton = React.useCallback(
		(e, action) => {
			try {
				if (action === 'insert') {
					const newTheme = {
						...theme,
						id: genId('specs'),
						name: `${theme.name || 'Unnamed'} - copy`,
					};
					const newThemes = [...themes, newTheme];
					const updated = _.set('themes', newThemes, colorspec);
					dispatch(updateSpecs({ [updated._id]: updated }));
				} else if (action === 'remove') {
					if (themes.length < 2) {
						return;
					}
					if (editedColor) {
						setEditedColor(null);
					}
					const newThemes = [
						...themes.slice(0, themeAt),
						...themes.slice(themeAt + 1),
					];
					const updated = _.set(
						'activeTheme',
						newThemes[0].id,
						_.set('themes', newThemes, colorspec),
					);
					dispatch(updateSpecs({ [updated._id]: updated }));
				}
			} catch (e) {
				logger.error(e);
			}
		},
		[theme, themes, colorspec, dispatch, editedColor, themeAt],
	);

	const selectTheme = React.useCallback(
		id => {
			try {
				if (editedColor) {
					setEditedColor(null);
				}
				const updated = _.set('activeTheme', id, colorspec);
				dispatch(updateSpecs({ [updated._id]: updated }));
			} catch (e) {
				logger.error(e);
			}
		},
		[colorspec, dispatch, editedColor],
	);

	const finalThemeButtons = React.useMemo(
		() => ({
			...themeButtons,
			buttons: themes.length > 1 ? themeButtons.buttons : [themeButtons.buttons[1]],
		}),
		[themes.length],
	);

	return (
		<>
			<Section
				label="Themes"
				buttonGroup={finalThemeButtons}
				onClick={clickThemeButton}
			>
				<ListThemes
					colorspec={colorspec}
					activeTheme={colorspec.activeTheme}
					onClick={selectTheme}
					t={t}
				/>
			</Section>
			<Box sx={anchorBoxOuterStyle}>
				<Separator />
				<Box ref={anchorRef} sx={anchorBoxInnerStyle} />
			</Box>
			<ListThemeColors
				colorspec={colorspec}
				theme={theme}
				themeAt={themeAt}
				active={editedColor}
				onClick={editTheme}
			/>
			{editedColorValue && (
				<PopupWhite
					isOpen={!!editedColor}
					anchorEl={anchorRef}
					offsetLeft={12}
					offsetTop={-100}
					onClose={uneditColor}
					mutex="color"
					isFixed
				>
					<Library
						t={t}
						config={libColorPalette}
						value={editedColorValue}
						onChange={changeColor}
						colorspec={colorspec}
						gridspec={gridspec}
						genId={genId}
					/>
				</PopupWhite>
			)}
		</>
	);
}

export default React.memo<TProps>(Themes);
