// @flow
import emptyObject from 'empty/object';
import { useLocation, matchPath } from 'react-router-dom';

export const useProjectId = () => {
	const location = useLocation();
	const { params: { projectId } = {} } =
		matchPath(location.pathname, {
			path: '/project/:projectId',
		}) || emptyObject;

	return projectId;
};

export const useSiteId = () => {
	const location = useLocation();
	const { params: { siteId } = {} } =
		matchPath(location.pathname, {
			path: '/project/:projectId/site/:siteId',
		}) || emptyObject;

	return siteId;
};

export const usePageId = () => {
	const location = useLocation();
	const { params: { pageId } = {} } =
		matchPath(location.pathname, {
			path: '/project/:projectId/site/:siteId/page/:pageId',
		}) || emptyObject;

	return pageId;
};
