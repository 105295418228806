// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import Widget from 'Widget';
import { anyKind } from 'Widget/libs';
import ComponentContext from 'ComponentContext';

import {
	getGridspec,
	getColorspec,
	getWidgetspec,
	getEffectspec,
	getCurrentPage,
	getCurrentSite,
} from '@graphite/selectors';

export type TProps = $ReadOnly<{||}>;

const Published = () => {
	const pageId = useSelector(getCurrentPage);
	const siteId = useSelector(getCurrentSite);
	const gridspec = useSelector(getGridspec);
	const widgetspec = useSelector(getWidgetspec);
	const colorspec = useSelector(getColorspec);
	const effectspec = useSelector(getEffectspec);

	if (!pageId || !siteId) return 'Page not found';

	return (
		<ComponentContext.Provider value={anyKind('get-view-component')}>
			<Widget
				id={pageId}
				containerId={null}
				instanceId={null}
				widgetspec={widgetspec}
				colorspec={colorspec}
				gridspec={gridspec}
				effectspec={effectspec}
			/>
		</ComponentContext.Provider>
	);
};

export default React.memo<TProps>(Published);
