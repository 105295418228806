// @flow
import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { getRebassBreakpoints } from '@graphite/selectors';
import getDisplayName from '@graphite/get-display-name';

import type { TMinimalPropsThemeProvider } from '../constants/types';

const withThemeProvider = <T: TMinimalPropsThemeProvider>(
	Component: React$ComponentType<T>,
): React$ComponentType<$ReadOnly<{| ...$Exact<T> |}>> => {
	const WithThemeProvider = (props: T, ref) => {
		const theme = getRebassBreakpoints(props.gridspec);

		return (
			<ThemeProvider theme={theme}>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} ref={ref} />
			</ThemeProvider>
		);
	};

	WithThemeProvider.displayName = `withThemeProvider(${getDisplayName(Component)})`;

	return React.memo<T>(React.forwardRef(WithThemeProvider));
};

export default withThemeProvider;
