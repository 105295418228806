// @flow
import React, { memo, useCallback, useMemo } from 'react';
import emptyFunction from 'empty/function';
import type { TListEmbed, TListItemTextEmbedNamed, TListOnClick } from '@graphite/types';

import ListItemText from '../ListItemText';
import { Flex } from '../Box';

type TProps = $ReadOnly<{|
	...TListEmbed,
	onClick?: TListOnClick,
|}>;

const containerStyle = {
	alignItems: 'center',
	borderStyle: 'solid',
	justifyContent: 'center',
	flexDirection: 'column',
};

function List({
	items,
	colors = null,
	activeColors = null,
	size = null,
	isDisabled = false,
	behavior = 'button',
	sx = null,
	style = null,
	active = null,
	onClick = emptyFunction,
}: TProps) {
	const clickBound = useCallback(
		(e, name, buttons) => {
			if (name === undefined) {
				return;
			}
			if (behavior !== 'checkbox') {
				onClick(e, name, buttons);
				return;
			}
			const activeArray = typeof active === 'string' || !active ? [] : active;
			if (activeArray && activeArray.includes(name)) {
				const nameAt = activeArray.findIndex(v => name === v);
				onClick(
					e,
					[...activeArray.slice(0, nameAt), ...activeArray.slice(nameAt + 1)],
					buttons,
				);
			}
			onClick(e, [...activeArray, name], buttons);
		},
		[onClick, active, behavior],
	);

	const modifiedItems: $ReadOnlyArray<TListItemTextEmbedNamed> = useMemo(
		() =>
			items.map(item => {
				const isActive =
					(active &&
						((typeof active === 'string' && active === item.name) ||
							(typeof active !== 'string' &&
								active.includes(item.name)))) ||
					false;
				return {
					...item,
					isActive: activeColors ? false : isActive,
					size: size || item.size,
					colors: (isActive && activeColors) || item.colors || colors,
					isDisabled: isDisabled || item.isDisabled,
				};
			}),
		[active, activeColors, items, colors, isDisabled, size],
	);

	const containerThemedStyle = useMemo(
		() => ({
			...containerStyle,
			...sx,
		}),
		[sx],
	);

	return (
		<Flex sx={containerThemedStyle} style={style}>
			{modifiedItems.map(b => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<ListItemText key={b.name} {...b} onClick={clickBound} />
			))}
		</Flex>
	);
}

export default memo<TProps>(List);
