// @flow

import React, { memo, useCallback, useMemo } from 'react';
import { Box } from 'rebass';

import type { TIconShape } from '@graphite/types';

type TProps = $ReadOnly<{|
	name?: ?string,
	color: string,
	shape?: ?TIconShape,
	cursor?: ?string,
	isActive?: boolean,
	onClick?: ?(MouseEvent, ?string) => void,
|}>;

const buttonBaseSx = {
	position: 'relative',
	boxShadow: 'inset 0 0 0 1px rgba(0,0,0,0.15), inset 0 0 0 12px rgba(0,0,0,0)',
	width: '24px',
	height: '24px',
	transition: 'box-shadow 0.15s ease-in',
	':hover': {
		boxShadow: 'inset 0 0 0 1px rgba(0,0,0,0.15), inset 0 0 0 12px rgba(0,0,0,0.1)',
	},
};

const bgBaseSx = {
	backgroundImage: `linear-gradient(45deg, #80808044 25%, transparent 25%), 
			linear-gradient(-45deg, #80808044 25%, transparent 25%), 
			linear-gradient(45deg, transparent 75%, #80808044 75%), 
			linear-gradient(-45deg, transparent 75%, #80808044 75%)`,
	backgroundSize: '12px 12px',
	backgroundPosition: '0 0, 0 6px, 6px -6px, -6px 0px',
};

const outlineBaseSx = {
	position: 'absolute',
	zIndex: 1,
	top: '-2px',
	left: '-2px',
	right: '-2px',
	bottom: '-2px',
	boxShadow: 'inset 0 0 0 1px rgba(255,255,255,0.9)',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'bg.accent',
};

function ButtonColor({ name, color, shape, cursor, isActive, onClick }: TProps) {
	const boundClick = useCallback(
		e => {
			if (onClick) {
				onClick(e, name);
			}
		},
		[onClick, name],
	);

	const buttonStyle = useMemo(
		() => ({
			background: color,
		}),
		[color],
	);

	const buttonSx = useMemo(
		() => ({
			...buttonBaseSx,
			cursor: cursor || 'pointer',
			borderRadius: `${shape || 'rounded'}.all`,
		}),
		[shape, cursor],
	);

	const outlineSx = useMemo(
		() => ({
			...outlineBaseSx,
			borderRadius: `${shape || 'rounded'}.all`,
		}),
		[shape],
	);

	const bgSx = useMemo(
		() => ({
			...bgBaseSx,
			borderRadius: `${shape || 'rounded'}.all`,
		}),
		[shape],
	);

	return (
		<Box sx={bgSx} onClick={boundClick}>
			<Box sx={buttonSx} style={buttonStyle}>
				{isActive && <Box sx={outlineSx} />}
			</Box>
		</Box>
	);
}

ButtonColor.defaultProps = {
	name: null,
	shape: 'rounded',
	cursor: 'pointer',
	isActive: false,
	onClick: null,
};

export default memo<TProps>(ButtonColor);
