// @flow

import _ from 'lodash/fp';
import { eventChannel, type EventChannel } from 'redux-saga';
import { takeEvery, put, fork, take, cancelled } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import type { Saga } from 'redux-saga';
import type {
	TId,
	TAction,
	TQueryConstraints2,
	TQueryConstraint,
	TSites,
	TSite,
} from '@graphite/types';

import { defaultSite } from '@graphite/constants';
import logger from 'libs/logger';
import { collection, genId } from 'libs/firebase';

import { APPLY } from './editor';

const APPLY_SITES = 'SITES/APPLY_SITES';
const FETCH_IF_NEEDED = 'SITES/FETCH_IF_NEEDED';
const INSERT_SITE = 'SITES/INSERT_SITE';
const REMOVE_SITE = 'SITES/REMOVE_SITE';
const UPDATE_SITE = 'SITES/UPDATE_SITE';

const initialState: TSites = {};

const applySites = (sites: TSites): TAction => ({
	type: APPLY_SITES,
	payload: { sites },
});

export const fetchIfNeeded = (id: ?TId): TAction => ({
	type: FETCH_IF_NEEDED,
	payload: { id },
});

export const insertSite = (userId: TId, site: ?$Shape<TSite>): TAction => ({
	type: INSERT_SITE,
	payload: { userId, site },
});

export const removeSite = (id: TId): TAction => ({
	type: REMOVE_SITE,
	payload: { id },
});

export const updateSite = (siteId: TId, diff: $Shape<TSite>): TAction => ({
	type: UPDATE_SITE,
	payload: { siteId, diff },
});

/**
	Коллаборация
 */
const getSitesEventsChannel = (userId: string): EventChannel<{ sites: TSites }> => {
	const minDate = new Date().toISOString();
	return eventChannel<{ sites: TSites }>(
		(emit: ({ sites: TSites }) => void): (() => void) => {
			const unsubscriptors: $ReadOnlyArray<() => void> = [
				{ scope: ['==', 'system'], scopeId: ['==', null] },
				{
					scope: ['==', 'market'],
					scopeId: ['==', userId],
					removedAt: ['==', null],
				},
				{
					scope: ['==', 'market'],
					scopeId: ['==', userId],
					removedAt: ['>', minDate],
				},
				{
					scope: ['==', 'user'],
					scopeId: ['==', userId],
					removedAt: ['==', null],
				},
				{
					scope: ['==', 'user'],
					scopeId: ['==', userId],
					removedAt: ['>', minDate],
				},
			].map((constraints: TQueryConstraints2): (() => void) => {
				let sites = collection('sites').where('userId', '==', userId);
				_.forEach(([k, v]: [string, TQueryConstraint]) => {
					sites = sites.where(k, v[0], v[1]);
				}, _.entries(constraints));
				return sites.onSnapshot((snapshot: any) => {
					const sites = {};
					snapshot.docChanges().forEach((change: any) => {
						const site = change.doc.data();
						sites[site._id] = site;
					});
					if (_.size(sites)) {
						emit({ sites });
					}
				});
			});
			// Return an unregister function
			return (): void => unsubscriptors.forEach((u: () => void): void => u());
		},
	);
};

export function* fetchIfNeededSaga(): Saga<void> {
	let sitesEventsChannel = null;
	yield takeEvery(FETCH_IF_NEEDED, function*({
		payload: { id },
	}: {
		payload: { id: TId },
	}): any {
		if (sitesEventsChannel) {
			sitesEventsChannel.close();
			sitesEventsChannel = null;
		}

		if (!id) return;

		sitesEventsChannel = getSitesEventsChannel(id);
		yield put(applySites({}));

		try {
			while (!0) {
				const { sites } = yield take(sitesEventsChannel);
				yield put(applySites(sites));
			}
		} catch (e) {
			logger.error(e);
		} finally {
			if (yield cancelled() && sitesEventsChannel) sitesEventsChannel.close();
		}
	});
}

export function* insertSiteSaga(): Saga<void> {
	yield takeEvery(INSERT_SITE, function*({
		payload: { userId, site = {} },
	}: {
		payload: { userId: TId, site: ?$Shape<TSite> },
	}): Saga<void> {
		try {
			const siteId = site && site._id ? site._id : genId('sites');
			yield collection('sites')
				.doc(siteId)
				.set({
					...defaultSite,
					// FixMe: тут дыра в безопасности
					// нужно проверять, что пользователь только под своим id создаёт сайты
					userId,
					_id: siteId,
					name: `New Site ${new Date().getSeconds()}`,
					...site,
					scope: 'user',
					scopeId: userId,
				});
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* removeSiteSaga(): Saga<void> {
	yield takeEvery(REMOVE_SITE, function*({
		payload: { id },
	}: {
		payload: { id: TId },
	}): Saga<void> {
		try {
			yield collection('sites')
				.doc(id)
				.set(
					{
						removedAt: new Date().toISOString(),
					},
					{ merge: true },
				);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* updateSiteSaga(): Saga<void> {
	yield takeEvery(UPDATE_SITE, function*({
		payload: { siteId, diff },
	}: {
		payload: { siteId: TId, diff: $Shape<TSite> },
	}): Saga<void> {
		try {
			yield collection('sites')
				.doc(siteId)
				.update(diff);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* saga(): Saga<void> {
	yield fork(fetchIfNeededSaga);
	yield fork(insertSiteSaga);
	yield fork(removeSiteSaga);
	yield fork(updateSiteSaga);
}

export default handleActions<$ReadOnly<TSites>, TAction>(
	{
		[APPLY_SITES](
			state: $ReadOnly<TSites>,
			{ payload: { sites } }: { +payload: { +sites: $ReadOnly<TSites> } },
		): $ReadOnly<TSites> {
			return _.assign(state, sites);
		},
		[APPLY](
			state: $ReadOnly<TSites>,
			{ payload: { sites } }: { +payload: { +sites: TSites } },
		): $ReadOnly<TSites> {
			return _.pickBy(_.identity, _.assign(state, sites));
		},
	},
	initialState,
);
