// @flow

import compose from 'libs/compose';
import cast from 'libs/types/widgets';
import repopulate from 'libs/repopulate';

import type {
	TWidgetEnterResult,
	TWidgetOpFeedbackEmpty,
	TWidgetOpFeedbackFull,
	TWidgetComposed,
	TWidget,
	TWidgetMethodReorderWidgets,
	TWidgetEnterParams,
} from '@graphite/types';
import { getWidgetPresets, getOrder } from '@graphite/selectors';

export {
	addWidgetHook,
	removeWidgetHook,
	reorderWidgetsHook,
} from 'Widget/libs/stack-hooks';

export const applyChildren = repopulate;
export const applyPosition: TWidgetMethodReorderWidgets = (
	widget,
	position,
	newId,
	currentDevice,
) =>
	getOrder({
		widget,
		position,
		newId,
		currentDevice,
	});

// Вынимаем из page
export const leave = async (): Promise<null> => null;

// Кладём в page
export const enter = async ({
	widgets,
	srcId,
	destContainerId,
	destInstanceId,
	destOriginId,
	position,
	operations,
	currentDevice,
}: TWidgetEnterParams): Promise<?TWidgetEnterResult> => {
	const protoBlock: ?TWidgetComposed = getWidgetPresets(
		{ widgets },
		(w: TWidget): boolean => w.kind === 'block',
	)[0];
	if (!protoBlock) {
		return;
	}
	const protoBlockId = protoBlock._id;
	const composed = compose(widgets, widgets[srcId]);

	// Если будет вставляться блок, то ничего не делаем.
	if (composed.kind === 'block') {
		return null;
	}
	// Если будет вставляться всё что угодно, кроме блока, то нужно обернуть в блок
	// Сюда будет записан _id нового виджета
	const feedbackEmpty: TWidgetOpFeedbackEmpty = {};

	const updated = await operations.placeWidget({
		widgets,
		protoId: protoBlockId,
		destId: destContainerId,
		destInstanceId,
		destOriginId,
		position,
		feedback: feedbackEmpty,
		currentDevice,
	});

	const feedback: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(feedbackEmpty);

	if (!feedback) {
		return null;
	}

	return {
		updated,
		destContainerId: feedback.targetId,
		position: {
			kind: 'grid',
			destRect: position.destRect || null,
			dragRect: position.dragRect || null,
			breakpoints: position.breakpoints || null,
			prevId: null,
			nextId: null,
		},
	};
};
