// @flow
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { RouterHistory } from 'react-router-dom';
import { Flex } from '@graphite/uneon';
import type { TId } from '@graphite/types';
import { getSite } from '@graphite/selectors';
import { useProjectId, useSiteId, usePageId } from '@graphite/use-location';
import Preview from './Preview';
import Builder from './Builder';
import { HEIGHT } from './constants';

type TProps = $ReadOnly<{|
	history: RouterHistory,
|}>;

const headerFlexSx = {
	position: 'fixed',
	top: '0',
	width: '100%',
	height: `${HEIGHT}px`,
	padding: '0 12px',
	backgroundColor: 'bg.primaryminus',
	userSelect: 'none',
	zIndex: 150,
};

function TopBar({ history }: TProps) {
	const projectId: ?TId = useProjectId();
	const siteId: ?TId = useSiteId();
	const pageId: ?TId = usePageId();
	const { url: publishUrl } = useSelector(state =>
		siteId ? getSite(state, siteId) : {},
	);

	const url = `/project/${projectId || ''}/site/${siteId || ''}/page/${pageId || ''}`;

	const panelRef = React.useRef();

	const goToBuider = React.useCallback(() => history.push(url), [history, url]);

	const goToPreview = React.useCallback(() => history.push(`${url}/preview`), [
		history,
		url,
	]);

	const goToDashboard = React.useCallback(() => history.push(''), [history]);

	if (!(projectId && siteId && pageId)) {
		return null; // если этого всего нет, то хули мы тут забыли?
	}

	return (
		<Flex as="header" sx={headerFlexSx} ref={panelRef}>
			<Switch>
				<Route path="/project/:projectId/site/:siteId/page/:pageId/preview">
					<Preview publishUrl={publishUrl} goToBuider={goToBuider} />
				</Route>
				<Route path="">
					<Builder
						publishUrl={publishUrl}
						panelRef={panelRef}
						goToPreview={goToPreview}
						goToDashboard={goToDashboard}
					/>
				</Route>
			</Switch>
		</Flex>
	);
}

export default withRouter(React.memo<TProps>(TopBar));
