// @flow
import _ from 'lodash/fp';
import type { TId, TStateEditor, TSites, TSite } from '@graphite/types';

import reSelect from './libs/re-select';

type TGetSites = (TStateEditor, TId) => TSites;
export const getSites: TGetSites = reSelect(
	({ projects }, projectId: TId) => projects[projectId],
	({ sites }) => sites,
	(project, sites): TSites =>
		_.pickBy(
			(site: TSite) =>
				project.children && !!project.children[site._id] && !site.removedAt,
			sites,
		),
)((state, projectId) => `sites@getSites-${projectId}`);

// type TGetSite = (TStateEditor, { id: TId }) => ?TSite;
// export const getSite: TGetSite = reSelect(
// 	({ sites }) => sites,
// 	(state, { id }: { id: TId }) => id,
// 	(sites, id): TSite => sites[id],
// )((state, { id }) => `sites@getSite-${id}`);

export default {};
