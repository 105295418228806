// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
	withAbsolute,
	withAbsoluteDrag,
	withAbsoluteDropPlace,
} from '@graphite/abs-grid';
import type { TWidgetOwnProps } from '@graphite/types';

import withSymbiote from 'Editor/libs/with-symbiote';
import withDrag from 'Widget/libs/dnd/drag';
import withDragReorder from 'Widget/libs/dnd/dragReorder';
import withFilter from 'Widget/libs/with-filter';
import withWidgetControls, { withWidgetResizer } from 'Widget/libs/with-widget-controls';
import mapDispatchToPropsEdit from 'Widget/libs/primitive/mapDispatchToPropsEdit';
import withWidgetEdit from 'Widget/libs/with-widget-edit';
import withControls from 'Editor/libs/with-controls';
import withUnedit from 'Widget/libs/with-unedit';
import checkActiveWidget from 'Editor/libs/check-active-widget';
import withRef from 'Widget/libs/with-ref';

import type {
	TConnectPropsEdit,
	TConnectPropsDrag,
	TConnectProps,
} from './constants/types';
import mapStateToPropsEdit from './libs/mapStateToPropsEdit';

import Stack from './Stack';
import Controls from './Controls';

const filter = (props: TConnectPropsDrag): TConnectProps => ({
	id: props.id,
	containerId: props.id,
	instanceId: props.instanceId,
	babies: props.babies,
	gridspec: props.gridspec,
	colorspec: props.colorspec,
	widgetspec: props.widgetspec,
	effectspec: props.effectspec,
	data: props.data,
	position: props.position,
	dispatch: props.dispatch,
	dragContainer: props.dragContainer,
	dragFlip: props.dragFlip,
	rowId: props.rowId,
	widgetChain: props.widgetChain,
	direction: props.direction,
	onClick: props.onClick,
});

const StackEdit = compose(
	// берём данные из стейта
	connect<TConnectPropsEdit, TWidgetOwnProps, _, _, _, _>(
		mapStateToPropsEdit,
		mapDispatchToPropsEdit,
	),
	// добавляет реф, который прокидывается через все ХОКи прямо вглубь
	Component => withRef<TConnectPropsEdit>(Component),
	// позволяет выходить из виджета по клику в пустое место блока
	Component => withUnedit<TConnectPropsEdit>(Component),
	// меняет порядок виджетов в стэке при драге
	withDragReorder,
	withDrag,
	withWidgetResizer,
	// Выносим ХОКи на служебный слой
	withSymbiote(
		// Добавляет возможность таскать абсолютные виджеты
		withAbsoluteDrag,
		{
			level: 'abs-drag-place',
		},
	),
	withSymbiote(
		compose(
			withWidgetEdit,
			withWidgetControls({ margin: true, height: true, width: true }),
			withControls(checkActiveWidget, Controls),
		),
	),
	// Выносим ХОКи на служебный слой
	withSymbiote(
		// добавляет подсвеику дропа абсолтного виджета
		Component =>
			withAbsoluteDropPlace(
				{ name: 'Stack', type: 'stack', kind: 'absolute' },
				Component,
			),
		{
			margin: false,
			level: 'abs-drag-place',
		},
	),
	withAbsolute<TConnectPropsDrag>(),
	Stack => withFilter<TConnectPropsDrag, TConnectProps>(filter, Stack),
)(Stack);

export default StackEdit;
