// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import getDisplayName from '@graphite/get-display-name';
import { closestDeviceWithKey, getBlockBreakpointInfo } from '@graphite/selectors';

import DropPlace from 'Widget/libs/dnd/DropPlace';
import type { TWidgetBoxBreakpoint } from '@graphite/types';
import type { TConnectPropsWithDropPlace } from '../constants/types';

const withDropPlace = <
	TProps: TConnectPropsWithDropPlace,
	WrapedComponent: React$ComponentType<TProps>,
>(
	Component: WrapedComponent,
): React$ComponentType<TProps> => {
	const WithDropPlace = (props: TProps) => {
		const { gridspec, data, currentDevice } = props;
		const { positions = emptyObject, children = emptyObject } = data;
		const gridChildrenNum = React.useMemo(
			() =>
				_.flow(
					_.entries,
					_.filter(([, realId]) => !!realId),
					_.filter(([refId]) => {
						if (!positions[refId]) {
							return true;
						}
						return !positions[refId].includes('absolute');
					}),
					_.size,
				)(children),
			[positions, children],
		);

		const breakpoint = closestDeviceWithKey(gridspec.breakpoints, {
			currentDevice,
			key: `breakpoint-${gridspec._id}`,
		});
		const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
			currentDevice,
			key: `box-${data._id}`,
		});
		const breakpointInfo = getBlockBreakpointInfo({
			breakpoint,
			box,
			unit: gridspec.unit,
		});

		const blockWidth = React.useMemo(
			() =>
				breakpointInfo.width < 0
					? `${breakpointInfo.width * -100}%`
					: `${breakpointInfo.width}px`,
			[breakpointInfo.width],
		);

		return (
			gridChildrenNum === 0 && (
				<DropPlace
					originId={props.originId}
					containerId={props.id}
					direction="horizontal"
					placement="full"
					width={blockWidth}
				/>
			)
		);
	};

	WithDropPlace.displayName = `withDropPlace(${getDisplayName(Component)})`;

	return React.memo<TProps>(WithDropPlace);
};

export default withDropPlace;
