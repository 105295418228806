// @flow
import React from 'react';
import { Box, Text } from 'rebass';

type TProps = $ReadOnly<{|
	checked: boolean,
	label?: string,
	onClick: MouseEvent => void,
|}>;

const styleLabel = {
	ml: '12px',
};

const Switch = ({ checked, label = '', ...props }: TProps) => {
	const id = React.useMemo(() => `switch-${`${Math.random()}`.slice(2)}`, []);

	const transformStyle = React.useMemo(
		() => ({
			transform: checked ? 'translateX(12px)' : 'translateX(0)',
		}),
		[checked],
	);

	return (
		<>
			<Box
				id={id}
				as="button"
				type="button"
				role="switch"
				variant="switch"
				aria-checked={checked}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				<Box aria-hidden style={transformStyle} />
			</Box>
			{label && (
				<Text as="label" variant="bodysm" sx={styleLabel} htmlFor={id}>
					{label}
				</Text>
			)}
		</>
	);
};

export default React.memo<TProps>(Switch);
