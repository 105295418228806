// @flow

import React from 'react';
import emotionReset from 'emotion-reset';
import styled from '@emotion/styled';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { CacheProvider, Global, css } from '@emotion/core';
import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';

// https://webpack-v3.jsx.app/loaders/raw-loader/#inline
/* $FlowFixMe(>=0.102.0) raw-loader
 * */
import awesomeStyles from '!!raw-loader!@fortawesome/fontawesome-svg-core/styles.css'; // eslint-disable-line max-len, import/extensions, import/no-webpack-loader-syntax
import FrameSetter from './Setter';

const FrameStyled = styled(Frame)`
	position: relative;
	display: block;
	width: 100%;
`;

const frameStyle = css`
	${awesomeStyles}
	${emotionReset}
	
	body {
		overflow: hidden;
	}
`;

const memoizedCreateCacheWithContainer = weakMemoize(container =>
	createCache({ container }),
);

type TProps = $ReadOnly<{|
	children: React$Node,
	width: number,
	scale: number,
|}>;

const GraphiteFrame = ({ children, width, scale }: TProps) => {
	const [bodyRef, setBodyRef] = React.useState(null);
	const [height, setHeight] = React.useState(0);

	React.useEffect(() => {
		const body = bodyRef;
		if (!body) return;

		const resizeObserver = new ResizeObserver(() => {
			const height = Math.max(body.scrollHeight, body.offsetHeight);
			setHeight(height);
		});

		resizeObserver.observe(body);

		return () => {
			resizeObserver.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bodyRef, setHeight]);

	const style = React.useMemo(
		() => ({
			height: `${height}px`,
			width: `${width}px`,
			transform: `scale(${scale})`,
		}),
		[height, width, scale],
	);

	return (
		<FrameStyled style={style}>
			<FrameContextConsumer>
				{({ window, document }) => (
					<>
						<FrameSetter window={window} document={document} />
						<CacheProvider
							value={memoizedCreateCacheWithContainer(document.head)}
						>
							<div ref={setBodyRef}>
								<Global styles={frameStyle} />
								{children}
							</div>
						</CacheProvider>
					</>
				)}
			</FrameContextConsumer>
		</FrameStyled>
	);
};

export default React.memo<TProps>(GraphiteFrame);
