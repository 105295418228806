// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAbsolute } from '@graphite/abs-grid';

import type { TWidgetOwnProps } from '@graphite/types';

import getMapStateToProps from '../../libs/primitive/getMapStateToProps';
import Button from './Button';
import type { TConnectProps } from './constants/types';
import withFonts from './libs/with-fonts';

const WidgetComponent = compose(
	connect<TConnectProps, TWidgetOwnProps, _, _, _, _>(
		getMapStateToProps<TConnectProps>(),
	),
	withAbsolute<TConnectProps>(),
	withFonts,
)(Button);

export default WidgetComponent;
