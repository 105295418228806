// @flow
import React from 'react';
import type { TId } from '@graphite/types';

type TUseScrollEffectParams = $ReadOnly<{|
	pageId: ?TId,
	ref: {| current: ?HTMLElement |},
|}>;
type TUseScrollEffectHook = TUseScrollEffectParams => void;

const useScrollEffect: TUseScrollEffectHook = ({
	pageId,
	ref,
}: TUseScrollEffectParams) => {
	React.useEffect(() => {
		if (pageId && ref.current) {
			const el: HTMLElement = ref.current;

			el.scrollTo(el.scrollLeft, Math.floor(window.innerHeight * 0.8 - 100));
		}
	}, [ref, pageId]);

	React.useEffect(() => {
		if (pageId && ref.current) {
			const el: HTMLElement = ref.current;

			el.scrollTo(Math.floor(window.innerWidth * 0.8), el.scrollTop);
		}
	}, [ref, pageId]);
};

export default useScrollEffect;
