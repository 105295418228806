// @flow
import _ from 'lodash/fp';
import type { TStateEditor, TProjects, TProject } from '@graphite/types';

import reSelect from './libs/re-select';

type TGetProjects = TStateEditor => TProjects;
export const getProjects: TGetProjects = reSelect(
	({ projects }) => projects,
	(projects): TProjects => _.pickBy(p => !p.removedAt, projects),
)(() => `projects@getProjects`);

type TGetProject = (TStateEditor, { id: string }) => TProject;
export const getProject: TGetProject = reSelect(
	getProjects,
	(state, { id }: { id: string }) => id,
	(projects, id): TProject => projects[id],
)((state, { id }) => `projects@getProject-${id}`);

export default {};
