// @flow
import React from 'react';
import type { TSx, TColorsProp } from '@graphite/types';
import ButtonDeprecated from '../ButtonDeprecated';

type TToolbarBtnProps = $ReadOnly<{|
	children?: React$Node,
	iconName?: string,
	isActive?: boolean,
	variant?: 'sm' | 'md',
	sx?: TSx,
	onMouseDown?: (MouseEvent, ?string) => void,
	colors?: ?TColorsProp,
|}>;

const toolbarBtnSx = {
	sm: { width: 30 },
	md: { width: 36 },
};

const iconSizes = {
	sm: 'xxsm',
	md: 'xsm',
};

const ToolbarBtn = ({ iconName, variant = 'md', ...props }: TToolbarBtnProps, ref) => {
	const icons = React.useMemo(
		() =>
			iconName
				? [
						{
							name: iconName,
							size: iconSizes[variant],
						},
				  ]
				: undefined,
		[iconName, variant],
	);

	return (
		<ButtonDeprecated
			sx={toolbarBtnSx[variant]}
			variant="flat"
			colors="primaryflat"
			icons={icons}
			size={variant}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			ref={ref}
		/>
	);
};

export default React.memo<TToolbarBtnProps>(React.forwardRef(ToolbarBtn));
