// @flow
import React from 'react';
import { Box, Flex } from '@graphite/uneon';
import type { TParamSource, TParams, TListParamsOnClick } from '@graphite/types';

import ListItemParams from './ListItemParams';

type TProps = $ReadOnly<{|
	paramSource: TParamSource,
	paramList: TParams,
	listName?: string,
	unit?: ?number,
	onChange?: (string, ?(string | number | $ReadOnlyArray<number> | boolean)) => void,
	onClick?: ?TListParamsOnClick,
|}>;

const paramListStyle = {
	flexDirection: 'column',
};

const separatorOuterSx = {
	position: 'relative',
};

const separatorSx = {
	position: 'absolute',
	left: '-24px',
	right: '-24px',
	bottom: '1px',
	height: '1px',
	backgroundColor: 'bg.primaryalt',
};

const ListParams = ({
	paramSource,
	paramList,
	listName = '',
	unit = null,
	onChange = null,
	onClick = null,
}: TProps) => {
	return (
		<Flex sx={paramListStyle}>
			{paramList.map((param, i) => {
				if (param.hidden && param.hidden.includes(listName)) {
					return null;
				}
				return (
					<React.Fragment key={`${listName}-${param.key}`}>
						<ListItemParams
							paramSource={paramSource}
							param={param}
							unit={unit || 1}
							onChange={onChange}
							onClick={onClick}
						/>
						{i < paramList.length - 1 && (
							<Box sx={separatorOuterSx}>
								<Box sx={separatorSx} />
							</Box>
						)}
					</React.Fragment>
				);
			})}
		</Flex>
	);
};

ListParams.defaultProps = {
	listName: '',
	unit: null,
	onChange: null,
	onClick: null,
};

export default React.memo<TProps>(ListParams);
