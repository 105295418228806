// @flow
import React, { memo, useCallback, useMemo } from 'react';
import { Flex } from 'rebass';
import _ from 'lodash/fp';

import type {
	TButtonGroupEmbed,
	TButtonEmbedNamed,
	TButtonGroupOnClick,
	TButtonShape,
	TButtonGroupShape,
} from '@graphite/types';
import ButtonDeprecated from '../ButtonDeprecated';

type TProps = $ReadOnly<{|
	...TButtonGroupEmbed,
	onClick?: ?TButtonGroupOnClick,
	className?: string,
|}>;

const containerStyle = {
	alignItems: 'center',
	border: 'none',
	justifyContent: 'center',
};

function ButtonGroup({
	buttons,
	icons = null,
	colors = null,
	activeColors = null,
	size = null,
	isDisabled = false,
	isWide = false,
	isColumn = false,
	as = 'div',
	behavior = 'button',
	sx = null,
	margin = 0,
	style = null,
	active = null,
	variant = 'normal',
	shape = 'normal',
	onClick = null,
	className = null,
}: TProps) {
	const clickBound = useCallback(
		(e, name) => {
			if (!name || !onClick) {
				return;
			}
			if (behavior !== 'checkbox') {
				onClick(e, name);
				return;
			}
			const activeArray = typeof active === 'string' || !active ? [] : active;
			if (activeArray && activeArray.includes(name)) {
				const nameAt = activeArray.findIndex(v => name === v);
				onClick(e, [
					...activeArray.slice(0, nameAt),
					...activeArray.slice(nameAt + 1),
				]);
				return;
			}
			onClick(e, [...activeArray, name]);
		},
		[onClick, active, behavior],
	);

	const groupShape: TButtonGroupShape = shape;
	const getShape = useCallback(
		(isFirst, isLast): ?TButtonShape => {
			if (variant === 'flat' || margin > 0 || groupShape === 'underlined') {
				return null;
			}
			if (isFirst) {
				if (isColumn) {
					return groupShape === 'normal' ? 'normal.top' : 'rounded.top';
				}
				return groupShape === 'normal' ? 'normal.left' : 'rounded.left';
			}
			if (isLast) {
				if (isColumn) {
					return groupShape === 'normal' ? 'normal.bottom' : 'rounded.bottom';
				}
				return groupShape === 'normal' ? 'normal.right' : 'rounded.right';
			}
			return 'none';
		},
		[isColumn, margin, groupShape, variant],
	);

	const modifiedButtons: $ReadOnlyArray<TButtonEmbedNamed> = useMemo(() => {
		const lastI = buttons.length - 1;
		return buttons.map((button, i) => {
			const isActive =
				(active &&
					((typeof active === 'string' && active === button.name) ||
						(typeof active !== 'string' && active.includes(button.name)))) ||
				false;
			return {
				...button,
				isActive: activeColors ? false : isActive,
				icons: button.icons || icons,
				size: button.size || size,
				colors: (isActive && activeColors) || button.colors || colors,
				isDisabled: button.isDisabled || isDisabled,
				as: button.as || as,
				variant: button.variant || variant,
				shape: getShape(i === 0, i === lastI) || button.shape || shape,
				style: _.assignAll([
					{
						marginLeft:
							(margin !== 0 && (i !== 0 ? `${margin}px` : 0)) || null,
						flexGrow: 1,
					},
					button.style,
				]),
			};
		});
	}, [
		active,
		activeColors,
		as,
		shape,
		buttons,
		colors,
		icons,
		isDisabled,
		size,
		variant,
		margin,
		getShape,
	]);

	const containerThemedStyle = useMemo(
		() => ({
			...containerStyle,
			...sx,
		}),
		[sx],
	);
	const containerDynamicStyle = useMemo(
		() => ({
			flexDirection: isColumn ? 'column' : 'row',
			width: isWide ? '100%' : null,
			...style,
		}),
		[isColumn, isWide, style],
	);

	return (
		<Flex
			sx={containerThemedStyle}
			style={containerDynamicStyle}
			className={className}
		>
			{modifiedButtons.map(b => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<ButtonDeprecated key={b.name} {...b} onClick={clickBound} />
			))}
		</Flex>
	);
}

export default memo<TProps>(ButtonGroup);
