// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Box } from '@graphite/uneon';
import type { TSx, TDesign } from '@graphite/types';
import { getBoxSx, getDesignSx } from '@graphite/selectors';
import useDynamicStyle from 'Widget/libs/use-dynamic-style';

import type { TConnectProps } from './constants/types';

const boxBaseSx = {
	overflow: 'hidden',
	position: 'relative',
	flexShrink: 0,
	backgroundSize: 'contain',
	backgroundPosition: '50% 50%',
	backgroundRepeat: 'no-repeat',
};

const baseSx = {
	overflow: 'visible',
	height: '100%',
};

const Wrap = (props: TConnectProps, ref) => {
	const {
		children,
		dragContainer,
		dragFlip,
		data,
		position,
		direction,
		colorspec,
		widgetspec,
		effectspec,
		gridspec,
		className = null,
		isPassive = false,
	} = props;

	const { link } = data;

	// FIXME вынести в отдельный fnSX, для получения нужных свойств
	const boxShadow: ?string = React.useMemo(() => {
		if (!data.designId) {
			return null;
		}

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			(custom && custom.target === 'image' ? custom : null) ||
			widgetspec.image.find(d => d._id === data.designId);
		if (!design) {
			return null;
		}
		const { boxShadow } = getDesignSx({
			design,
			widgetspec,
			colorspec,
			effectspec,
			gridspec,
		});
		return typeof boxShadow === 'string' ? boxShadow : null;
	}, [data, widgetspec, colorspec, effectspec, gridspec]);

	const boxSx: TSx = React.useMemo(() => {
		const containerSx = _.assign(
			boxBaseSx,
			getBoxSx({
				data,
				position,
				direction,
				gridspec,
			}),
		);

		if (!boxShadow) {
			return containerSx;
		}

		return _.assign(containerSx, { boxShadow });
	}, [data, position, direction, gridspec, boxShadow]);

	const innerProps = React.useMemo(() => {
		if (isPassive || !(link && link.href)) {
			return { as: 'div', sx: baseSx };
		}
		return {
			as: 'a',
			href: link.href,
			target: link.isNewTab ? '_blank' : 'self',
			sx: baseSx,
		};
	}, [isPassive, link]);

	const dynamicStyle: ?TSx = useDynamicStyle(data.style);

	const otherProps = _.assign(dragContainer, dragFlip);

	return (
		<Box
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...otherProps}
			sx={boxSx}
			style={dynamicStyle}
			ref={ref}
			className={className}
		>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<Box {...innerProps}>{children}</Box>
		</Box>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Wrap));
