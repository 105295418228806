// @flow

import React from 'react';
import emptyObject from 'empty/object';
import { set, mapValues } from 'lodash/fp';

import reSelect from 'libs/re-select';
import logger from 'libs/logger';
import { editWidget } from 'Editor/ducks/widgets';
import type {
	TId,
	TAction,
	TSizesBreakpoint,
	TWidgetBoxBreakpoint,
} from '@graphite/types';
import type { TColumnsGroup } from 'libs/types/calc-columns';
import type { TConnectEditProps } from 'Widget/Widgets/Block/constants/types';
import {
	getColSizeMap,
	getTrueWidgetIds,
	closestDeviceWithKey,
	getBlockBreakpointInfo,
} from '@graphite/selectors';
import { resize, getDeltaRange } from 'libs/calc-rows';

type TMinimalProps = $ReadOnly<{
	data: $PropertyType<TConnectEditProps, 'data'>,
	colAmount: $PropertyType<TConnectEditProps, 'colAmount'>,
	currentDevice: $PropertyType<TConnectEditProps, 'currentDevice'>,
	blockSizes: $PropertyType<TConnectEditProps, 'blockSizes'>,
	dispatch: $PropertyType<TConnectEditProps, 'dispatch'>,
	instanceId: $PropertyType<TConnectEditProps, 'instanceId'>,
	originId: $PropertyType<TConnectEditProps, 'originId'>,
	colSizeMap: $PropertyType<TConnectEditProps, 'colSizeMap'>,
	gutter: $PropertyType<TConnectEditProps, 'gutter'>,
	gridspec: $PropertyType<TConnectEditProps, 'gridspec'>,
	setGridHighlight: ($PropertyType<TConnectEditProps, 'gridHighlight'>) => void,
}>;

export type TContext = $ReadOnly<{|
	isResize: boolean,
	initialData: $PropertyType<TMinimalProps, 'data'>,
	data: $PropertyType<TMinimalProps, 'data'>,
	gridspec: $PropertyType<TMinimalProps, 'gridspec'>,
	currentDevice: $PropertyType<TMinimalProps, 'currentDevice'>,
	colAmount: $PropertyType<TMinimalProps, 'colAmount'>,
	blockSizes: $PropertyType<TMinimalProps, 'blockSizes'>,
	deltaRange: [number, number],
	siblingId: ?TId,
	dispatch: $PropertyType<TMinimalProps, 'dispatch'>,
	instanceId: ?TId,
	originId: ?TId,
	colSize: number,
	colSizeMap: TColumnsGroup,
	side: 'left' | 'right' | null,
	dragId: ?TId,
	gutter: $PropertyType<TMinimalProps, 'gutter'>,
	dragWidgetOriginalsize: number,
	delta: number,
	initialDragSize?: string,
	setGridHighlight: boolean => void,
	blockWidth: number,
	ref: React$ElementRef<*>,
|}>;

type ResizeData = [TContext, (TAction) => void];
type TUseResize = (TMinimalProps, React$ElementRef<*>) => ResizeData;
type TResizeDataStart = $ReadOnly<{|
	dir: 'left' | 'right',
	dragId: TId,
|}>;

type TResizeDataUpdate = $ReadOnly<{|
	delta: number,
|}>;

const getColSize = reSelect<?TSizesBreakpoint, number, number, number, number>(
	size => (size ? parseFloat(size.width) : 0),
	(colSizeMap, colAmount: number) => colAmount,
	(width, colAmount) => colAmount * width,
)(() => 'row@getColSize');

const getStringify = (
	{ width, margin: { left, right } } = {
		width: '',
		margin: { left: '', right: '' },
	},
) => `${width}-${left}-${right}`;

export const resizeStart = (state: TContext, resizeData: TResizeDataStart) => {
	const { data, colAmount, currentDevice, gridspec, ref } = state;
	const { dir, dragId } = resizeData;
	const initialDragSize = getStringify(data.sizes?.[dragId][currentDevice]);

	// Тут прям очень надо +1, ибо медиа -1 имеют.
	const maxWidth = ref.current ? ref.current.offsetWidth : window.innerWidth;

	const colSizeMap = getColSizeMap({
		data,
		currentDevice,
		colAmount,
		orderList: getTrueWidgetIds({ ...data, currentDevice }),
	});

	let ids = [];

	colSizeMap.some(({ orderList }) => {
		ids = orderList.map(({ trueId }) => trueId);

		if (ids.includes(dragId)) {
			return true;
		}

		return false;
	});

	const colSize = getColSize(data.sizes?.[dragId][currentDevice], colAmount);

	const index = ids.indexOf(dragId);

	const siblingId = dir === 'right' ? ids[index + 1] : ids[index - 1];

	const [min, max] = getDeltaRange({
		widget: { ...data },
		colAmount,
		srcId: dragId,
		side: dir,
		currentDevice,
	});

	const breakpoint = closestDeviceWithKey(gridspec.breakpoints, {
		currentDevice,
		key: `breakpoint-${gridspec._id}`,
	});
	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice,
		key: `box-${data._id}`,
	});
	const breakpointInfo = getBlockBreakpointInfo({
		breakpoint,
		box,
		unit: gridspec.unit,
	});

	// Я понимаю, что если там 0 то он превратится во 100%, и даже хочу этого
	// Ну это странно как то, нужно ограничить ввод в поле
	const blockWidth =
		breakpointInfo.width < 0
			? -maxWidth * breakpointInfo.width
			: breakpointInfo.width + (breakpointInfo.gutter || 0);

	const widthWithMargin = blockWidth + breakpointInfo.padding * 2;

	const blockCoreWidth =
		widthWithMargin >= maxWidth
			? maxWidth - breakpointInfo.padding * 2 + (breakpointInfo.gutter || 0)
			: blockWidth;

	const { width: colWidthPercent = 1 } = data.sizes?.[dragId][currentDevice] ?? {};
	const colWidth = blockCoreWidth * colWidthPercent;
	const colGutter =
		(breakpointInfo.gutter || 0) < 0
			? -colWidth * breakpointInfo.gutter
			: breakpointInfo.gutter;

	const dragWidgetOriginalsize: number = colWidth - colGutter;
	const deltaRange: [number, number] = [blockCoreWidth * min, blockCoreWidth * max];

	return {
		siblingId,
		deltaRange,
		isResize: true,
		colSize,
		side: dir,
		initialDragSize,
		dragId,
		dragWidgetOriginalsize,
		blockWidth: blockCoreWidth,
	};
};

export const resizeStop = (state: TContext) => {
	const { dispatch, data, instanceId, originId } = state;
	if (!originId) return;

	dispatch(
		editWidget(data._id, instanceId, originId, {
			sizes: data.sizes,
		}),
	);

	return {
		siblingId: null,
		deltaRange: [0, 0],
		isResize: false,
		side: null,
		dragId: null,
		delta: 0,
	};
};

export const resizeUpdate = (state: TContext, resizeData: TResizeDataUpdate) => {
	const { data, colAmount, blockWidth, currentDevice, side, dragId } = state;

	const { delta } = resizeData;

	if (side !== 'right' && side !== 'left') {
		logger.error('resizeUpdate side dont exist', side);
		return emptyObject;
	}

	if (!dragId) {
		logger.error('resizeUpdate dragId dont exist', dragId);
		return emptyObject;
	}

	const newData = resize({
		widget: { ...state.initialData },
		srcId: dragId,
		colAmount,
		currentDevice,
		side,
		value: delta / blockWidth,
	});

	const colSize = getColSize(newData.sizes[dragId][currentDevice], colAmount);

	const dragSize = getStringify(newData.sizes[dragId][currentDevice]);

	if (dragSize === state.initialDragSize) return { delta };

	const colSizeMap = getColSizeMap({
		data: newData,
		currentDevice,
		colAmount,
		orderList: getTrueWidgetIds({ ...newData, currentDevice }),
	});

	return {
		data: {
			...data,
			sizes: newData.sizes,
		},
		initialDragSize: dragSize,
		colSize,
		colSizeMap,
		dragId,
		delta,
	};
};

const resizeReducer = (state: TContext, action) => {
	switch (action.type) {
		case 'start': {
			return {
				...state,
				...resizeStart(state, action.payload),
			};
		}
		case 'stop': {
			return {
				...state,
				...resizeStop(state),
			};
		}
		case 'update': {
			return {
				...state,
				...resizeUpdate(state, action.payload),
			};
		}
		case 'reset': {
			return {
				...state,
				...action.payload,
				initialData: action.payload.data,
				colSizeMap: action.payload.colSizeMap,
				colAmount: action.payload.colAmount,
				blockSizes: action.payload.blockSizes,
			};
		}
		default:
			logger.error('resizeUpdate action not valid', action);
			return state;
	}
};

const extendDataSizes = (data, currentDevice) =>
	set(
		'sizes',
		mapValues(
			column => ({
				...column,
				[`${currentDevice}`]:
					column[currentDevice] ||
					closestDeviceWithKey(column, {
						currentDevice,
						key: `column-${data._id}`,
					}),
			}),
			data.sizes,
		),
		data,
	);

export const useResize: TUseResize = (props: TMinimalProps, ref) => {
	const resize = React.useReducer(resizeReducer, props, props => {
		const {
			data,
			colAmount,
			currentDevice,
			blockSizes,
			dispatch,
			instanceId,
			originId,
			colSizeMap,
			gutter,
			gridspec,
			setGridHighlight,
		} = props;

		const dataWithSizes = extendDataSizes(data, currentDevice);

		return {
			initialData: dataWithSizes,
			data: dataWithSizes,
			currentDevice,
			colAmount,
			blockSizes,
			isResize: false,
			deltaRange: [0, 0],
			siblingId: null,
			dispatch,
			gridspec,
			instanceId,
			originId,
			colSize: 0,
			colSizeMap,
			side: null,
			dragId: null,
			gutter,
			dragWidgetOriginalsize: 0,
			delta: 0,
			setGridHighlight,
			blockWidth: 0,
			ref,
		};
	});

	const {
		data,
		colSizeMap,
		currentDevice,
		colAmount,
		blockSizes,
		setGridHighlight,
		gridspec,
	} = props;
	const [resizeData, dispatch] = resize;

	const countRender = React.useRef(0);
	React.useEffect(() => {
		if (countRender.current > 0)
			dispatch({
				type: 'reset',
				payload: {
					data: extendDataSizes(data, currentDevice),
					colSizeMap,
					currentDevice,
					colAmount,
					setGridHighlight,
					blockSizes,
					gridspec,
				},
			});
		++countRender.current;
	}, [
		dispatch,
		colAmount,
		blockSizes,
		gridspec,
		data,
		colSizeMap,
		currentDevice,
		setGridHighlight,
	]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return React.useMemo(() => resize, [resizeData]);
};

const ResizeContext = React.createContext<ResizeData>([
	{
		initialData: {
			_id: '',
			display: 'normal',
			kind: 'block',
			protoId: '',
			scope: 'site',
			scopeId: '',
			userId: '',
			sizes: {},
			box: {},
		},
		data: {
			_id: '',
			display: 'normal',
			kind: 'block',
			protoId: '',
			scope: 'site',
			scopeId: '',
			userId: '',
			sizes: {},
			box: {},
		},
		gridspec: {},
		currentDevice: 'desktop',
		colAmount: 0,
		blockSizes: {},
		isResize: false,
		deltaRange: [0, 0],
		siblingId: null,
		dispatch: () => ({ payload: '', type: '' }),
		instanceId: null,
		originId: '',
		colSize: 0,
		colSizeMap: [],
		side: null,
		dragId: null,
		gutter: [0],
		dragWidgetOriginalsize: 0,
		delta: 0,
		setGridHighlight: () => {},
		blockWidth: 0,
		ref: {
			current: null,
		},
	},
	() => {},
]);

export default ResizeContext;
