// @flow
import React from 'react';
import loadable from '@loadable/component';
import type { TWidget, TGetComponent, TWidgetOwnProps } from '@graphite/types';
import ComponentContext from 'ComponentContext';
import { composeCached } from 'Widget/selectors';
import { useSelector } from '@graphite/use-redux';
import { getWidgets } from '@graphite/selectors';

const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

type TComponent = React$ComponentType<TWidgetOwnProps>;

const Widget = (props: TWidgetOwnProps) => {
	const {
		id,
		containerId,
		instanceId,
		rowId,
		widgetChain,
		gridspec,
		widgetspec,
		colorspec,
		effectspec,
	} = props;
	const components = React.useRef({});

	const getComponent = React.useContext<TGetComponent>(ComponentContext);

	const widgets = useSelector(getWidgets);
	const widget = widgets[id];
	const data: ?TWidget = widget ? composeCached(widgets, widget) : null;

	const Component: ?TComponent = React.useMemo(() => {
		if (!data) return;
		const { kind } = data;
		if (!kind) return;

		if (components.current[kind]) return components.current[kind];

		if (process.env.ENV_MODE === 'server') {
			components.current[kind] = require(`./Widgets/${capitalize(
				kind,
			)}/index`).default;
		} else {
			components.current[kind] = loadable(() => getComponent(kind));
		}

		return components.current[kind];
	}, [data, getComponent]);

	if (!Component) {
		return null;
	}

	return (
		<Component
			rowId={rowId}
			id={id}
			containerId={containerId}
			instanceId={instanceId}
			widgetChain={widgetChain}
			widgetspec={widgetspec}
			colorspec={colorspec}
			gridspec={gridspec}
			effectspec={effectspec}
		/>
	);
};

export default React.memo<TWidgetOwnProps>(Widget);
