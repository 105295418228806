// @flow

import _ from 'lodash/fp';
import { eventChannel, type EventChannel } from 'redux-saga';
import { takeEvery, put, fork, take, cancelled } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import type { Saga } from 'redux-saga';
import type {
	TId,
	TAction,
	TQueryConstraints2,
	TQueryConstraint,
	TProject,
	TProjects,
} from '@graphite/types';
import { defaultProject } from '@graphite/constants';

import { collection, genId } from 'libs/firebase';
import logger from 'libs/logger';

import { APPLY } from './editor';

const UPDATE_PROJECT = 'PROJECTS/UPDATE_PROJECT';
const APPLY_PROJECTS = 'PROJECTS/APPLY_PROJECTS';
const FETCH_IF_NEEDED = 'PROJECTS/FETCH_IF_NEEDED';
const INSERT_PROJECT = 'PROJECTS/INSERT_PROJECT';
const REMOVE_PROJECT = 'PROJECTS/REMOVE_PROJECT';

const initialState: TProjects = {};

export const fetchIfNeeded = (id: ?TId): TAction => ({
	type: FETCH_IF_NEEDED,
	payload: { id },
});

export const insertProject = (userId: TId): TAction => ({
	type: INSERT_PROJECT,
	payload: { userId },
});

export const removeProject = (id: TId): TAction => ({
	type: REMOVE_PROJECT,
	payload: { id },
});

export const updateProject = (id: TId, project: $Shape<TProject>): TAction => ({
	type: UPDATE_PROJECT,
	payload: { id, project },
});

const applyProjects = (projects: TProjects): TAction => ({
	type: APPLY_PROJECTS,
	payload: { projects },
});

/**
	Коллаборация
 */
const getProjectsEventsChannel = (
	userId: string,
): EventChannel<{ projects: TProjects }> => {
	const minDate = new Date().toISOString();
	return eventChannel<{ projects: TProjects }>(
		(emit: ({ projects: TProjects }) => void): (() => void) => {
			const unsubscriptors: $ReadOnlyArray<() => void> = [
				{ scope: ['==', 'system'], scopeId: ['==', null] },
				{
					scope: ['==', 'market'],
					scopeId: ['==', userId],
					removedAt: ['==', null],
				},
				{
					scope: ['==', 'market'],
					scopeId: ['==', userId],
					removedAt: ['>', minDate],
				},
				// FixMe: написать правило, запрещающее смотреть чужие проекты
				{
					scope: ['==', 'user'],
					scopeId: ['==', userId],
					removedAt: ['==', null],
				},
				{
					scope: ['==', 'user'],
					scopeId: ['==', userId],
					removedAt: ['>', minDate],
				},
			].map((constraints: TQueryConstraints2): (() => void) => {
				let projects = collection('projects').where('userId', '==', userId);
				_.forEach(([k, v]: [string, TQueryConstraint]) => {
					projects = projects.where(k, v[0], v[1]);
				}, _.entries(constraints));
				return projects.onSnapshot((snapshot: any) => {
					const projects = {};
					snapshot.docChanges().forEach((change: any) => {
						const project = change.doc.data();
						projects[project._id] = project;
					});
					if (_.size(projects)) {
						emit({ projects });
					}
				});
			});
			// Return an unregister function
			return (): void => unsubscriptors.forEach((u: () => void): void => u());
		},
	);
};

export function* fetchIfNeededSaga(): Saga<void> {
	let projectsEventsChannel = null;
	yield takeEvery(FETCH_IF_NEEDED, function*({
		payload: { id },
	}: {
		payload: { id: ?string },
	}): any {
		if (projectsEventsChannel) {
			projectsEventsChannel.close();
			projectsEventsChannel = null;
		}

		if (!id) return;

		projectsEventsChannel = getProjectsEventsChannel(id);
		yield put(applyProjects({}));

		try {
			while (!0) {
				const { projects } = yield take(projectsEventsChannel);
				yield put(applyProjects(projects));
			}
		} catch (e) {
			logger.error(e);
		} finally {
			if (yield cancelled() && projectsEventsChannel) projectsEventsChannel.close();
		}
	});
}

export function* insertProjectSaga(): Saga<void> {
	yield takeEvery(INSERT_PROJECT, function*({
		payload: { userId },
	}: {
		payload: { userId: TId },
	}): Saga<void> {
		try {
			const newId = genId('projects');
			const newProj = {
				...defaultProject,
				// FixMe: тут дыра в безопасности
				// нужно проверять, что пользователь только под своим id создаёт сайты
				userId,
				_id: newId,
				name: `New project ${new Date().getSeconds()}`,
				scope: 'user',
				scopeId: userId,
			};
			yield collection('projects')
				.doc(newId)
				.set(newProj);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* removeProjectSaga(): Saga<void> {
	yield takeEvery(REMOVE_PROJECT, function*({
		payload: { id },
	}: {
		payload: { id: TId },
	}): Saga<void> {
		try {
			yield collection('projects')
				.doc(id)
				.set(
					{
						removedAt: new Date().toISOString(),
					},
					{ merge: true },
				);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* updateProjectSaga(): Saga<void> {
	yield takeEvery(UPDATE_PROJECT, function*({
		payload: { id, project },
	}: {
		payload: { id: TId, project: $Shape<TProject> },
	}): Saga<void> {
		try {
			yield collection('projects')
				.doc(id)
				.update(project);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* saga(): Saga<void> {
	yield fork(fetchIfNeededSaga);
	yield fork(insertProjectSaga);
	yield fork(removeProjectSaga);
	yield fork(updateProjectSaga);
}

export default handleActions<$ReadOnly<TProjects>, TAction>(
	{
		[APPLY_PROJECTS](
			state: $ReadOnly<TProjects>,
			{ payload: { projects } }: { +payload: { +projects: $ReadOnly<TProjects> } },
		): $ReadOnly<TProjects> {
			return _.assign(state, projects);
		},
		[APPLY](
			state: $ReadOnly<TProjects>,
			{ payload: { projects } }: { +payload: { +projects: TProjects } },
		): $ReadOnly<TProjects> {
			return _.pickBy(_.identity, _.assign(state, projects));
		},
	},
	initialState,
);
