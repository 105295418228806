// @flow
import emptyObject from 'empty/object';
import type {
	TStateEditor,
	TWidget,
	TPositioned,
	TWidgetOwnProps,
	TWidgetBoxBreakpoint,
} from '@graphite/types';
import { closestDeviceWithKey, getTrueIds, adjustColorspec } from '@graphite/selectors';

import { composeCached } from 'Widget/selectors';

import type { TWidgetStackComposed } from '../constants/types';

type TParrent = $ReadOnly<{
	...$Exact<TWidget>,
	positions: TPositioned,
}>;

const mapStateToProps = (
	{ widgets }: TStateEditor,
	{
		id,
		containerId: containerInitialId,
		widgetspec,
		colorspec,
		gridspec,
		effectspec,
	}: TWidgetOwnProps,
) => {
	const containerId = containerInitialId || 'none';

	const parentWidget = widgets[containerId];
	const parent: TParrent | null = parentWidget
		? composeCached(widgets, widgets[containerId])
		: null;
	const parentBox: ?TWidgetBoxBreakpoint = parent
		? closestDeviceWithKey(parent.box, {
				currentDevice: 'desktop',
				key: `box-${parent._id || 'null'}`,
		  })
		: null;
	const direction = parentBox?.flexDirection === 'row' ? 'horizontal' : 'vertical';
	const data: TWidgetStackComposed = composeCached(widgets, widgets[id]);

	const positions = parent ? parent.positions : emptyObject;

	const babies = getTrueIds({
		_id: data._id,
		children: data.children,
		order: data.order,
		currentDevice: 'desktop',
	});

	return {
		data,
		gridspec,
		colorspec: adjustColorspec({ data, colorspec, widgetspec, target: 'stack' }),
		widgetspec,
		effectspec,
		babies,
		position: positions[data._id],
		direction,
	};
};

export default mapStateToProps;
