// @flow

import { useEffect, useMemo } from 'react';
import { listForFontsLib } from '@graphite/constants';
import type { TFonts } from '@graphite/types';
import { loadFonts } from '../GoogleFonts/driver';

type TOptions = $ReadOnly<{|
	isFrame?: boolean,
|}>;

const useGoogleFonts = (fonts: TFonts, { isFrame = false }: TOptions) => {
	const fontsGoogle = useMemo(() => {
		return fonts.filter(
			font =>
				font.family &&
				!listForFontsLib.find(
					conf => conf.name === font.family || conf.family === font.family,
				),
		);
	}, [fonts]);

	useEffect(() => {
		loadFonts(fontsGoogle, isFrame);
	}, [fontsGoogle, isFrame]);
};

export default useGoogleFonts;
