// @flow
import reSelect from 'libs/re-select';
import type { TStateEditor, TUser } from '@graphite/types';

type TGetIsLoading = TStateEditor => boolean;
export const getIsLoading: TGetIsLoading = reSelect(
	({ users }) => users.isFetching,
	({ users }) => users.didInvalidate,
	(isFetching, didInvalidate) => {
		// ToDo: разобраться, почему стейт не успевает подгрузиться
		if (typeof isFetching !== 'boolean') return true;

		return isFetching || didInvalidate;
	},
)(() => 'users@getIsLoading');

type TGetCurrentUser = TStateEditor => ?TUser;
export const getCurrentUser: TGetCurrentUser = reSelect(
	({ users }) => users.currentUser,
	({ users }) => users.items,
	(currentUser, users) => (currentUser ? users[currentUser] : null),
)(() => 'users@getCurrentUser');

type TGetAuthError = TStateEditor => $PropertyType<
	$PropertyType<TStateEditor, 'users'>,
	'authError',
>;
export const getAuthError: TGetAuthError = reSelect(
	({ users }) => users.authError,
	authError => authError,
)(() => 'users@getAuthError');

type TGetIsAuth = TStateEditor => boolean;
export const getIsAuth: TGetIsAuth = reSelect(
	({ users }) => users.isAuth,
	isAuth => isAuth,
)(() => 'users@getIsAuth');

export default {};
