// @flow
import React from 'react';
import _ from 'lodash/fp';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import CursorProvider from 'CursorProvider';
import emptyObject from 'empty/object';
import { ThemeProvider } from 'emotion-theming';
import { themes } from '@graphite/uneon';
import styled from '@emotion/styled';
import type { TId } from '@graphite/types';

import { useSiteId, useProjectId, usePageId } from '@graphite/use-location';
import { useDispatch } from '@graphite/use-redux';
import { getSite } from '@graphite/selectors';

import './libs/i18n';
import TopBar from './TopBar';
import Page from './Page';
import Published from '../Published';
import Loader from '../Loader';
import { fetchIfNeeded as fetchIfNeededSite } from './ducks/sites';
import { syncScopedWidgets, unsyncScopedWidgets } from './ducks/widgets';
import { editorEmpty } from './ducks/editor';
import { historyEmpty } from './ducks/history';
import { loadAndWatchSpecs } from './ducks/specs';
// FixMe: Это не тут и не так должно быть
import '../styles/fonts';
import Error from '../Error';

const PublishedBox = styled.div`
	margin-top: 54px;
`;

type TProps = $ReadOnly<{|
	user: $ReadOnly<{
		_id: TId,
	}>,
|}>;

const Site = ({ user: { _id: userId } }: TProps) => {
	const dispatch = useDispatch();
	const projectId: ?TId = useProjectId();
	const pageId: ?TId = usePageId();
	const siteId: ?TId = useSiteId();
	const site = useSelector(state => (siteId ? getSite(state, siteId) : null));

	React.useEffect(() => {
		dispatch(editorEmpty());
		dispatch(historyEmpty());
		dispatch(fetchIfNeededSite(userId));
		if (projectId) {
			dispatch(syncScopedWidgets('project', projectId));
		}
		if (siteId) {
			dispatch(syncScopedWidgets('site', siteId));
		}
		if (pageId) {
			dispatch(syncScopedWidgets('page', pageId));
		}
		// TODO: Store as widget
		dispatch(loadAndWatchSpecs(userId, projectId || '', siteId || ''));
		return () => {
			dispatch(fetchIfNeededSite(null));
			if (projectId) {
				dispatch(unsyncScopedWidgets('project', projectId));
			}
			if (siteId) {
				dispatch(unsyncScopedWidgets('site', siteId));
			}
			if (pageId) {
				dispatch(unsyncScopedWidgets('page', pageId));
			}
		};
	}, [projectId, siteId, pageId, dispatch, userId]);

	if (!site) return <Loader />;

	return (
		<ThemeProvider theme={themes.dark}>
			<CursorProvider>
				<React.Suspense fallback={<Loader />}>
					<TopBar />
					<Switch>
						<Route
							exact
							path="/project/:projectId/site/:siteId/page/:pageId"
							component={Page}
						/>
						<Route path="/project/:projectId/site/:siteId/page/:pageId/preview">
							<PublishedBox>
								<Published />
							</PublishedBox>
						</Route>
						{/* eslint-disable-next-line max-len */}
						{/* Если человек промахнулся, то переадресовываем его на случайную страницу */}
						{_.size(site.children) ? (
							<Redirect
								to={`/project/:projectId/site/${site._id}/page/${_.sample(
									site.children || emptyObject,
								)}`}
							/>
						) : (
							<Route path="" component={Error} />
						)}
					</Switch>
				</React.Suspense>
			</CursorProvider>
		</ThemeProvider>
	);
};

export default React.memo<TProps>(Site);
