// @flow
import type {
	Dispatch,
	TWidgetOwnProps,
	TId,
	TAction,
	TPositionValue,
	TOffsetDevice,
	TWidgetChain,
	TWidgetDiff,
} from '@graphite/types';

import { repositionWidget, editWidget } from 'Editor/ducks/widgets';
import { startEdit, resetEdit } from 'Editor/ducks/editor';
import { insertImages, removeImage, reset } from 'Editor/ducks/imageLibrary';

const mapDispatchToPropsEdit = (
	dispatch: Dispatch<TAction>,
	ownProps: TWidgetOwnProps,
) => {
	const { instanceId } = ownProps;

	return {
		dispatch,
		startEdit: (id: TId, widgetChain: TWidgetChain) => {
			dispatch(startEdit(id, widgetChain, instanceId));
		},
		resetEdit: () => {
			dispatch(resetEdit());
		},
		editWidget: (_id: TId, instanceId: ?TId, originId: TId, diff: TWidgetDiff) => {
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		repositionWidget: (
			targetId: TId,
			originId: TId,
			containerId: ?TId,
			position: TPositionValue,
			offset: TOffsetDevice,
		) => {
			dispatch(repositionWidget(targetId, originId, containerId, position, offset));
		},
		insertImage: (files: FileList) => {
			dispatch(insertImages(files));
		},
		removeImage: (imageId: TId) => {
			dispatch(removeImage(imageId));
		},
		resetImage: () => {
			dispatch(reset());
		},
	};
};

export default mapDispatchToPropsEdit;
