// @flow
import _ from 'lodash/fp';

import reSelect from 'libs/re-select';
import type { TWidgetKind, TGetComponent } from '@graphite/types';

const loadable = async (func: string, kind: string) => {
	// eslint-disable-next-line prefer-template
	const { default: method } = await import('./' + func);
	return method(_.upperFirst(_.camelCase(kind)));
};

export const anyKind = reSelect<string, string, TGetComponent>(
	func => func,
	func => (kind: TWidgetKind) => loadable(func, kind),
)(func => `anyKind@${func}`);

export default loadable;
