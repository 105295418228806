// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Box, InputInplace } from '@graphite/uneon';
import type { TStringParam } from '@graphite/types';

type TProps = $ReadOnly<{|
	value: string,
	param: TStringParam,
	onChange?: ?(string) => void,
|}>;

const paramRightStyle = {
	flexGrow: 1,
	width: '50%',
	position: 'relative',
};

const inputSx = {
	margin: '0 -9px',
};

function ListItemStringParams({ value, param, onChange }: TProps) {
	const changeParam = React.useMemo(
		() =>
			// eslint-disable-next-line no-shadow
			_.debounce(500, value => (onChange && onChange(value)) || undefined),
		[onChange],
	);
	return (
		<Box sx={paramRightStyle}>
			<InputInplace
				value={value}
				max={param.info.maxLength || 0}
				placeholder={param.info.placeholder || ''}
				sx={inputSx}
				onChange={changeParam}
			/>
		</Box>
	);
}

ListItemStringParams.defaultProps = {
	onChange: null,
};

export default React.memo<TProps>(ListItemStringParams);
