// @flow

import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './styles';

type TProps = $ReadOnly<{|
	isActive: boolean,
	unmountOnExit?: boolean,
	children: React$Node,
|}>;

const ControlsTransition = ({ isActive, unmountOnExit = false, children }: TProps) => (
	// Я не понимаю как, но увеличение timeout с .2 до 1с позволило починить UPRO-529 и UPRO-523
	// если опустить таймаут то события дропа перестают вызываться, хотя они висят на документе
	// FixMe: вернул в зад, выпилив unmountOnExit
	<CSSTransition
		appear
		mountOnEnter
		unmountOnExit={unmountOnExit}
		in={isActive}
		timeout={3}
		classNames={styles}
	>
		{children}
	</CSSTransition>
);

export default React.memo<TProps>(ControlsTransition);
