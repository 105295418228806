// @flow

import React, { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ButtonDeprecated, PopupWhite, Box } from '@graphite/uneon';
import { AbsoluteContext } from '@graphite/abs-grid';
import { getWidgetPresets } from '@graphite/selectors';
import { useSiteId } from '@graphite/use-location';

import GridContext from 'Widget/libs/dnd/dndContext';
import type { TWidgetComposed } from '@graphite/types';

import WidgetPanel from './WidgetPanel';

type TProps = $ReadOnly<{||}>;

const buttonSx = {
	boxShadow: 'md',
};

const excludeList = ['page', 'block', 'col', 'icon'];

const PopupWhiteWrapped = styled(PopupWhite)`
	${({ isHidden }) =>
		isHidden
			? css`
					transition: left 0.1s ease-out 0.15s, opacity 0.15s ease-out 0s !important;
					opacity: 0 !important;
					left: -100% !important;
			  `
			: ''}
`;

function WidgetAdd() {
	const siteId = useSiteId();
	const widgetFilter = React.useCallback(
		(w: TWidgetComposed): boolean =>
			!excludeList.includes(w.kind) && w.scopeId === siteId,
		[siteId],
	);
	const widgetPresets = useSelector(state => getWidgetPresets(state, widgetFilter));
	const [isPanelShown, setIsPanelShown] = useState(false);

	const togglePanel = useCallback(() => setIsPanelShown(!isPanelShown), [isPanelShown]);

	const hidePanel = useCallback(() => setIsPanelShown(false), []);

	const panelRef = useRef();

	const { dragId: absDragId } = React.useContext(AbsoluteContext);
	const [{ dragId }] = React.useContext(GridContext);

	return (
		<>
			<Box>
				<ButtonDeprecated
					colors="success"
					icons="plus"
					size="sm"
					onClick={togglePanel}
					title="Add Content"
					shape="rounded"
					sx={buttonSx}
				/>
				<Box ref={panelRef} />
			</Box>
			<PopupWhiteWrapped
				anchorEl={panelRef}
				isOpen={isPanelShown}
				onClose={hidePanel}
				offsetTop={12}
				mutex="topleft"
				isFixed
				isHidden={absDragId || dragId}
			>
				{isPanelShown && <WidgetPanel widgetPresets={widgetPresets} />}
			</PopupWhiteWrapped>
		</>
	);
}

export default React.memo<TProps>(WidgetAdd);
