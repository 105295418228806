// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@graphite/uneon';

type TProps = $ReadOnly<{||}>;

const SpanStyled = styled.span`
	display: inline-block;
	animation: pulse 0.6s alternate infinite ease-in-out;
	margin: 0 0.35vw;
	opacity: 1;
	color: ${({ theme }) => theme.colors.spec.blue50};
	font-family: Epilogue, sans-serif;
	font-weight: 800;
	font-size: 4vw;

	&:nth-child(odd) {
		animation-delay: 0.4s;
	}

	@keyframes pulse {
		to {
			transform: scale(0.9);
			opacity: 0.7;
		}
	}
`;

const LoaderStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const GraphiteStyled = styled(SpanStyled)`
	color: ${({ theme }) => theme.colors.spec.lightblue10};
`;

const Loader = () => {
	return (
		<Flex
			minHeight="100vh"
			alignItems="center"
			justifyContent="center"
			backgroundColor="spec.darkblue90"
		>
			<LoaderStyled>
				<SpanStyled>{'{'}</SpanStyled>
				<GraphiteStyled>graphite</GraphiteStyled>
				<SpanStyled>{'}'}</SpanStyled>
			</LoaderStyled>
		</Flex>
	);
};

export default React.memo<TProps>(Loader);
