exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2WTXvUh___appear,._3nDotJd___enter{opacity:0;display:none}._2ijNlYB___appearActive,.ziIireB___enterActive{display:block;-webkit-transition:opacity .15s cubic-bezier(0,0,.2,1);transition:opacity .15s cubic-bezier(0,0,.2,1)}._2zZEwrC___exit{opacity:1;display:block}._1-qSCbI___exitActive{opacity:0;display:none;-webkit-transition:opacity .15s cubic-bezier(0,0,.2,1);transition:opacity .15s cubic-bezier(0,0,.2,1)}._2uPFCsf___exitDone{display:none!important;opacity:0!important}._1d3Zn-P___hidden{width:0;height:0;overflow:hidden;position:absolute}", "", {"version":3,"sources":["/builds/graphite/graphite/services/constructor/src/Editor/ControlsTransition/styles.scss"],"names":[],"mappings":"AAEA,qCAEC,UAAU,AACV,YAAa,CAMd,AAJC,gDACC,cAAc,AACd,uDAA8C,AAA9C,8CAA8C,CAC/C,AAGD,iBACC,UAAU,AACV,aAAc,CAOf,AALC,uBACC,UAAU,AACV,aAAa,AACb,uDAA8C,AAA9C,8CAA8C,CAC/C,AAGD,qBACC,uBAAwB,AACxB,mBAAqB,CACtB,AAEA,mBACC,QAAQ,AACR,SAAS,AACT,gBAAgB,AAChB,iBAAkB,CACnB","file":"styles.scss","sourcesContent":["@import 'vars';\n\n.appear,\n.enter {\n\topacity: 0;\n\tdisplay: none;\n\n\t&Active {\n\t\tdisplay: block;\n\t\ttransition: opacity $duration $timing-function;\n\t}\n}\n\n.exit {\n\topacity: 1;\n\tdisplay: block;\n\n\t&Active {\n\t\topacity: 0;\n\t\tdisplay: none;\n\t\ttransition: opacity $duration $timing-function;\n\t}\n}\n\n.exitDone {\n\tdisplay: none !important;\n\topacity: 0 !important;\n}\n\n.hidden {\n\twidth: 0;\n\theight: 0;\n\toverflow: hidden;\n\tposition: absolute;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"appear": "_2WTXvUh___appear",
	"enter": "_3nDotJd___enter",
	"appearActive": "_2ijNlYB___appearActive",
	"enterActive": "ziIireB___enterActive",
	"exit": "_2zZEwrC___exit",
	"exitActive": "_1-qSCbI___exitActive",
	"exitDone": "_2uPFCsf___exitDone",
	"hidden": "_1d3Zn-P___hidden"
};