// @flow
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Box } from '@graphite/uneon';
import type { TId } from '@graphite/types';
import { transitions } from '@graphite/constants';
import ResizeCols from 'Widget/libs/resize-cols';

const transition = css`
	transition: opacity ${transitions.columnControls.showDuration}
		${transitions.columnControls.showTiming} ${transitions.columnControls.showDelay};
`;

const Backlight = styled(Box)`
	position: absolute;
	pointer-events: none;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 2px solid ${({ theme }) => theme.colors.spec.blue10};
	opacity: ${({ isHightligth }) => (isHightligth ? 1 : 0)};

	${transition}
`;

type TProps = $ReadOnly<{|
	id: TId,
	controls: string,
|}>;

const Border = ({ id, controls }: TProps) => {
	const [{ siblingId }] = React.useContext(ResizeCols);

	const isHightligth = siblingId === id || controls === 'flip';

	return <Backlight isHightligth={isHightligth} />;
};

export default React.memo<TProps>(Border);
