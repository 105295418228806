// @flow
import _ from 'lodash/fp';
import type {
	TPublishedState,
	TSpecs,
	TId,
	TSpecsWidget,
	TSpecsColor,
	TSpecsGrid,
	TSpecsEffect,
	TSpecsGridBreakpoints,
	TSites,
} from '@graphite/types';
import {
	defaultWidgetspec,
	defaultColorspec,
	defaultGridspec,
	defaultEffectspec,
} from '@graphite/constants';

import reSelect from './libs/re-select';
import { getCurrentSite } from './router';

type TGetSpecs = TPublishedState => TSpecs;
export const getSpecs: TGetSpecs = reSelect(
	(state: TPublishedState): TSpecs => state.specs,
	(specs: TSpecs): TSpecs => specs,
)(() => 'specs@getSpecs');

type TGetSpecsById = (TPublishedState, { ids: $ReadOnlyArray<TId> }) => ?TSpecs;
export const getSpecByIds: TGetSpecsById = reSelect(
	(state: TPublishedState): TSpecs => state.specs,
	(state, { ids }: { ids: $ReadOnlyArray<TId> }) => ids,
	(specs, ids) => _.pickBy(spec => ids.includes(spec._id), specs),
)((state, { ids }) => `specs@getSpec-${ids.join('-')}`);

type TGetGridspec = TPublishedState => TSpecsGrid;
export const getGridspec: TGetGridspec = reSelect(
	(state: TPublishedState): TSpecs => state.specs,
	(state: TPublishedState): TSites => state.sites,
	getCurrentSite,
	(specs, sites, siteId): TSpecsGrid => {
		if (!siteId) {
			return defaultGridspec;
		}
		if (!sites[siteId]) {
			return defaultGridspec;
		}
		const { gridspecId } = sites[siteId];
		if (gridspecId && specs[gridspecId] && specs[gridspecId].kind === 'gridspec') {
			return specs[gridspecId];
		}
		return defaultGridspec;
	},
)(() => 'specs@getGridspec');

type TGetColorspec = TPublishedState => TSpecsColor;
export const getColorspec: TGetColorspec = reSelect(
	(state: TPublishedState): TSpecs => state.specs,
	(state: TPublishedState): TSites => state.sites,
	getCurrentSite,
	(specs, sites, siteId): TSpecsColor => {
		if (!siteId) {
			return defaultColorspec;
		}
		const { colorspecId } = sites[siteId];
		if (
			colorspecId &&
			specs[colorspecId] &&
			specs[colorspecId].kind === 'colorspec'
		) {
			return specs[colorspecId];
		}
		return defaultColorspec;
	},
)(() => 'specs@getColorspec');

type TGetWidgetspec = TPublishedState => TSpecsWidget;
export const getWidgetspec: TGetWidgetspec = reSelect(
	(state: TPublishedState): TSpecs => state.specs,
	(state: TPublishedState): TSites => state.sites,
	getCurrentSite,
	(specs, sites, siteId): TSpecsWidget => {
		if (!siteId) {
			return defaultWidgetspec;
		}
		const { widgetspecId } = sites[siteId];
		if (
			widgetspecId &&
			specs[widgetspecId] &&
			specs[widgetspecId].kind === 'widgetspec'
		) {
			return specs[widgetspecId];
		}
		return defaultWidgetspec;
	},
)(() => 'specs@getWidgetspec');

type TGetEffectspec = TPublishedState => TSpecsEffect;
export const getEffectspec: TGetEffectspec = reSelect(
	(state: TPublishedState): TSpecs => state.specs,
	(state: TPublishedState): TSites => state.sites,
	getCurrentSite,
	(specs, sites, siteId): TSpecsEffect => {
		if (!siteId) {
			return defaultEffectspec;
		}
		const { effectspecId } = sites[siteId];
		if (
			effectspecId &&
			specs[effectspecId] &&
			specs[effectspecId].kind === 'effectspec'
		) {
			return specs[effectspecId];
		}
		return defaultEffectspec;
	},
)(() => 'specs@getEffectspec');

export const getGridBreakpoints = reSelect<
	TPublishedState,
	?TSpecsGrid,
	?TSpecsGridBreakpoints,
>(
	getGridspec,
	gridSpec => gridSpec?.breakpoints ?? null,
)(() => 'specs@getBreakpoints');

export default {};
