// @flow
import { connect } from 'react-redux';
import { withAbsolute } from '@graphite/abs-grid';
import type { TWidgetOwnProps } from '@graphite/types';

import getMapStateToProps from '../../libs/primitive/getMapStateToProps';
import Image from './Image';
import type { TConnectProps } from './constants/types';

export default connect<TConnectProps, TWidgetOwnProps, _, _, _, _>(
	getMapStateToProps<TConnectProps>(),
)(withAbsolute<TConnectProps>()(Image));
