// @flow

import React, { memo, useCallback } from 'react';
import emptyFunction from 'empty/function';
import { Box } from 'rebass';
import { zIndices } from '@graphite/constants';

import Portal from '../Portal';
import PopupWhite from '../PopupWhite';

type TProps = $ReadOnly<{|
	children: React$Node,
	width?: number | 'auto',
	isOpen: boolean,
	offsetTop?: number,
	offsetLeft?: number,
	anchorEl: {| current: ?React$ElementRef<any> |},
	onClose?: () => void,
|}>;

const popupSx = {
	paddingTop: '12px',
	paddingBottom: '12px',
};

const overlayOpenStyle = {
	position: 'fixed',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	zIndex: zIndices.popupSelectOverlay,
};

const overlayClosedStyle = {
	display: 'none',
};

function PopupMenu({
	children,
	width = 'auto',
	onClose = emptyFunction,
	offsetTop = 12,
	offsetLeft = 0,
	isOpen,
	anchorEl,
}: TProps) {
	const closeBound = useCallback(() => onClose(), [onClose]);
	return (
		<>
			<Portal>
				<Box
					sx={isOpen ? overlayOpenStyle : overlayClosedStyle}
					onClick={closeBound}
				/>
			</Portal>
			<PopupWhite
				isOpen={isOpen}
				width={width}
				anchorEl={anchorEl}
				zIndex={zIndices.popupInner}
				offsetLeft={offsetLeft}
				offsetTop={offsetTop}
				noDrag
				sx={popupSx}
			>
				{children}
			</PopupWhite>
		</>
	);
}

export default memo<TProps>(PopupMenu);
