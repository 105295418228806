// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAbsolute, withAbsoluteDrag } from '@graphite/abs-grid';
import type {
	TWidgetOwnProps,
	Dispatch,
	TAction,
	TId,
	TWidgetChain,
	TWidgetDiff,
} from '@graphite/types';

import withSymbiote from 'Editor/libs/with-symbiote';
import withControls from 'Editor/libs/with-controls';
import checkActiveWidget from 'Editor/libs/check-active-widget';
import getMapStateToPropsEdit from 'Widget/libs/primitive/getMapStateToPropsEdit';
import withDrag from 'Widget/libs/dnd/drag';
import withWidgetControls, { withWidgetResizer } from 'Widget/libs/with-widget-controls';
import withWidgetEdit from 'Widget/libs/with-widget-edit';
import withRef from 'Widget/libs/with-ref';
import { repositionWidget, editWidget } from 'Editor/ducks/widgets';
import { startEdit, resetEdit } from 'Editor/ducks/editor';
import { insertImages, removeImage, reset } from 'Editor/ducks/imageLibrary';

import withEditable from './libs/with-editable';
import withEditorState from './libs/with-editor-state';
import Controls from './Controls';
import type {
	TConnectPropsEdit,
	TConnectPropsDrag,
	TMinimalEditableProps,
} from './constants/types';

const mapDispatchToProps = (dispatch: Dispatch<TAction>, ownProps: TWidgetOwnProps) => {
	const { instanceId } = ownProps;

	return {
		// FixMe: выпилить dispatch
		dispatch,
		startEdit: (id: TId, widgetChain: TWidgetChain) => {
			dispatch(startEdit(id, widgetChain, instanceId));
		},
		resetEdit: () => {
			dispatch(resetEdit());
		},
		editWidget: (_id: TId, instanceId: ?TId, originId: TId, diff: TWidgetDiff) => {
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		repositionWidget: (
			targetId,
			originId,
			containerId,
			position,
			{ height, ...offset },
		) => {
			dispatch(repositionWidget(targetId, originId, containerId, position, offset));
		},
		insertImage: (files: FileList) => {
			dispatch(insertImages(files));
		},
		resetImage: () => {
			dispatch(reset());
		},
		removeImage: (imageId: TId) => {
			dispatch(removeImage(imageId));
		},
	};
};

const WidgetComponentEdit = compose(
	// берём данные из стейта
	connect<TConnectPropsEdit, TWidgetOwnProps, _, _, _, _>(
		getMapStateToPropsEdit<TConnectPropsEdit>(),
		mapDispatchToProps,
	),
	// добавляет реф, который прокидывается через все ХОКи прямо вглубь
	Component => withRef<TConnectPropsEdit>(Component),
	withDrag,
	withWidgetResizer,
	withSymbiote(
		compose(
			withWidgetEdit,
			withWidgetControls({ margin: true, height: false, width: true }),
		),
	),
	// ...
	withSymbiote(
		// ...
		withAbsoluteDrag,
		{
			level: 'abs-drag-place',
		},
	),
	withAbsolute<TConnectPropsDrag>(),
	withEditorState,
	withSymbiote(withControls(checkActiveWidget, Controls)),
)(withEditable<TMinimalEditableProps>());

export default WidgetComponentEdit;
