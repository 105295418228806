// @flow

import React from 'react';

import getDisplayName from '@graphite/get-display-name';
import type { TWidget, TPositionValue, TSpecsGrid } from '@graphite/types';

import Absolute from './Absolute';

type TMinimal = $ReadOnly<{
	position: TPositionValue,
	gridspec: TSpecsGrid,
	data: TWidget,
}>;

// Внимание! Не используйте этот HOC без явного указания типа дженерика.
export default function<T: TMinimal>(): (
	Widget: React$ComponentType<T>,
) => React$ComponentType<$ReadOnly<{| ...$Exact<T> |}>> {
	return function withabsolute(
		Widget: React$ComponentType<T>,
	): React$ComponentType<$ReadOnly<{| ...$Exact<T> |}>> {
		const WidgetHOC = (props: T, ref) => {
			const { position, data, gridspec } = props;

			if (!position || position === 'grid') {
				// eslint-disable-next-line react/jsx-props-no-spreading
				return <Widget {...props} ref={ref} />;
			}

			return (
				<Absolute data={data} gridspec={gridspec}>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<Widget {...props} ref={ref} />
				</Absolute>
			);
		};

		WidgetHOC.displayName = `withAbsolute(${getDisplayName(Widget)})`;

		return React.memo(React.forwardRef(WidgetHOC));
	};
}
