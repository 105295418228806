// @flow

import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Box, Icon, Text } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	insertSiteHandler: () => void,
	isEmptySite: boolean,
|}>;

const wrapSx = {
	paddingBottom: '30px',
};

const IconPlus = styled(Icon)``; // что бы класс повесить

const Insert = styled(Box)`
	position: absolute;
	height: 54px;
	width: 54px;
	top: -27px;
	left: -27px;
	background-color: ${({ theme }) => theme.colors.bg.primary};
	border-radius: ${({ theme }) => theme.radii.rounded.all};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Label = styled(Text)`
	position: absolute;
	top: 22px;
	left: 31px;
`;

const Wrap = styled(Box)`
	position: relative;
	width: 100%;
	min-height: 180px;
	height: ${({ isEmptySite }) => (isEmptySite ? '100%' : 'calc(100% - 39px)')};
	border: 2px solid;
	cursor: pointer;
	transition: border-color 0.15s ease-out;
	border-color: ${({ theme, isEmptySite }) =>
		isEmptySite ? theme.colors.bg.secondary : 'transparent'};

	${IconPlus} {
		svg {
			fill: ${({ theme }) => theme.colors.text.accent};
		}
	}

	${Label} {
		opacity: ${({ isEmptySite }) => (isEmptySite ? 1 : 0)};
		transition: opacity 0.15s ease-out;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0.25;
		transition: background-color 0.15s ease-out;
	}

	&:hover {
		border-color: ${({ theme }) => theme.colors.bg.accentplus};

		&:before {
			background-color: ${({ theme }) => theme.colors.bg.primaryalt};
		}

		svg {
			fill: ${({ theme }) => theme.colors.text.accentplus};
		}

		${Label} {
			opacity: 1;
			color: ${({ theme }) => theme.colors.text.accentplus};
		}
	}
`;

const EmptySite = ({ insertSiteHandler, isEmptySite }: TProps) => {
	const { t } = useTranslation();

	return (
		<Box variant="grid.column" sx={wrapSx}>
			<Wrap isEmptySite={isEmptySite} onClick={insertSiteHandler}>
				<Insert variant="primaryaltflat.flat.lg">
					<IconPlus name="plus" iconSize={30} />
				</Insert>
				<Label variant="headlinemd" color="text.accent">
					{t('dashboard.project.new site')}
				</Label>
			</Wrap>
		</Box>
	);
};

export default EmptySite;
