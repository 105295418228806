// @flow

import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDeprecated } from '@graphite/uneon';
import emptyFunction from 'empty/function';

import type { TTopPanel } from './types';

type TProps = $ReadOnly<{|
	active?: boolean,
	panel: TTopPanel,
	onClick?: (?string) => void,
|}>;

const buttonStyle = {
	marginRight: '30px',
	':last-of-type': {
		marginRight: 0,
	},
};

function ButtonPanel({ active = false, panel, onClick = emptyFunction }: TProps) {
	const onClickBound = useCallback(() => onClick(panel.value), [panel, onClick]);
	const { t } = useTranslation();

	return (
		<ButtonDeprecated
			label={t(panel.title)}
			variant="flat"
			size="sm"
			colors={active ? 'accentflat' : 'primaryflat'}
			onClick={onClickBound}
			sx={buttonStyle}
		/>
	);
}

ButtonPanel.defaultProps = {
	active: false,
	onClick: emptyFunction,
};

export default memo<TProps>(ButtonPanel);
