// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Spec as SelectSpec } from '@graphite/selects';
import { ButtonGroup } from '@graphite/uneon';
import { useTranslation } from 'react-i18next';

import { updateSpecs } from 'Editor/ducks/specs';
import { updateSite } from 'Editor/ducks/sites';
import { genId } from 'libs/firebase';
import { useDispatch } from '@graphite/use-redux';
import { useSiteId } from '@graphite/use-location';
import { getColorspec, getSpecs, getGridspec } from '@graphite/selectors';

import Palette from './Palette';
import Themes from './Themes';
import NewPalette from './NewPalette';

type TProps = $ReadOnly<{||}>;

const groupStyle = {
	padding: '18px 0',
};

const tabList = [
	{ label: 'Palette', name: 'palette' },
	{ label: 'Themes', name: 'themes' },
];

function ColorSettings() {
	const { t } = useTranslation();
	const siteId = useSiteId();
	const dispatch = useDispatch();
	const specs = useSelector(getSpecs);
	const colorspec = useSelector(getColorspec);
	const gridspec = useSelector(getGridspec);
	const [activeTab, setActiveTab] = React.useState('palette');
	const [isCreating, setIsCreating] = React.useState(false);

	const tab = React.useMemo(
		() => tabList.find(t => t.name === activeTab) || tabList[0],
		[activeTab],
	);

	const clickBound = React.useCallback((e, name) => {
		if (typeof name === 'string') {
			setActiveTab(name);
		}
	}, []);

	const startInsertion = React.useCallback(() => setIsCreating(true), []);

	const finishInsertion = React.useCallback(() => setIsCreating(false), []);

	const updateSiteHandler = React.useCallback(
		(siteId, site) => {
			dispatch(updateSite(siteId, site));
		},
		[dispatch],
	);

	const updateSpecsHandler = React.useCallback(
		specs => {
			dispatch(updateSpecs(specs));
		},
		[dispatch],
	);

	if (!siteId) return null;

	return (
		<>
			{(isCreating && <NewPalette onExit={finishInsertion} />) || (
				<>
					<SelectSpec
						siteId={siteId}
						spec={colorspec}
						specs={specs}
						onInsert={startInsertion}
						t={t}
						genCustomId={genId}
						updateSite={updateSiteHandler}
						updateSpecs={updateSpecsHandler}
					/>
					<ButtonGroup
						behavior="radio"
						active={tab.name}
						buttons={tabList}
						variant="normal"
						shape="rounded"
						size="sm"
						colors="accentghost"
						activeColors="accent"
						onClick={clickBound}
						sx={groupStyle}
					/>
					{(tab.name === 'palette' && (
						<Palette colorspec={colorspec} gridspec={gridspec} />
					)) ||
						(tab.name === 'themes' && (
							<Themes colorspec={colorspec} gridspec={gridspec} />
						))}
				</>
			)}
		</>
	);
}

export default React.memo<TProps>(ColorSettings);
