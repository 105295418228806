// @flow
import { takeEvery, put, fork, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import _ from 'lodash/fp';
import type { TId, TAction, TSpecsWidget, TWidget, TProject } from '@graphite/types';
import {
	colorspecUneon,
	colorspecBootstrap,
	presetWidgets,
	listForFontsLib,
} from '@graphite/constants';

import logger from 'libs/logger';
import { genId } from 'libs/firebase';
import { getProject } from '@graphite/selectors';

import { updateProject } from './projects';
import { insertSite as insertSiteOriginal, updateSite } from './sites';
import { saveWidgets } from './widgets';
import { saveSpecs } from './specs';

const INSERT_SITE = 'PROJECTS_SITES/INSERT_SITE';
const REMOVE_SITE = 'PROJECTS_SITES/REMOVE_SITE';

export const insertSite = (userId: string, projectId: string): TAction => ({
	type: INSERT_SITE,
	payload: { userId, projectId },
});

export const removeSite = (projectId: string, siteId: string): TAction => ({
	type: REMOVE_SITE,
	payload: { projectId, siteId },
});

export function* insertSiteSaga(): Saga<void> {
	yield takeEvery(INSERT_SITE, function*({
		payload: { userId, projectId },
	}: {
		payload: { userId: string, projectId: string },
	}): Saga<void> {
		try {
			const project: TProject = yield select(getProject, { id: projectId });

			const pageId = genId('pages');
			const siteId = genId('sites');
			const colorspecBootstrapId = genId('specs');
			const colorspecUneonId = genId('specs');
			const widgetspecId = genId('specs');
			const effectspecId = genId('specs');
			const gridspecId = genId('specs');

			// const presets = _.mapValues();
			const filledPresetWidgets = _.mapValues(
				(widget: TWidget): TWidget => ({
					...widget,
					userId,
					_id: genId('widgets'),
					scope: 'site',
					scopeId: siteId,
				}),
				presetWidgets,
			);

			// Создаём страницу
			yield put(
				saveWidgets({
					[pageId]: {
						userId,
						_id: pageId,
						kind: 'page',
						name: 'Home',
						display: 'normal',
						protoId: null,
						removedAt: null,
						scope: 'site',
						scopeId: siteId,
						children: {},
						updatedAt: null,
					},
					..._.mapKeys(
						(key: string): TId => filledPresetWidgets[key]._id,
						filledPresetWidgets,
					),
				}),
			);

			// Создаём сайт
			yield put(
				insertSiteOriginal(userId, {
					_id: siteId,
					children: { [pageId]: pageId },
					colorspecId: colorspecBootstrapId,
					widgetspecId,
					effectspecId,
					gridspecId,
				}),
			);

			// Создаём спеки
			yield put(
				saveSpecs({
					[gridspecId]: {
						userId,
						_id: gridspecId,
						kind: 'gridspec',
						name: 'Bootstrap',

						display: 'normal',
						protoId: null,
						removedAt: null,
						updatedAt: null,
						scope: 'site',
						scopeId: siteId,
						unit: 4,

						breakpoints: {
							phone_p: {
								active: true,
								breakpoint: 576,
								columns: 6,
								gutter: 30,
								gutterUnit: 'px',
								container: 100,
								containerUnit: '%',
								padding: 0,
								paddingUnit: 'px',
							},
							phone_l: {
								active: false,
								breakpoint: 768,
								columns: 6,
								gutter: 30,
								gutterUnit: 'px',
								container: 570,
								containerUnit: 'px',
								padding: 0,
								paddingUnit: 'px',
							},
							tablet_p: {
								active: true,
								breakpoint: 992,
								columns: 12,
								gutter: 30,
								gutterUnit: 'px',
								container: 750,
								containerUnit: 'px',
								padding: 0,
								paddingUnit: 'px',
							},
							tablet_l: {
								active: false,
								breakpoint: 1200,
								columns: 12,
								gutter: 30,
								gutterUnit: 'px',
								container: 750,
								containerUnit: 'px',
								padding: 0,
								paddingUnit: 'px',
							},
							desktop: {
								active: true,
								breakpoint: 1200,
								columns: 12,
								gutter: 30,
								gutterUnit: 'px',
								container: 990,
								containerUnit: 'px',
								padding: 0,
								paddingUnit: 'px',
							},
							desktop_hd: {
								active: false,
								breakpoint: 1440,
								columns: 12,
								gutter: 30,
								gutterUnit: 'px',
								container: 1140,
								containerUnit: 'px',
								padding: 0,
								paddingUnit: 'px',
							},
						},
					},

					[colorspecBootstrapId]: {
						userId,
						_id: colorspecBootstrapId,
						kind: 'colorspec',
						name: 'Bootstrap',
						display: 'normal',
						protoId: null,
						removedAt: null,
						updatedAt: null,
						scope: 'site',
						scopeId: siteId,

						activeTheme: 'light',

						generator: {
							isEnabled: false,
							baseColor: null,
						},

						...colorspecBootstrap,
					},
					[colorspecUneonId]: {
						userId,
						_id: colorspecUneonId,
						kind: 'colorspec',
						name: 'Uneon',
						display: 'normal',
						protoId: null,
						removedAt: null,
						updatedAt: null,
						scope: 'site',
						scopeId: siteId,

						activeTheme: 'light',

						generator: {
							isEnabled: false,
							baseColor: null,
						},

						...colorspecUneon,
					},
					[widgetspecId]: ({
						userId,
						_id: widgetspecId,
						kind: 'widgetspec',
						name: 'Bootstrap',

						display: 'normal',
						protoId: null,
						removedAt: null,
						updatedAt: null,
						scope: 'site',
						scopeId: siteId,

						block: [],

						col: [],

						text: [
							{
								_id: 'header1',
								target: 'text',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								section: 'header',
								name: 'Header 1',
								breakpoints: {
									desktop: {
										family: listForFontsLib[0].family,
										variants: listForFontsLib[0].variants,
										weight: '500',
										fontSize: 40,
										textHeight: 58,
										textHeightUnit: 'px',
										spacing: 0,
										textMargin: 0,
										textMarginUnit: 'px',
										lettercase: 'none',
										normal: {
											text: {
												color: 'text.primary',
												shadow: null,
											},
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
										},
									},
								},
							},
							{
								_id: 'header2',
								target: 'text',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								section: 'header',
								name: 'Header 2',
								breakpoints: {
									desktop: {
										family: listForFontsLib[0].family,
										variants: listForFontsLib[0].variants,
										weight: '500',
										fontSize: 32,
										textHeight: 38,
										textHeightUnit: 'px',
										spacing: 0,
										textMargin: 0,
										textMarginUnit: 'px',
										lettercase: 'none',
										normal: {
											text: {
												color: 'text.primary',
												shadow: null,
											},
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
										},
									},
								},
							},
							{
								_id: 'header3',
								target: 'text',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								section: 'header',
								name: 'Header 3',
								breakpoints: {
									desktop: {
										family: listForFontsLib[0].family,
										variants: listForFontsLib[0].variants,
										weight: '500',
										fontSize: 28,
										textHeight: 33,
										textHeightUnit: 'px',
										spacing: 0,
										textMargin: 0,
										textMarginUnit: 'px',
										lettercase: 'none',
										normal: {
											text: {
												color: 'text.primary',
												shadow: null,
											},
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
										},
									},
								},
							},
							{
								_id: 'header4',
								target: 'text',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								section: 'header',
								name: 'Header 4',
								breakpoints: {
									desktop: {
										family: listForFontsLib[0].family,
										variants: listForFontsLib[0].variants,
										weight: '500',
										fontSize: 24,
										textHeight: 29,
										textHeightUnit: 'px',
										spacing: 0,
										textMargin: 0,
										textMarginUnit: 'px',
										lettercase: 'none',
										normal: {
											text: {
												color: 'text.primary',
												shadow: null,
											},
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
										},
									},
								},
							},
							{
								_id: 'header5',
								target: 'text',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								section: 'header',
								name: 'Header 5',
								breakpoints: {
									desktop: {
										family: listForFontsLib[0].family,
										variants: listForFontsLib[0].variants,
										weight: '500',
										fontSize: 20,
										textHeight: 24,
										textHeightUnit: 'px',
										spacing: 0,
										textMargin: 0,
										textMarginUnit: 'px',
										lettercase: 'none',
										normal: {
											text: {
												color: 'text.primary',
												shadow: null,
											},
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
										},
									},
								},
							},
							{
								_id: 'header6',
								target: 'text',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								section: 'header',
								name: 'Header 6',
								breakpoints: {
									desktop: {
										family: listForFontsLib[0].family,
										variants: listForFontsLib[0].variants,
										weight: '500',
										fontSize: 16,
										textHeight: 19,
										textHeightUnit: 'px',
										spacing: 0,
										textMargin: 0,
										textMarginUnit: 'px',
										lettercase: 'none',
										normal: {
											text: {
												color: 'text.primary',
												shadow: null,
											},
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
										},
									},
								},
							},
							{
								_id: 'body-text',
								target: 'text',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								section: 'body',
								name: 'Text',
								breakpoints: {
									desktop: {
										family: listForFontsLib[0].family,
										variants: listForFontsLib[0].variants,
										weight: '400',
										fontSize: 18,
										textHeight: 27,
										textHeightUnit: 'px',
										spacing: 0,
										textMargin: 0,
										textMarginUnit: 'px',
										lettercase: 'none',
										normal: {
											text: {
												color: 'text.primary',
												shadow: null,
											},
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
										},
									},
								},
							},
							{
								_id: 'body-lead',
								target: 'text',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								section: 'body',
								name: 'Lead',
								breakpoints: {
									desktop: {
										family: listForFontsLib[0].family,
										variants: listForFontsLib[0].variants,
										weight: '300',
										fontSize: 20,
										textHeight: 30,
										textHeightUnit: 'px',
										spacing: 0,
										textMargin: 0,
										textMarginUnit: 'px',
										lettercase: 'none',
										normal: {
											text: {
												color: 'text.primary',
												shadow: null,
											},
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
										},
									},
								},
							},
							{
								_id: 'body-button',
								target: 'text',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								section: 'body',
								name: 'Button',
								breakpoints: {
									desktop: {
										family: listForFontsLib[0].family,
										variants: listForFontsLib[0].variants,
										weight: '500',
										fontSize: 16,
										textHeight: 24,
										textHeightUnit: 'px',
										spacing: 0,
										textMargin: 0,
										textMarginUnit: 'px',
										lettercase: 'none',
										normal: {
											text: {
												color: 'text.primary',
												shadow: null,
											},
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
										},
									},
								},
							},
						],

						image: [],

						icon: [
							{
								_id: 'icon-flat',
								target: 'icon',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								name: 'Flat',
								breakpoints: {
									desktop: {
										border: null,
										padding: 0,
										paddingUnit: 'px',
										radius: 'null',
										iconSize: 24,
										iconSizeUnit: 'px',
										normal: {
											container: {
												background: null,
												border: null,
												overlay: null,
												shadow: null,
											},
											text: {
												color: 'text.primary',
												shadow: null,
											},
										},
									},
								},
							},
						],

						button: [
							{
								_id: 'button-normal',
								target: 'button',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								name: 'Normal',
								breakpoints: {
									desktop: {
										height: 42,
										heightUnit: 'px',
										padding: 18,
										paddingUnit: 'px',
										radius: 'small',
										textDesign: 'body-button',
										textMargin: 0,
										textMarginUnit: 'px',
										btnIconSize: 16,
										btnIconSizeUnit: 'px',
										iconMargin: 6,
										iconMarginUnit: 'px',
										normal: {
											container: {
												border: 'bg.accentplus',
												background: [
													{
														value: 'bg.accent',
														kind: 'color',
													},
												],
												shadow: null,
												overlay: null,
											},
											text: {
												color: 'text.oncolor',
												shadow: null,
											},
										},
										border: 'thin',
										hover: {
											container: {
												border: 'bg.accentminus',
												background: [
													{
														value: 'bg.accentplus',
														kind: 'color',
													},
												],
												shadow: null,
												overlay: null,
											},
											text: {
												color: null,
												shadow: null,
											},
										},
									},
								},
							},
							{
								_id: 'button-rounded',
								target: 'button',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								name: 'Rounded',
								breakpoints: {
									desktop: {
										height: 42,
										heightUnit: 'px',
										padding: 24,
										paddingUnit: 'px',
										radius: 'rounded',
										textDesign: 'body-button',
										textMargin: 0,
										textMarginUnit: 'px',
										btnIconSize: 16,
										btnIconSizeUnit: 'px',
										iconMargin: 6,
										iconMarginUnit: 'px',
										normal: {
											container: {
												border: 'bg.accentplus',
												background: [
													{
														value: 'bg.accent',
														kind: 'color',
													},
												],
												shadow: null,
												overlay: null,
											},
											text: {
												color: 'text.oncolor',
												shadow: null,
											},
										},
										border: 'thin',
										hover: {
											container: {
												border: 'bg.accentminus',
												background: [
													{
														value: 'bg.accentplus',
														kind: 'color',
													},
												],
												shadow: null,
												overlay: null,
											},
											text: {
												color: null,
												shadow: null,
											},
										},
									},
								},
							},
							{
								_id: 'button-outlined',
								target: 'button',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								name: 'Outlined',
								breakpoints: {
									desktop: {
										height: 42,
										heightUnit: 'px',
										padding: 18,
										paddingUnit: 'px',
										radius: 'small',
										textDesign: 'body-button',
										textMargin: 0,
										textMarginUnit: 'px',
										btnIconSize: 16,
										btnIconSizeUnit: 'px',
										iconMargin: 6,
										iconMarginUnit: 'px',
										normal: {
											container: {
												border: 'bg.accent',
												background: null,
												shadow: null,
												overlay: null,
											},
											text: {
												color: 'text.accent',
												shadow: null,
											},
										},
										border: 'thick',
										hover: {
											container: {
												border: 'bg.accentminus',
												background: [
													{
														value: 'bg.accentplus',
														kind: 'color',
													},
												],
												shadow: null,
												overlay: null,
											},
											text: {
												color: 'text.oncolor',
												shadow: null,
											},
										},
									},
								},
							},
							{
								_id: 'button-flat',
								target: 'button',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								updatedAt: null,
								scope: 'site',
								scopeId: siteId,
								name: 'Flat',
								breakpoints: {
									desktop: {
										height: 42,
										heightUnit: 'px',
										padding: 0,
										paddingUnit: 'px',
										radius: 'small',
										textDesign: 'body-button',
										textMargin: 0,
										textMarginUnit: 'px',
										btnIconSize: 16,
										btnIconSizeUnit: 'px',
										iconMargin: 6,
										iconMarginUnit: 'px',
										normal: {
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
											text: {
												color: 'text.accent',
												shadow: null,
											},
										},
										border: 'thin',
										hover: {
											container: {
												border: null,
												background: null,
												shadow: null,
												overlay: null,
											},
											text: {
												color: 'text.accentplus',
												shadow: null,
											},
										},
									},
								},
							},
						],

						stack: [
							{
								_id: 'stack-background',
								target: 'stack',
								kind: 'design',
								userId,
								display: 'normal',
								protoId: null,
								removedAt: null,
								scope: 'site',
								scopeId: siteId,
								name: 'Background',
								breakpoints: {
									desktop: {
										normal: {
											container: {
												border: null,
												background: [
													{
														value: 'bg.primaryalt',
														kind: 'color',
													},
												],
												shadow: null,
												overlay: null,
											},
											text: {
												color: null,
												shadow: null,
											},
										},
									},
								},
							},
						],
					}: TSpecsWidget),
					[effectspecId]: {
						userId,
						_id: effectspecId,
						kind: 'effectspec',
						name: 'Bootstrap',
						display: 'normal',
						protoId: null,
						removedAt: null,
						updatedAt: null,
						scope: 'site',
						scopeId: siteId,

						radii: [
							{
								id: 'small',
								name: 'Small',
								size: 6,
								sides: null,
							},
							{
								id: 'medium',
								name: 'Medium',
								size: 8,
								sides: null,
							},
							{
								id: 'large',
								name: 'Large',
								size: 10,
								sides: null,
							},
							{
								id: 'rounded',
								name: 'Rounded',
								size: 999,
								sides: null,
							},
						],

						borders: [
							{
								id: 'thin',
								name: 'Thin',
								kind: 'solid',
								size: 1,
								sides: null,
							},
							{
								id: 'thick',
								name: 'Thick',
								kind: 'solid',
								size: 2,
								sides: null,
							},
							{
								id: 'underlined',
								name: 'Underlined',
								kind: 'solid',
								size: 2,
								sides: {
									n: 0,
									e: 0,
									w: 0,
								},
							},
						],

						shadows: [
							{
								id: 'small',
								name: 'Small',
								kind: 'drop',
								x: 0,
								y: 5,
								blur: 10,
								spread: 0,
								color: {
									entryId: null,
									shadeId: null,
									snapshot: '#c74cff33',
								},
							},
							{
								id: 'medium',
								name: 'Medium',
								kind: 'drop',
								x: 0,
								y: 5,
								blur: 10,
								spread: 0,
								color: {
									entryId: null,
									shadeId: null,
									snapshot: '#0000001a',
								},
							},
							{
								id: 'large',
								name: 'Large',
								kind: 'drop',
								x: 0,
								y: 10,
								blur: 20,
								spread: 0,
								color: {
									entryId: null,
									shadeId: null,
									snapshot: '#0000001a',
								},
							},
						],

						transitions: [
							{
								id: 'hover',
								name: 'Hover',
								easing: 'ease',
								time: 200,
								delay: 0,
								curve: null,
							},
							{
								id: 'closing',
								name: 'Closing',
								easing: 'ease',
								time: 200,
								delay: 0,
								curve: null,
							},
							{
								id: 'closing-big-objects',
								name: 'Closing Big Objects',
								easing: 'ease',
								time: 200,
								delay: 0,
								curve: null,
							},
						],
					},
				}),
			);

			// Меняем проект
			yield put(
				updateProject(projectId, {
					children: _.set(siteId, siteId, project.children),
				}),
			);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* removeSiteSaga(): Saga<void> {
	yield takeEvery(REMOVE_SITE, function*({
		payload: { projectId, siteId },
	}: {
		payload: { projectId: string, siteId: string },
	}): Saga<void> {
		try {
			const project: TProject = yield select(getProject, { id: projectId });

			yield put(
				updateProject(projectId, {
					children: _.pickBy((id: TId): boolean => id !== siteId, {
						...(project.children || null),
					}),
				}),
			);

			yield put(
				updateSite(siteId, {
					removedAt: new Date().toISOString(),
				}),
			);
		} catch (e) {
			logger.error(e);
		}
	});
}

export function* saga(): Saga<void> {
	yield fork(insertSiteSaga);
	yield fork(removeSiteSaga);
}
