// @flow
import React, { memo, useMemo, useCallback } from 'react';
import { Box, Text } from 'rebass';
import type {
	TSx,
	TStyle,
	TButtonGroupEmbed,
	TButtonGroupOnClick,
} from '@graphite/types';

import ListItem from '../ListItem';

type TSectionSize = 'xsm' | 'sm' | 'md' | 'lg';

type TProps = $ReadOnly<{|
	label?: ?string,
	color?: ?string,
	children: React$Node,
	buttonGroup?: ?TButtonGroupEmbed,
	size?: ?TSectionSize,
	isSeparated?: boolean,
	sx?: ?TSx,
	style?: ?TStyle,
	onClick?: TButtonGroupOnClick,
|}>;

const heightMap = {
	xsm: {
		height: '42px',
	},
	sm: {
		height: '51px',
	},
	md: {
		height: 'auto',
		paddingTop: '24px',
		paddingBottom: '15px',
	},
	lg: {
		height: 'auto',
		paddingTop: '24px',
		paddingBottom: '18px',
	},
};

const paddingMap = {
	xsm: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	sm: {
		paddingTop: '0px',
		paddingBottom: '12px',
	},
	md: {
		paddingTop: '0px',
		paddingBottom: '12px',
	},
	lg: {
		paddingTop: '0px',
		paddingBottom: '12px',
	},
};

const textVariantMap = {
	xsm: 'bodysm',
	sm: 'headlinesm',
	md: 'headlinemd',
	lg: 'headlinelg',
};

const separatorOuterSx = {
	position: 'relative',
	height: '8px',
};

const separatorSx = {
	position: 'absolute',
	left: '-24px',
	right: '-24px',
	top: '4px',
	height: '1px',
	backgroundColor: 'bg.primaryalt',
};

const Section = ({
	children,
	size = 'md',
	color = 'text.primaryalt',
	label = null,
	isSeparated = false,
	buttonGroup = null,
	sx = null,
	style = null,
	onClick = null,
}: TProps) => {
	const containerThemedStyle = useMemo(
		() => ({
			...paddingMap[size || 'md'],
			...sx,
		}),
		[size, sx],
	);
	const clickBound = useCallback(
		(e, itemName, name) => {
			if (typeof name === 'string') {
				onClick(e, name);
			}
		},
		[onClick],
	);
	return (
		<Box sx={containerThemedStyle} style={style}>
			<ListItem
				sx={heightMap[size || 'md']}
				buttonGroup={buttonGroup}
				onClick={clickBound}
				isExplicit
			>
				{label && (
					<Text variant={textVariantMap[size || 'md']} color={color}>
						{label}
					</Text>
				)}
			</ListItem>
			{children}
			{isSeparated && (
				<Box sx={separatorOuterSx}>
					<Box sx={separatorSx} />
				</Box>
			)}
		</Box>
	);
};

export default memo<TProps>(Section);
