// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import emptyFunction from 'empty/function';

import { Flex, Box, Button, Icon, Text, Image } from '@graphite/uneon';
import type { TSx, TSite, TProject } from '@graphite/types';

import PreviewImage from './images/site-preview.inline.svg';

type TProps = $ReadOnly<{|
	project: TProject,
	site: TSite,
	onRemove: string => void,
	sx?: TSx,
|}>;

// overlay with action buttons

const overlayWrapperSx = {
	position: 'relative',
};

const overlaySx = {
	opacity: '0',
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	transition: '0.15s ease-out',
	transitionProperty: 'opacity, background-color, border-color',
	'&:hover': {
		opacity: '1',
		backgroundColor: 'rgba(0,0,0,0.04)',
		borderColor: 'bg.tertiary',
	},
};

const overlayButtonsSx = {
	justifyContent: 'space-between',
	padding: '15px',
};

// card content

const sitePreviewSx = {
	display: 'block',
	marginBottom: '15px',
	width: '100%',
	height: 'auto',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: 'bg.secondary',
};
const siteNameSx = {
	color: 'text.primaryalt',
	a: {
		textDecoration: 'none',
		color: 'text.primary',
	},
	marginBottom: '6px',
};

const Site = ({ project, site, onRemove, sx }: TProps) => {
	const removeBound = React.useCallback(
		e => {
			e.preventDefault();
			onRemove(site._id);
		},
		[site, onRemove],
	);
	return (
		<Box sx={sx}>
			<Box sx={overlayWrapperSx}>
				<Link to={`/project/${project._id}/site/${site._id}`}>
					<Image src={PreviewImage} alt={site.name} sx={sitePreviewSx} />
					<Box sx={overlaySx}>
						<Flex sx={overlayButtonsSx}>
							<Button variant="accent.iconrounded" onClick={emptyFunction}>
								<Icon name="pen" colors="accent" />
							</Button>
							<Button variant="error.iconrounded" onClick={removeBound}>
								<Icon name="trash" colors="accent" />
							</Button>
						</Flex>
					</Box>
				</Link>
			</Box>

			<Text variant="bodysm" sx={siteNameSx}>
				<Link to={`/project/${project._id}/site/${site._id}`}>{site.name}</Link>
			</Text>
		</Box>
	);
};

export default React.memo<TProps>(Site);
