// @flow

import _ from 'lodash/fp';
import type {
	TId,
	TStateEditor,
	TSites,
	TSite,
	TSpecsGrid,
	TGridBreakpointNames,
	TSpecsGridBreakpointList,
	TSpecsGridBreakpoints,
	TWidgetTheme,
} from '@graphite/types';
import { gridBreakpointsNames } from '@graphite/constants';

import reSelect from './libs/re-select';

const HALF_PERCENT_FACTOR = 0.005;

export const getUnit = reSelect<TSpecsGrid, TSpecsGrid, number>(
	gridspec => gridspec,
	gridspec => gridspec.unit,
)(() => 'site@getUnit');

export const getSite = reSelect<TStateEditor, TId, TSites, TId, TSite>(
	state => state.sites,
	(state, siteId) => siteId,
	(sites, siteId): TSite => sites[siteId],
)((state, siteId) => `site@getSite-${siteId}`);

export const getSiteBreakpoints = reSelect<
	{| gridspec: TSpecsGrid |},
	TSpecsGrid,
	TSpecsGridBreakpoints,
>(({ gridspec }) => gridspec, gridspec => gridspec.breakpoints)(() => 'getBreakpoints');

export const getActiveBreakpointNames = reSelect<
	{| gridspec: TSpecsGrid |},
	TSpecsGridBreakpoints,
	TGridBreakpointNames,
>(
	getSiteBreakpoints,
	breakpoints =>
		(_.filter(
			name => breakpoints[name] && breakpoints[name].active,
			gridBreakpointsNames,
		): TGridBreakpointNames),
)(({ gridspec }) => `getActiveBreakpointNames-${gridspec._id}`);

export const getActiveBreakpointList = reSelect<
	{| gridspec: TSpecsGrid |},
	TSpecsGridBreakpoints,
	TGridBreakpointNames,
	TSpecsGridBreakpointList,
>(getSiteBreakpoints, getActiveBreakpointNames, (breakpoints, names) =>
	_.map(name => breakpoints[name], names),
)(({ gridspec }) => `getActiveBreakpointList-${gridspec._id}`);

export const getActiveBreakpoints = reSelect<
	{| gridspec: TSpecsGrid |},
	TSpecsGridBreakpoints,
	TSpecsGridBreakpoints,
>(getSiteBreakpoints, breakpoints =>
	_.pickBy(breakpoint => breakpoint.active, breakpoints),
)(({ gridspec }) => `getActiveBreakpoints-${gridspec._id}`);

export const getActiveWidth = reSelect<
	{| gridspec: TSpecsGrid |},
	TSpecsGridBreakpointList,
	number,
	$ReadOnlyArray<number>,
>(
	({ gridspec }) => getActiveBreakpointList({ gridspec }),
	({ gridspec }) => gridspec.unit,
	(activeBreakpoints: TSpecsGridBreakpointList, unit: number) =>
		_.map(({ container, containerUnit }) => {
			if (containerUnit === 'px') {
				return container;
			}
			if (containerUnit === 'unit') {
				return container * unit;
			}
			if (containerUnit === '%') {
				return container * 0.01;
			}
			return 1;
		}, activeBreakpoints),
)(({ gridspec }) => `site@getActiveWidth-${gridspec._id}`);

export const getActiveGutter = reSelect<
	{| gridspec: TSpecsGrid |},
	number,
	TSpecsGridBreakpointList,
	$ReadOnlyArray<number>,
>(
	({ gridspec }) => getUnit(gridspec),
	({ gridspec }) => getActiveBreakpointList({ gridspec }),
	(unit, activeBreakpoints) =>
		(_.map(({ gutter, gutterUnit }) => {
			if (gutterUnit === 'px') {
				return gutter / 2;
			}
			if (gutterUnit === 'unit') {
				return (gutter * unit) / 2;
			}
			if (gutterUnit === '%') {
				return gutter * HALF_PERCENT_FACTOR;
			}
			return 0;
		}, activeBreakpoints): $ReadOnlyArray<number>),
)(({ gridspec }) => `getGutters-${gridspec._id}`);

export const getActiveColWidth = reSelect<
	{| gridspec: TSpecsGrid |},
	$ReadOnlyArray<number>,
	$ReadOnlyArray<number>,
	$ReadOnlyArray<number>,
>(
	getActiveWidth,
	({ gridspec }) => getActiveGutter({ gridspec }),
	(widths, paddings): $ReadOnlyArray<number> => {
		return widths.map((w, i) => w + paddings[i] * 2);
	},
)(({ gridspec }) => `site@getActiveColWidth-${gridspec._id}`);

export const getActivePaddings = reSelect<
	{| gridspec: TSpecsGrid |},
	number,
	TSpecsGridBreakpointList,
	$ReadOnlyArray<number>,
>(
	({ gridspec }) => getUnit(gridspec),
	({ gridspec }) => getActiveBreakpointList({ gridspec }),
	(unit, activeBreakpoints) =>
		_.map(({ padding, paddingUnit }) => {
			if (paddingUnit === 'px') {
				return padding / 2;
			}
			if (paddingUnit === 'unit') {
				return (padding * unit) / 2;
			}
			if (paddingUnit === '%') {
				return padding * HALF_PERCENT_FACTOR;
			}
			return 0;
		}, activeBreakpoints),
)(({ gridspec }) => `site@getActivePaddings-${gridspec._id}`);

export const getRebassBreakpoints = reSelect<
	TSpecsGrid,
	TSpecsGridBreakpoints,
	TWidgetTheme,
>(
	gridspec => getSiteBreakpoints({ gridspec }),
	breakpoints =>
		({
			breakpoints: gridBreakpointsNames
				.filter(name => breakpoints[name] && breakpoints[name].active)
				.map(name =>
					breakpoints[name] ? `${breakpoints[name].breakpoint}px` : '0px',
				)
				.slice(1),
		}: TWidgetTheme),
)(gridspec => `site@getRebassBreakpoints-${gridspec._id}`);

export default {};
