// @flow
import _ from 'lodash/fp';
import type { TId, TStateEditor, TWidgetOwnProps } from '@graphite/types';
import emptyObject from 'empty/object';
import { getTrueIds, getColSx, adjustColorspec } from '@graphite/selectors';
import { composeCached } from 'Widget/selectors';

import type { TConnectProps, TConnectPropsPre } from '../constants/types';

const mapStateToProps = (
	// FixMe: тут должен быть TStatePublished
	{ widgets }: TStateEditor,
	{
		id,
		widgetspec,
		colorspec,
		gridspec,
		effectspec,
		containerId: containerInitialId,
	}: TWidgetOwnProps,
): TConnectPropsPre => {
	const data: $PropertyType<TConnectProps, 'data'> = composeCached(
		widgets,
		widgets[id],
	);

	const babies = getTrueIds({
		_id: data._id,
		children: data.children,
		order: data.order,
		currentDevice: 'desktop', // ToDo Нужно доделать адптивность
	});

	const containerId = containerInitialId || 'none';
	const parent = composeCached(widgets, widgets[containerId]);

	const myId: TId = _.findKey(parent.children, v => v === data._id) || data._id;
	const sizes = parent.sizes ? parent.sizes[myId] : emptyObject;

	return {
		gridspec,
		colorspec: adjustColorspec({ data, colorspec, widgetspec, target: 'col' }),
		widgetspec,
		effectspec,
		data,
		babies,
		colSizes: getColSx({ data, sizes, gridspec }),
		style: {},
	};
};

export default mapStateToProps;
