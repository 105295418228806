// @flow
import React from 'react';
import styled from '@emotion/styled';
import _ from 'lodash/fp';
import emptyArray from 'empty/array';
import getDisplayName from '@graphite/get-display-name';
import { getTrueWidgetIds, getColSizeMap } from '@graphite/selectors';
import type { TColumnsGroup, TColumnsOrderList } from '@graphite/calc-columns';

import DropPlace from 'Widget/libs/dnd/DropPlace';
import ResizeCols from 'Widget/libs/resize-cols';
import { getActiveColAmount, getActiveGutter } from 'Editor/selectors/site';

import type { TConnectPropsWithDropPlace } from '../../constants/types';
import Border from './Border';

// ToDo Скоро этого тут не будет, ибо Витюня готовит новый дизайн
const Wrapper = styled.div`
	position: absolute;
	display: flex;
	height: ${({ position }) => (['left', 'right'].includes(position) ? '100%' : '0')};
	width: ${({ position, rowWidth = 0, gutter }) =>
		['top', 'bottom'].includes(position)
			? `calc(${rowWidth}% + ${gutter} + ${gutter})`
			: '0'};
	right: ${({ position }) => (position === 'right' ? '0' : 'auto')};
	left: ${({ position }) =>
		['left', 'top', 'bottom'].includes(position) ? '0' : 'auto'};
	top: ${({ position }) => (position === 'top' ? '0' : 'auto')};
	bottom: ${({ position }) => (position === 'bottom' ? '0' : 'auto')};
	margin-left: ${({ position, gutter }) => (position === 'left' ? `-${gutter}` : '0')};
	margin-right: ${({ position, gutter }) =>
		position === 'right' ? `-${gutter}` : '0'};
`;

const withDropPlace = <
	TProps: TConnectPropsWithDropPlace,
	WrapedComponent: React$ComponentType<TProps>,
>(
	Component: WrapedComponent,
): React$ComponentType<TProps> => {
	const WithDropPlace = (props: TProps) => {
		const {
			id,
			originId,
			containerId,
			data,
			currentDevice,
			gridspec,
			controls,
		} = props;

		const [{ data: dataParrent }] = React.useContext(ResizeCols);

		// Это всё оставил как было в mapStateToProps
		const colAmount: number = getActiveColAmount({
			currentDevice,
			gridspec,
		});

		const orderList: TColumnsOrderList = getTrueWidgetIds({
			...dataParrent,
			currentDevice,
		});

		const colSizeMap: TColumnsGroup = getColSizeMap({
			data: { ...dataParrent },
			currentDevice,
			colAmount,
			orderList,
		});

		const { positions } = dataParrent;

		const colSizeData = colSizeMap
			.map((colSize, rowIndex) => ({
				colSize,
				rowIndex,
				ids: colSize.orderList
					.map(({ trueId }) => trueId)
					.filter(id => !positions || !positions[id]),
			}))
			.find(({ ids }) => ids.includes(data._id));

		const { colSize, rowIndex, ids = emptyArray } = colSizeData || {};

		const blockId = containerId || 'none';
		const rowId = `${blockId}-${rowIndex}`;
		const isFirst = _.head(ids) === data._id;
		const isLast = _.last(ids) === data._id;

		const nextWidgetIndex = ids.findIndex(id => id === data._id);
		const nextWidgetId = ids[nextWidgetIndex + 1];
		const rowWidth =
			colSize && colSize[data._id] && colSize[data._id].width
				? (1 /
						(colSize[data._id].width +
							colSize[data._id].marginLeft +
							colSize[data._id].marginRight)) *
				  100
				: 1;
		// Вот это вот всё)))

		const gutter = getActiveGutter({ currentDevice, gridspec });

		return (
			<>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} />
				<Wrapper position="right" gutter={gutter}>
					<DropPlace
						rowId={rowId}
						originId={originId}
						widgetId={id}
						nextWidgetId={nextWidgetId || undefined}
						containerId={blockId}
						position="after"
						direction="vertical"
						placement={isLast ? 'last' : 'middle'}
					/>
				</Wrapper>
				<Border id={data._id} controls={controls} />
				{isFirst && (
					<>
						<Wrapper position="left" gutter={gutter}>
							<DropPlace
								rowId={rowId}
								originId={originId}
								widgetId={id}
								containerId={blockId}
								position="before"
								direction="vertical"
								placement="first"
							/>
						</Wrapper>

						<Wrapper position="top" rowWidth={rowWidth} gutter={gutter}>
							<DropPlace
								rowId={rowId}
								originId={originId}
								widgetId={id}
								containerId={blockId}
								position="before"
								direction="horizontal"
								placement="first"
							/>
						</Wrapper>
						<Wrapper position="bottom" rowWidth={rowWidth} gutter={gutter}>
							<DropPlace
								rowId={rowId}
								originId={originId}
								widgetId={id}
								containerId={blockId}
								position="after"
								direction="horizontal"
								placement="last"
							/>
						</Wrapper>
					</>
				)}
			</>
		);
	};

	WithDropPlace.displayName = `withDropPlace(${getDisplayName(Component)})`;

	return React.memo<TProps>(WithDropPlace);
};

export default withDropPlace;
