// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAbsolute, withAbsoluteDrag } from '@graphite/abs-grid';
import type {
	TId,
	TWidgetOwnProps,
	Dispatch,
	TAction,
	TWidgetChain,
	TWidgetDiff,
} from '@graphite/types';

import withSymbiote from 'Editor/libs/with-symbiote';
import withControls from 'Editor/libs/with-controls';
import checkActiveWidget from 'Editor/libs/check-active-widget';
import getMapStateToPropsEdit from 'Widget/libs/primitive/getMapStateToPropsEdit';
import withDrag from 'Widget/libs/dnd/drag';
import { repositionWidget, editWidget } from 'Editor/ducks/widgets';
import { startEdit, resetEdit } from 'Editor/ducks/editor';
import { insertImages, removeImage, reset } from 'Editor/ducks/imageLibrary';
import withWidgetControls, { withWidgetResizer } from 'Widget/libs/with-widget-controls';
import withWidgetEdit from 'Widget/libs/with-widget-edit';
import withFilter from 'Widget/libs/with-filter';
import withRef from 'Widget/libs/with-ref';

import type {
	TConnectPropsEdit,
	TConnectPropsDrag,
	TConnectProps,
} from './constants/types';
import WidgetComponent from './Code';
import Controls from './Controls';
import withCodeEditor from './libs/with-code-editor';
import withEmpty from './libs/with-empty';
import withWrapper from './libs/with-wrapper';

const filter = (props: TConnectPropsDrag): TConnectProps => ({
	id: props.id,
	containerId: props.id,
	instanceId: props.id,
	data: props.data,
	widgetspec: props.widgetspec,
	colorspec: props.colorspec,
	effectspec: props.effectspec,
	gridspec: props.gridspec,
	position: props.position,
	dispatch: props.dispatch,
	dragContainer: props.dragContainer,
	dragFlip: props.dragFlip,
	rowId: props.rowId,
	children: props.children,
	direction: props.direction,
});

const mapDispatchToProps = (dispatch: Dispatch<TAction>, ownProps: TWidgetOwnProps) => {
	const { instanceId } = ownProps;

	return {
		// FixMe: выпилить dispatch
		dispatch,
		startEdit: (id: TId, widgetChain: TWidgetChain) => {
			dispatch(startEdit(id, widgetChain, instanceId));
		},
		resetEdit: () => {
			dispatch(resetEdit());
		},
		editWidget: (_id: TId, instanceId: ?TId, originId: TId, diff: TWidgetDiff) => {
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		repositionWidget: (
			targetId,
			originId,
			containerId,
			position,
			{ height, ...offset },
		) => {
			dispatch(repositionWidget(targetId, originId, containerId, position, offset));
		},
		insertImage: (files: FileList) => {
			dispatch(insertImages(files));
		},
		resetImage: () => {
			dispatch(reset());
		},
		removeImage: (imageId: TId) => {
			dispatch(removeImage(imageId));
		},
	};
};

const WidgetComponentEdit = compose(
	// берём данные из стейта
	connect<TConnectPropsEdit, TWidgetOwnProps, _, _, _, _>(
		getMapStateToPropsEdit<TConnectPropsEdit>(),
		mapDispatchToProps,
	),
	// добавляет реф, который прокидывается через все ХОКи прямо вглубь
	Component => withRef<TConnectPropsEdit>(Component),
	withDrag,
	withWidgetResizer,
	// Выносим ХОКи на служебный слой
	withSymbiote(
		// Добавляет возможность таскать абсолютные виджеты
		withAbsoluteDrag,
		{
			level: 'abs-drag-place',
		},
	),
	// Выносим ХОКи на служебный слой
	withSymbiote(withCodeEditor),
	// Выносим ХОКи на служебный слой
	withSymbiote(
		compose(
			withWidgetEdit,
			withWidgetControls({ margin: true, height: false, width: true }),
			withControls(checkActiveWidget, Controls),
		),
	),
	// Добавляем виджету возможность быть абсолютным
	withAbsolute(),
	withWrapper,
	withEmpty,
	WidgetComponent =>
		withFilter<TConnectPropsDrag, TConnectProps>(filter, WidgetComponent),
)(WidgetComponent);

export default WidgetComponentEdit;
