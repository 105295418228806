// @flow
import _ from 'lodash/fp';
import type { TSx, TSpecsGrid, TGridBreakpointName } from '@graphite/types';
import { defaultDevice } from '@graphite/constants';

import { getActiveBreakpointNames } from './site';
import reSelect from './libs/re-select';

export type TMaxCombineSxList = $ReadOnlyArray<TSx>;
export type TMaxCombineSxParams = $ReadOnly<{|
	sxList: TMaxCombineSxList,
	gridspec: TSpecsGrid,
|}>;
export type TMaxCombineSxFn = TMaxCombineSxParams => TSx;

export const mediaString = (width: number): string =>
	`@media screen and (max-width: ${width - 1}px)`;

export const getBreakpointWidth = (
	breakpoint: TGridBreakpointName,
	gridspec: TSpecsGrid,
): number => {
	return gridspec.breakpoints[breakpoint].breakpoint;
};

// sxList учитывает порядок активных breakpoint от большого к маленькому.
// sxList: [{ свойства для дефолтного самого большого девайса }, { для девайса меньше }, ...]
export const maxCombineSx: TMaxCombineSxFn = reSelect<
	TMaxCombineSxParams,
	TMaxCombineSxList,
	TSpecsGrid,
	TSx,
>(
	({ sxList }) => sxList,
	({ gridspec }) => gridspec,
	(sxList, gridspec) => {
		const breakpointNames = getActiveBreakpointNames({ gridspec });

		const medias: TSx = breakpointNames.reduce((map: TSx, breakpointName, index) => {
			const filteredBox = {
				...sxList[index],
			};

			const prevBox = {
				...(index > 0 ? sxList[index - 1] : {}),
			};

			// Для десктопа добавляем все свойста, что есть.
			if (breakpointName === defaultDevice)
				return {
					...map,
					...filteredBox,
				};

			// откинем лишние свойства, что бы не засорять css
			_.mapKeys(prop => {
				if (prevBox[prop] === filteredBox[prop]) {
					delete filteredBox[prop];
				}
			}, prevBox);

			return {
				...map,
				[mediaString(getBreakpointWidth(breakpointName, gridspec))]: filteredBox,
			};
		}, {});

		return medias;
	},
)(({ sxList, gridspec }) => {
	const breakpointNames = getActiveBreakpointNames({ gridspec });

	return [
		'widget@maxCombineSx',
		...sxList.map(_.size),
		...breakpointNames.map(breakpoint => getBreakpointWidth(breakpoint, gridspec)),
	].join('-');
});

export default maxCombineSx;
