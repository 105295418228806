// @flow
import emptyObject from 'empty/object';

import type { TStateEditor, TWidgetOwnProps } from '@graphite/types';
import {
	getTrueIds,
	getTrueWidgetIds,
	getBlockSx,
	adjustColorspec,
	adjustGridspec,
	getColSizeMap,
	getCurrentPage,
} from '@graphite/selectors';
import { getActiveColAmount } from 'Editor/selectors/site';
import { composeCached } from 'Widget/selectors';

import type { TConnectProps, TParent } from '../constants/types';

const mapStateToProps = (
	// FixMe: Здесь должен быть TPublishedState
	state: TStateEditor,
	{
		id,
		containerId: containerInitialId,
		widgetspec,
		colorspec,
		gridspec,
		effectspec,
	}: TWidgetOwnProps,
) => {
	const { widgets, editor = { currentDevice: 'desktop' } } = state;
	const currentPage = getCurrentPage(state);
	const containerId = containerInitialId || 'none';
	const parent: TParent = composeCached(widgets, widgets[containerId]);
	const data: $PropertyType<TConnectProps, 'data'> = composeCached(
		widgets,
		widgets[id],
	);

	/**
		Тут была и раньше сломана адаптация под мобильные устройства на опубликованном сайте,
		Потому что была использована функция getBlockSizesEdit, которая выдаёт
		результат только под один девайс. Я сделал только немного хуже.

		FixMe: починить адаптацию мобильных устройств
		FixMe: починить мемоизацию
 	*/
	const gridFinal = adjustGridspec({ data, gridspec });

	const colAmount = getActiveColAmount({
		currentDevice: editor.currentDevice,
		gridspec: gridFinal,
	});

	const parentPositions = parent.positions || emptyObject;
	const positions = data.positions || emptyObject;

	return {
		data,
		babies: getTrueIds({
			_id: data._id,
			children: data.children,
			order: data.order,
			currentDevice: 'desktop', // ToDo Нужно доделать адптивность
		}),
		colSizeMap: getColSizeMap({
			data: { ...data },
			// Для каждого девайса свой colSizeMap, ибо может быть разное количество rows
			currentDevice: editor.currentDevice,
			colAmount,
			orderList: getTrueWidgetIds({
				...data,
				currentDevice: editor.currentDevice,
			}),
		}),
		blockSizes: getBlockSx({ data, gridspec: gridFinal }),
		widgetspec,
		colorspec: adjustColorspec({ data, colorspec, widgetspec, target: 'block' }),
		gridspec: gridFinal,
		effectspec,
		position: parentPositions[data._id] || null,
		positions,
		originId: currentPage,
	};
};

export default mapStateToProps;
