// @flow

const configDefault = {
	storageBucket: 'graphite-test-1cc66.appspot.com',
	coloraiUrl: 'http://localhost:5000/graphite-d460b/us-central1/getcolorai',
	messagingSenderId: '685033122039',
	measurementId: 'G-GZESF6S3QF',
	databaseURL: 'https://graphite-test-1cc66.firebaseio.com',
	projectId: 'graphite-test-1cc66',
	authDomain: 'graphite-test-1cc66.firebaseapp.com',
	appHome: '//localhost:3011/',
	appId: '1:845575531460:web:211446c70f74166c505291',
	appUrl: '//localhost:3001/',
	apiKey: 'AIzaSyC-b0VgKkxCSJYwA9o1v_13_78NtlvoV0g',
	sentryDNS: process.env.SENTRY_DNS,
	sentryEnabled: true,
	apiUnsplashKey: process.env.UNSPLASH_API_KEY
		? process.env.UNSPLASH_API_KEY
		: 'VyXmdJMFWvmvW-wcKL05EXqu2jeaoU7S5eC2jjszJIQ',
	apiGoogleFontsKey: process.env.GOOGLE_FONTS_API_KEY
		? process.env.GOOGLE_FONTS_API_KEY
		: 'AIzaSyC-b0VgKkxCSJYwA9o1v_13_78NtlvoV0g',
};

const configProd =
	process.env.NODE_ENV === 'production' &&
	process.env.FIREBASE_ENV !== 'test' &&
	process.env.FIREBASE_API_KEY
		? {
				storageBucket: 'graphite-d460b.appspot.com',
				coloraiUrl: '//us-central1-graphite-d460b.cloudfunctions.net/getcolorai',
				databaseURL: 'https://graphite-d460b.firebaseio.com',
				projectId: 'graphite-d460b',
				authDomain: 'graphite-d460b.firebaseapp.com',
				appHome: '//graphite.space/',
				appId: '1:685033122039:web:b08db054e8748405960bf2',
				appUrl: '//app.graphite.space/',
				apiKey: process.env.FIREBASE_API_KEY,
		  }
		: {};

export const config = { ...configDefault, ...configProd };

export default config;
