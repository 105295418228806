// @flow
import Logger from '@graphite/logger';

export default Logger.init(async () => {
	// FixMe: временное решение, удалить второй await после обновления Flow до 137 версии
	if (process.env.NODE_ENV === 'production' && process.env.ENV_MODE !== 'server') {
		const { getConfig, auth, logEvent } = require('../firebase');

		const { sentryEnabled, sentryDNS } = await await getConfig();

		const currentUser = await new Promise(res => auth.onAuthStateChanged(res));

		const user = currentUser
			? {
					id: currentUser.uid,
					username: currentUser.displayName,
					email: currentUser.email,
			  }
			: null;

		return { sentryEnabled, sentryDNS, user, logEvent };
	}
	return {};
});
