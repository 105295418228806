// @flow
import type { TAction, TUser, TUsersState as TState } from '@graphite/types';
import emptyObject from 'empty/object';
import _ from 'lodash/fp';
import { eventChannel, type EventChannel } from 'redux-saga';
import { takeEvery, take, cancelled, call, put, fork, cancel } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import type { Saga } from 'redux-saga';

import logger from 'libs/logger';
import {
	auth,
	collection,
	googleAuthProvider,
	signInWithPopup,
	createUserWithEmailAndPassword as createUserWithEmailAndPasswordAuth,
	signInWithEmailAndPassword as signInWithEmailAndPasswordAuth,
} from 'libs/firebase';

const RECEIVE = 'USERS/RECEIVE';
const REJECT = 'USERS/REJECT';
const SIGN_IN_WITH_GOOGLE = 'USERS/SIGN_IN_WITH_GOOGLE';
const SIGN_IN_WITH_EMAIL_AND_PASSWORD = 'USERS/SIGN_IN_WITH_EMAIL_AND_PASSWORD';
const CREATE_USER_WITH_EMAIL_AND_PASSWORD = 'USERS/CREATE_USER_WITH_EMAIL_AND_PASSWORD';
const SIGN_UP = 'USERS/SIGN_UP';
const LOG_OUT = 'USERS/LOG_OUT';
const REQUEST_AUTH = 'USERS/REQUEST_AUTH';
const RECEIVE_AUTH = 'USERS/RECEIVE_AUTH';
const REJECT_AUTH = 'USERS/REJECT_AUTH';

export const receive = (user: TUser): TAction => ({
	type: RECEIVE,
	payload: { user },
});

export const reject = (): TAction => ({
	type: REJECT,
	payload: {},
});

export const signInWithGoogle = (): TAction => ({
	type: SIGN_IN_WITH_GOOGLE,
	payload: {},
});

export const signInWithEmailAndPassword = (email: string, password: string): TAction => ({
	type: SIGN_IN_WITH_EMAIL_AND_PASSWORD,
	payload: { email, password },
});

export const createUserWithEmailAndPassword = (
	email: string,
	password: string,
	name: string,
): TAction => ({
	type: CREATE_USER_WITH_EMAIL_AND_PASSWORD,
	payload: { email, password, name },
});

export const signUp = (): TAction => ({
	type: SIGN_UP,
	payload: {},
});

export const logOut = (): TAction => ({
	type: LOG_OUT,
	payload: {},
});

export const requestAuth = (): TAction => ({
	type: REQUEST_AUTH,
	payload: {},
});

export const receiveAuth = (): TAction => ({
	type: RECEIVE_AUTH,
	payload: {},
});

export const rejectAuth = (code: string, message: string): TAction => ({
	type: REJECT_AUTH,
	payload: { code, message },
});

export function* signInWithGoogleSaga(): Saga<void> {
	yield takeEvery(SIGN_IN_WITH_GOOGLE, function*(): Saga<void> {
		try {
			yield put(requestAuth());
			yield call(signInWithPopup, googleAuthProvider);
			yield put(receiveAuth());
			logger.info('signInWithGoogle');
		} catch (e) {
			logger.error(e);
			yield put(rejectAuth(e.code, e.massage));
		}
	});
}

export function* signInWithEmailAndPasswordSaga(): Saga<void> {
	yield takeEvery(SIGN_IN_WITH_EMAIL_AND_PASSWORD, function*({
		payload: { email, password },
	}: {
		payload: { email: string, password: string },
	}): Saga<void> {
		try {
			yield put(requestAuth());
			yield call(signInWithEmailAndPasswordAuth, email, password);
			yield put(receiveAuth());
			logger.info('signInWithEmailAndPassword');
		} catch (e) {
			yield put(rejectAuth(e.code, e.message));
		}
	});
}

const waitUserCreate = (uid: string): Promise<void> =>
	new Promise((res: () => void, rej: (error: any) => void) => {
		// FixMe: грязная функция
		const unsubscribe = collection('users')
			.doc(uid)
			.onSnapshot((doc: Object) => {
				if (doc.data()) {
					unsubscribe();
					res();
				}
			}, rej);
	});

export function* createUserWithEmailAndPasswordSaga(): Saga<void> {
	yield takeEvery(CREATE_USER_WITH_EMAIL_AND_PASSWORD, function*({
		payload: { email, password, name },
	}: {
		payload: { email: string, password: string, name: string },
	}): Saga<void> {
		try {
			yield put(requestAuth());

			// регистрируем пользователя
			const {
				user: { uid },
			} = yield call(createUserWithEmailAndPasswordAuth, email, password);

			// ждём пока запись о пользователе появится в БД
			yield call(waitUserCreate, uid);

			// отправляем письмо с подтверждением почты
			yield call([auth.currentUser, 'sendEmailVerification']);
			// обновляем имя
			yield call([collection('users').doc(uid), 'update'], {
				_id: uid,
				userId: uid,
				name,
			});
			yield put(receiveAuth());
			logger.info('createUserWithEmailAndPassword');
			// Входим в аккаунт
			yield put(
				receive({
					_id: uid,
					...auth.currentUser,
				}),
			);
		} catch (e) {
			logger.error(e);
			yield put(rejectAuth(e.code, e.message));
		}
	});
}

export function* logOutSaga(): Saga<void> {
	yield takeEvery(LOG_OUT, () => {
		try {
			auth.signOut();
			logger.info('logOut');
		} catch (e) {
			logger.error(e);
		}
	});
}

/**
	Коллаборация
 */
const getUserChangeEventsChannel = (_id: string): EventChannel<{ user: TUser }> =>
	eventChannel<{ user: TUser }>((emit: ({ user: TUser }) => void): (() => void) => {
		const unsubscribe = collection('users')
			.doc(_id)
			.onSnapshot(async (doc: any) => {
				const user = doc.data();
				emit({ user });
			});

		// return a function that can be used to unregister listeners when the saga is cancelled
		return unsubscribe;
	});

/**
	Подписываемся на изменение данных пользователя
 */
export function* watchUserChangeSaga(_id: string): Saga<void> {
	const authEventsChannel = getUserChangeEventsChannel(_id);

	try {
		while (true) {
			const { user } = yield take(authEventsChannel);
			if (!user) return;

			yield put(receive(user));
		}
	} finally {
		// unregister listener if the saga was cancelled
		if (yield cancelled()) authEventsChannel.close();
	}
}

/**
	Подписываемся на статус изменения статуса авторизации пользователя
 */
const authEventsChannel = eventChannel(
	(emit: ({ user: ?{ uid: string } }) => void): (() => void) => {
		const unsubscribe = auth.onAuthStateChanged((user: ?{ uid: string }) => {
			emit({ user });
		});
		// return a function that can be used to unregister listeners when the saga is cancelled
		return unsubscribe;
	},
);

/**
	Следим за статусом авторизации пользователя

	// FixMe: Проверить, что авторизация не может сработать раньше этой саги
	// иначе будет пиздец, тк колбэк никогда не вызоветься
 */
export function* watchAuthChange(): Saga<void> {
	let wathUserChangeTask;
	try {
		while (true) {
			const { user } = yield take(authEventsChannel);

			if (user) {
				// подписываемся на изменение данных пользователя
				const { uid } = user;
				if (wathUserChangeTask) yield cancel(wathUserChangeTask);
				// между остановкой саги и её перезапуском нужна пауза
				wathUserChangeTask = yield fork(watchUserChangeSaga, uid);
			} else {
				// выходим из системы
				if (wathUserChangeTask) {
					yield cancel(wathUserChangeTask);
					wathUserChangeTask = null;
				}
				yield put(reject());
			}
		}
	} catch (e) {
		logger.error(e);
	} finally {
		// unregister listener if the saga was cancelled
		if (yield cancelled()) {
			if (wathUserChangeTask) yield cancel(wathUserChangeTask);
			authEventsChannel.close();
		}
	}
}

export function* saga(): Saga<void> {
	// ToDo: написать документацию

	// Сначала подписываемся на авторизацию
	yield fork(watchAuthChange);

	// Потом запускаем саги логина, регистрации и разлогина
	yield fork(signInWithGoogleSaga);
	yield fork(signInWithEmailAndPasswordSaga);
	yield fork(createUserWithEmailAndPasswordSaga);
	yield fork(logOutSaga);
}

const initialState: TState = {
	currentUser: null,
	isFetching: true,
	didInvalidate: false,
	items: emptyObject,
	isAuth: false,
	authError: null,
};

export default handleActions<TState, TAction>(
	{
		[REQUEST_AUTH](state: TState): TState {
			return _.flow(_.set('isAuth', true), _.set('authError', null))(state);
		},
		[RECEIVE_AUTH](state: TState): TState {
			return _.flow(_.set('isAuth', false))(state);
		},
		[REJECT_AUTH](
			state: TState,
			{
				payload: { code, message },
			}: { +payload: { code: string, message: string } },
		): TState {
			return _.flow(
				_.set('isAuth', false),
				_.set('authError', { code, message }),
			)(state);
		},
		[REJECT](state: TState): TState {
			return _.flow(
				_.set('currentUser', null),
				_.set('isFetching', false),
				_.set('didInvalidate', false),
			)(state);
		},
		[RECEIVE](
			state: TState,
			{ payload: { user } }: { +payload: { +user: TUser } },
		): TState {
			return _.flow(
				_.set('currentUser', user._id),
				_.set('isFetching', false),
				_.set('didInvalidate', false),
				_.set(`items.${user._id}`, user),
			)(state);
		},
	},
	initialState,
);
