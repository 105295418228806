// @flow

import React, { memo, useCallback, useMemo } from 'react';
import { ListItem, Flex, Icon, Text, Box } from '@graphite/uneon';
import emptyFunction from 'empty/function';
import type { TTreeDataElement } from '../selectors';

type TProps = $ReadOnly<{|
	layer: TTreeDataElement,
	onExpand: (string, boolean) => void,
	isDragNode: boolean,
|}>;

const listItemSx = {
	position: 'relative',
	zIndex: 3,
	height: 'auto',
};

const boxtItemSx = {
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'bg.primaryalt',
	':last-child': {
		border: 'none',
	},
};

const flexSx = {
	alignItems: 'center',
	padding: '0',
	cursor: 'default',
};

const textStyle = {
	marginLeft: '6px',
};

function LabelLayer({ layer, onExpand = emptyFunction, isDragNode = false }: TProps) {
	const clickExpand = useCallback(
		e => {
			e.stopPropagation();
			return onExpand(layer.key, !layer.expanded);
		},
		[onExpand, layer],
	);
	const iconSx = useMemo(
		() => ({
			marginLeft: '-6px',
			visibility: (layer.children?.length && 'visible') || 'hidden',
		}),
		[layer],
	);

	return (
		<ListItem sx={listItemSx}>
			<Flex sx={flexSx}>
				<Icon
					name="triangle-top"
					rotation={layer.expanded ? 180 : 90}
					colors="tertiaryflat"
					size="xxsm"
					onClick={clickExpand}
					sx={iconSx}
				/>
				<Box sx={boxtItemSx}>
					<Text
						variant={layer.kind === 'block' ? 'captionlg' : 'notelg'}
						sx={textStyle}
						color={layer.selected || isDragNode ? 'white' : 'text.primary'}
					>
						{layer.title}
					</Text>
				</Box>
			</Flex>
		</ListItem>
	);
}

export default memo<TProps>(LabelLayer);
