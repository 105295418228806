// @flow

import React, { memo, useState, useCallback } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Box, Text } from '@graphite/uneon';

import Widget from './Widget';
import withGridDrag from './libs/withGridDrag';
import withAbsoluteDrag from './libs/withAbsoluteDrag';
import type { TWidgetsProps } from './constants/types';

const buttonProps = [
	{ name: 'grid', label: 'As Grid' },
	{ name: 'absolute', label: 'As Absolute' },
];

const WidgetGrid = withGridDrag(Widget);
const WidgetAbsolute = withAbsoluteDrag(Widget);

const titleSx = {
	marginBottom: '18px',
	cursor: 'default',
};

const tabSx = {
	padding: '18px 0 36px',
};

const widgetsSx = {
	position: 'relative',
	margin: '0 -24px 18px',
};

const WidgetPanel = (props: TWidgetsProps) => {
	const { t } = useTranslation();
	const { widgetPresets } = props;

	const [activeTab, setActiveTab] = useState('grid');

	const clickBound = useCallback(
		(e, name) => {
			if (typeof name === 'string') {
				setActiveTab(name);
			}
		},
		[setActiveTab],
	);

	return (
		<>
			<Text variant="title4" color="text.primaryalt" sx={titleSx}>
				{t('Add Widget')}
			</Text>
			<ButtonGroup
				behavior="radio"
				active={activeTab}
				buttons={buttonProps}
				variant="normal"
				shape="rounded"
				size="md"
				colors="accentghost"
				activeColors="accent"
				onClick={clickBound}
				sx={tabSx}
			/>
			<Box sx={widgetsSx}>
				{widgetPresets.map(preset =>
					activeTab === 'grid' ? (
						<WidgetGrid
							key={preset._id}
							protoId={preset._id}
							title={_.capitalize(preset.kind)}
							icon={`widget-${preset.kind}`}
						/>
					) : (
						<WidgetAbsolute
							key={preset._id}
							protoId={preset._id}
							kind={preset.kind}
							title={_.capitalize(preset.kind)}
							icon={`widget-${preset.kind}`}
						/>
					),
				)}
			</Box>
		</>
	);
};

export default memo<TWidgetsProps>(WidgetPanel);
