// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Box, Text } from '@graphite/uneon';

import type { TId, TProject, TSites } from '@graphite/types';
import { getSites } from '@graphite/selectors';

import Site from './Site';
import EmptySite from './EmptySite';

type TProps = $ReadOnly<{|
	project: TProject,
	removeProject: TId => void,
	insertSite: TId => void,
	removeSite: (TId, TId) => void,
|}>;

const headerSx = {
	justifyContent: 'space-between',
	alignItems: 'flex-end',
	marginBottom: '30px',
	paddingBottom: '16px',
	borderBottomWidth: '2px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'text.primaryalt',
};

const headersButtonSx = {
	marginLeft: '18px',
};

const sitesSx = {
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fill, minmax(312px, 1fr))',
};

const siteSx = {
	marginBottom: '30px',
};

const Project = ({ project, removeProject, insertSite, removeSite }: TProps) => {
	const { t } = useTranslation();
	const sites: TSites = useSelector(state => getSites(state, project._id));

	const insertSiteHandler = React.useCallback(() => insertSite(project._id), [
		insertSite,
		project._id,
	]);

	const removeSiteHandler = React.useCallback(
		id => {
			removeSite(project._id, id);
		},
		[project._id, removeSite],
	);

	const removeProjectHandler = React.useCallback(() => removeProject(project._id), [
		removeProject,
		project,
	]);

	return (
		<>
			<Flex sx={headerSx}>
				<Text as="h4" variant="title4" color="text.primaryalt">
					{project.name}
				</Text>
				<Flex>
					<Button
						variant="primaryaltflat.flat.sm"
						onClick={removeProjectHandler}
						sx={headersButtonSx}
					>
						{t('dashboard.project.remove')}
					</Button>
				</Flex>
			</Flex>

			<Box variant="grid" sx={sitesSx}>
				{_.map(
					site => (
						<Flex variant="grid.column" key={site._id}>
							<Site
								onRemove={removeSiteHandler}
								project={project}
								site={site}
								sx={siteSx}
							/>
						</Flex>
					),
					sites,
				)}
				<EmptySite
					insertSiteHandler={insertSiteHandler}
					isEmptySite={_.isEmpty(sites)}
				/>
			</Box>
		</>
	);
};

export default React.memo<TProps>(Project);
