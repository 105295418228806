// @flow

import React from 'react';
import Cropper from 'react-easy-crop';
import styled from '@emotion/styled';

import { editWidget } from 'Editor/ducks/widgets';

import Wrap from '../Wrap';
import Element from '../Element';
import type { TConnectPropsWithCrop } from '../constants/types';

const cropperStyle = {
	containerStyle: {
		overflow: 'visible',
	},
};

const StyledWrap = styled(Wrap)`
	overflow: ${({ isEdit }) => (isEdit ? 'visible' : 'hidden')};

	> div > div > div {
		box-shadow: none;
		border: 2px dashed #3d5eff;
	}
`;

const withCrop = <T: TConnectPropsWithCrop>(): React$ComponentType<T> => {
	const WithCrop = (props: T, ref) => {
		const {
			instanceId,
			originId,
			data,
			widgetMode,
			dispatch,
			colorspec,
			effectspec,
			widgetspec,
			gridspec,
		} = props;

		const { _id, src, rotation, cropMode = 'fit' } = data;

		const [cropSize, setCropSize] = React.useState({ width: 0, height: 0 });
		const [crop, setCrop] = React.useState(data.crop || { x: 0, y: 0 });
		const [zoom, setZoom] = React.useState(data.zoom || 1);
		const onCropComplete = React.useCallback(
			(/* croppedArea, croppedAreaPixels */) => {
				if (!originId) return;
				dispatch(
					editWidget(_id, instanceId, originId, {
						crop,
						zoom,
					}),
				);
			},
			[_id, crop, dispatch, instanceId, originId, zoom],
		);

		const isEdit = widgetMode === 'widget-edit' && cropMode === 'crop';

		React.useEffect(() => {
			if (isEdit && ref.current) {
				const { current } = ref;

				if (current instanceof HTMLElement) {
					setCropSize({
						width: Number(current.offsetWidth),
						height: Number(current.offsetHeight),
					});
				}
			}
		}, [isEdit, setCropSize, ref]);

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<StyledWrap {...props} isEdit={isEdit} ref={ref} isPassive>
				{isEdit ? (
					<Cropper
						image={src}
						crop={crop}
						zoom={zoom}
						rotation={rotation}
						cropSize={cropSize}
						key={`${cropSize.width}-${cropSize.height}`}
						restrictPosition={false}
						showGrid={false}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
						style={cropperStyle}
					/>
				) : (
					<Element
						colorspec={colorspec}
						effectspec={effectspec}
						widgetspec={widgetspec}
						gridspec={gridspec}
						data={data}
					/>
				)}
			</StyledWrap>
		);
	};

	WithCrop.displayName = 'withTextEditable(Image)';

	return React.memo(React.forwardRef(WithCrop));
};

export default withCrop;
