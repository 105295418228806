// @flow
import React from 'react';
import { Box } from '@graphite/uneon';
import _ from 'lodash/fp';
import type { TSx } from '@graphite/types';

import LogoSvg from './logo.svg';

type TProps = $ReadOnly<{|
	sx?: TSx,
|}>;

const logoSx = {
	svg: {
		width: '100%',
		height: 'auto',
	},
	'#letters': {
		fill: 'text.primaryalt',
	},
	'#alpha': {
		fill: 'text.accent',
	},
};

const Logo = ({ sx }: TProps) => {
	const boxSx: TSx = React.useMemo(() => _.assign(logoSx, sx), [sx]);

	return (
		<Box sx={boxSx}>
			<LogoSvg />
		</Box>
	);
};

export default React.memo<TProps>(Logo);
