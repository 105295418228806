// @flow

import React, { memo } from 'react';
import { Flex } from '@graphite/uneon';
import emptyFunction from 'empty/function';

import ButtonPanel from './ButtonPanel';
import type { TTopPanels } from './types';

type TProps = $ReadOnly<{|
	active: ?string,
	panels: TTopPanels,
	onClick?: (?string) => void,
|}>;

function ButtonsPanels({ active, panels, onClick = emptyFunction }: TProps) {
	return (
		<Flex as="nav">
			{panels.map(panel => (
				<ButtonPanel
					key={panel.value}
					panel={panel}
					active={active === panel.value}
					onClick={onClick}
				/>
			))}
		</Flex>
	);
}

ButtonsPanels.defaultProps = {
	onClick: emptyFunction,
};

export default memo<TProps>(ButtonsPanels);
