// @flow

import React from 'react';
import type { Dispatch } from 'redux';

import getBreakpointWidth from 'libs/get-breakpoint-width';
import { MIN_BREAKPOINT_WIDTH, CLICK_THRESHOLD_SQUARED } from '@graphite/constants';
import type { TAction, TSpecsGrid, TGridBreakpointName } from '@graphite/types';
import { getActiveBreakpointNames } from '@graphite/selectors';
import { updateEditor, resetEdit } from 'Editor/ducks/editor';

type TControlsData = {|
	x0: number,
	y0: number,
	width0: number,
	x: number,
	y: number,
	width: number,
	// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
	button: number,
	space: boolean,
	wheel: boolean,
|};

type TPageState = 'edit' | 'scroll' | 'resize';

const initialControls = {
	x0: 0,
	y0: 0,
	width0: 0,
	x: 0,
	y: 0,
	width: 0,
	button: -1,
	space: false,
	wheel: false,
};

const mouseDownResizeSide = (
	e: SyntheticMouseEvent<EventTarget>,
	side: 'left' | 'right',
	controls: TControlsData,
	pageState: TPageState,
	setPageState: ((TPageState => TPageState) | TPageState) => void,
	setIsResizeLeft: ((boolean => boolean) | boolean) => void,
) => {
	if (controls.button === -1) {
		controls.button = e.button;
	}

	controls.x = e.clientX;
	controls.x0 = e.clientX;
	controls.y = e.clientY;
	controls.y0 = e.clientY;
	controls.width0 = controls.width;

	if (pageState === 'edit' && e.button === 0 /* left */) {
		if (!controls.space) {
			setPageState('resize');
			setIsResizeLeft(side === 'left');
		}
		e.preventDefault();
		e.stopPropagation();
	}
};

type TMouseButtonName = 'left' | 'right' | 'wheel';
type TMouseButtonBits = $ReadOnly<{ [TMouseButtonName]: number }>;

const mouseButtonBits: TMouseButtonBits = {
	left: 1, // 0b0001
	right: 2, // 0b0010
	wheel: 4, // 0b0100
};

// Проверяет, что бит указанной кнопки активен
const checkMouseButton = (buttons: number, name: TMouseButtonName): boolean => {
	// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
	// https://www.w3.org/TR/DOM-Level-3-Events/#interface-mouseevent
	// Код ниже эффективно эквивалентен закомментированному но ОБХОДИТ ЛИНТЫ:
	// /* ПОЯСНЯЮЩИЙ ПРИМЕР, не удалять */ return (buttons & mouseButtonBits[name]) !== 0;
	return Math.floor(buttons / mouseButtonBits[name]) % 2 !== 0;
};

type TUseControlsParams = $ReadOnly<{|
	gridspec: TSpecsGrid,
	ref: {| current: ?HTMLElement |},
	dispatch: Dispatch<TAction>,
	initialWidth: number,
	setWidth: ((number => number) | number) => void,
	currentDevice: TGridBreakpointName,
|}>;
type TUseControlsResult = $ReadOnly<{|
	pageState: TPageState,
	isScroll: boolean,
	isResizeLeft: boolean,
	mouseDown: (e: SyntheticMouseEvent<EventTarget>) => void,
	mouseDownResizes: $ReadOnly<{|
		left: (e: SyntheticMouseEvent<EventTarget>) => void,
		right: (e: SyntheticMouseEvent<EventTarget>) => void,
	|}>,
	mouseUp: (e: MouseEvent) => void,
	preventMenu: (e: SyntheticMouseEvent<EventTarget>) => void,
	mouseMove: (e: MouseEvent) => void,
	ownDevice: TGridBreakpointName,
|}>;
type TUseControlsHook = TUseControlsParams => TUseControlsResult;

const useControls: TUseControlsHook = ({
	gridspec,
	ref,
	dispatch,
	initialWidth,
	setWidth,
	currentDevice,
}: TUseControlsParams) => {
	// Девайс использовать из этой переменной, т.к. он может быть переключен и отсюда и извне
	const [ownDevice, setOwnDevice] = React.useState<TGridBreakpointName>(currentDevice);

	const breakpointNames = getActiveBreakpointNames({ gridspec });

	const breakpointThresholds = React.useMemo(() => {
		const breakpointAt = breakpointNames.indexOf(ownDevice);
		const breakpointBelow = breakpointNames[breakpointAt + 1] || null;
		const breakpointAbove = breakpointNames[breakpointAt - 1] || null;
		const breakpoint = breakpointNames[breakpointAt] || null;

		return {
			breakpointBelow,
			breakpointAbove,
			min: breakpointBelow
				? getBreakpointWidth(gridspec.breakpoints[breakpointBelow])
				: null,
			max: breakpoint ? getBreakpointWidth(gridspec.breakpoints[breakpoint]) : null,
		};
	}, [breakpointNames, gridspec.breakpoints, ownDevice]);

	// Показывает когда в процессе скролла зажата мышь, это влияет на курсор
	const [isScroll, setIsScroll] = React.useState<boolean>(false);

	// Показывает что ресайз производится за левый хендлер
	const [isResizeLeft, setIsResizeLeft] = React.useState<boolean>(true);

	const [pageState, setPageState] = React.useState<TPageState>('edit');

	const controlsRef = React.useRef<TControlsData>({
		...initialControls,
		width: initialWidth,
		width0: initialWidth,
	});

	React.useEffect(
		() => {
			controlsRef.current.width = initialWidth;
			controlsRef.current.width0 = initialWidth;
			setWidth(initialWidth);
		},
		// Смотреть только на изменение спеки извне
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[gridspec._id],
	);

	React.useEffect(
		() => {
			// Если это мы поменяли девайс отсюда, то он совпадёт и ничего не надо делать
			if (ownDevice !== currentDevice) {
				setOwnDevice(currentDevice);
				controlsRef.current.width = initialWidth;
				controlsRef.current.width0 = initialWidth;
				setWidth(initialWidth);
			}
		},
		// Смотреть только на изменение девайса извне
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentDevice],
	);

	const keyDown = React.useCallback(
		(e: KeyboardEvent) => {
			if (e.target !== document.body) {
				return;
			}

			let isConsumed = false;

			if (
				pageState === 'edit' &&
				e.code === 'Space' &&
				!controlsRef.current.space
			) {
				controlsRef.current.space = true;
				if (!controlsRef.current.wheel) {
					setPageState('scroll');
				}
				isConsumed = true;
			}

			if (isConsumed) {
				e.preventDefault();
				e.stopPropagation();
			}
		},
		[pageState],
	);

	const keyUp = React.useCallback(
		(e: KeyboardEvent) => {
			if (
				pageState === 'scroll' &&
				e.code === 'Space' &&
				controlsRef.current.space
			) {
				controlsRef.current.space = false;
				if (!controlsRef.current.wheel) {
					setPageState('edit');
					setIsScroll(false);
				}
			}
		},
		[pageState],
	);

	const mouseDown = React.useCallback(
		(e: SyntheticMouseEvent<EventTarget>) => {
			let isConsumed = false;

			if (
				pageState === 'edit' &&
				e.button === 1 /* wheel */ &&
				!controlsRef.current.wheel
			) {
				controlsRef.current.wheel = true;
				if (!controlsRef.current.space) {
					setPageState('scroll');
					setIsScroll(true);
				}
				isConsumed = true;
			}
			if (controlsRef.current.button === -1) {
				controlsRef.current.button = e.button;
			}

			controlsRef.current.x = e.clientX;
			controlsRef.current.x0 = e.clientX;
			controlsRef.current.y = e.clientY;
			controlsRef.current.y0 = e.clientY;

			if (isConsumed) {
				e.preventDefault();
				e.stopPropagation();
			}
		},
		[pageState],
	);

	const mouseDownResizes = React.useMemo(
		() => ({
			left: (e: SyntheticMouseEvent<EventTarget>) => {
				mouseDownResizeSide(
					e,
					'left',
					controlsRef.current,
					pageState,
					setPageState,
					setIsResizeLeft,
				);
			},
			right: (e: SyntheticMouseEvent<EventTarget>) => {
				mouseDownResizeSide(
					e,
					'right',
					controlsRef.current,
					pageState,
					setPageState,
					setIsResizeLeft,
				);
			},
		}),
		[pageState],
	);

	const mouseUp = React.useCallback(
		(e: MouseEvent) => {
			if (
				pageState === 'scroll' &&
				e.button === 1 /* wheel */ &&
				controlsRef.current.wheel
			) {
				controlsRef.current.wheel = false;
				if (!controlsRef.current.space) {
					setPageState('edit');
				}
			}

			// Если теперь ни колёсико ни левая кнопка не нажаты, поменять курсор
			if (
				pageState === 'scroll' &&
				!(
					checkMouseButton(e.buttons, 'wheel') ||
					checkMouseButton(e.buttons, 'left')
				)
			) {
				setIsScroll(false);
			}

			if (pageState === 'resize' && e.button === 0 /* left */) {
				setPageState('edit');
			}

			// Проверяет что отпущена левая кнопка, и именно она изначально была нажата
			if (
				e.target === ref.current &&
				controlsRef.current.button === e.button &&
				e.button === 0 /* left */
			) {
				// if it was a click
				const dx = controlsRef.current.x - controlsRef.current.x0;
				const dy = controlsRef.current.y - controlsRef.current.y0;
				if (dx * dx + dy * dy < CLICK_THRESHOLD_SQUARED) {
					dispatch(resetEdit());
				}
				controlsRef.current.button = -1;
			}
		},
		[dispatch, pageState, ref],
	);

	const preventMenu = React.useCallback((e: SyntheticMouseEvent<EventTarget>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const wheel = React.useCallback((e: WheelEvent) => {
		// e.deltaMode === 0 -> delta in pixels
		// https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent/deltaMode
		if (e.ctrlKey && e.deltaMode === 0x00) {
			// setScale(scale => Math.max(0.5, Math.min(2, scale - e.deltaY * 0.001)));
			e.preventDefault();
			e.stopPropagation();
		}
	}, []);

	const mouseMove = React.useCallback(
		(e: MouseEvent) => {
			if (!ref.current) {
				return;
			}
			const el: HTMLElement = ref.current;
			const controls: TControlsData = controlsRef.current;

			const dx = e.clientX - controls.x;
			const dy = e.clientY - controls.y;
			let isConsumed = false;

			if (
				pageState === 'scroll' &&
				(checkMouseButton(e.buttons, 'wheel') ||
					checkMouseButton(e.buttons, 'left'))
			) {
				if (!isScroll) {
					setIsScroll(true);
				}
				el.scrollTo(
					Math.floor(el.scrollLeft - dx),
					Math.floor(el.scrollTop - dy),
				);
				isConsumed = true;
			}

			if (pageState === 'resize') {
				if (!checkMouseButton(e.buttons, 'left')) {
					setPageState('edit');
				} else {
					let finalWidth =
						controls.width0 +
						(controls.x - controls.x0) * (isResizeLeft ? -2 : 2);
					let nextDevice: ?TGridBreakpointName = null;

					if (
						breakpointThresholds.min &&
						breakpointThresholds.min >= finalWidth
					) {
						nextDevice = breakpointThresholds.breakpointBelow;
					} else if (
						breakpointThresholds.max &&
						breakpointThresholds.max <= finalWidth
					) {
						nextDevice = breakpointThresholds.breakpointAbove;
					}

					if (MIN_BREAKPOINT_WIDTH >= finalWidth)
						finalWidth = MIN_BREAKPOINT_WIDTH;

					if (nextDevice) {
						setOwnDevice(nextDevice);
						dispatch(updateEditor({ currentDevice: nextDevice }));
					}

					controls.width = finalWidth;
					setWidth(finalWidth);
				}

				isConsumed = true;
			}

			controls.x = e.clientX;
			controls.y = e.clientY;

			if (isConsumed) {
				e.preventDefault();
				e.stopPropagation();
			}
		},
		[
			ref,
			pageState,
			isScroll,
			setWidth,
			isResizeLeft,
			breakpointThresholds.max,
			breakpointThresholds.min,
			breakpointThresholds.breakpointBelow,
			breakpointThresholds.breakpointAbove,
			setOwnDevice,
			dispatch,
		],
	);

	React.useEffect(() => {
		if (document.body) {
			document.body.tabIndex = 0;
		}
		// https://github.com/inuyaksa/jquery.nicescroll/issues/799
		// https://developers.google.com/web/updates/2017/01/scrolling-intervention
		window.addEventListener('wheel', wheel, { passive: false });
		document.addEventListener('keydown', keyDown);
		document.addEventListener('keyup', keyUp);
		return () => {
			document.removeEventListener('keydown', keyDown);
			document.removeEventListener('keyup', keyUp);
			window.removeEventListener('wheel', wheel);
		};
	}, [mouseUp, keyDown, keyUp, wheel]);

	return {
		pageState,
		isScroll,
		isResizeLeft,
		mouseDown,
		mouseDownResizes,
		mouseUp,
		preventMenu,
		mouseMove,
		ownDevice,
	};
};

export default useControls;
