// @flow

import React from 'react';
import { ToolbarBtn, PopupWhite } from '@graphite/uneon';
import type {
	TWidget,
	TWidgetDiff,
	TPositionValue,
	TGridBreakpointName,
} from '@graphite/types';

import Position from './Position';

type TProps = $ReadOnly<{|
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
	unit: number,
	position: TPositionValue,
	data: TWidget,
	currentDevice: TGridBreakpointName,
	save: TWidgetDiff => void,
	currentRef: {| current: ?HTMLDivElement |},
|}>;

// const paramList: TParams = [
// 	{
// 		title: 'Width',
// 		key: 'width',
// 		kind: 'unit',
// 		info: {
// 			showUnits: true,
// 			unitKey: 'widthUnit',
// 			units: ['px', 'unit', '%'],
// 		},
// 	},
// 	{
// 		title: 'Height',
// 		key: 'height',
// 		kind: 'unit',
// 		info: {
// 			showUnits: true,
// 			unitKey: 'heightUnit',
// 			units: ['px', 'unit', '%'],
// 		},
// 	},
// 	{
// 		title: 'Rotation',
// 		key: 'rotation',
// 		kind: 'unit',
// 		info: {
// 			showUnits: true,
// 			unitKey: 'rotationUnit',
// 		},
// 	},
// ];

const ToolbarLayoutButton = ({
	openedPanel,
	setOpenedPanel,
	unit,
	save,
	data,
	position,
	currentDevice,
	currentRef,
}: TProps) => {
	const btnEl = React.useRef(null);
	const handleToogleOpen = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel => (openedPanel === 'layout' ? null : 'layout'));
		},
		[setOpenedPanel],
	);
	const disableActive = React.useCallback(
		(e: MouseEvent) => {
			e.preventDefault();

			setOpenedPanel(null);
		},
		[setOpenedPanel],
	);

	// const paramSource: TParamSource = React.useMemo(
	// 	() => ({
	// 		width: '1',
	// 		widthUnit: 'px',
	// 		height: '1',
	// 		heightUnit: 'px',
	// 		rotation: '0',
	// 		rotationUnit: 'deg',
	// 	}),
	// 	[],
	// );

	const isActive = openedPanel === 'layout';

	// const changeParam = React.useCallback(
	// 	(key, changed) => {
	// 		save({ [key]: changed });
	// 	},
	// 	[save],
	// );

	// const clickUnit: TListParamsOnClick = React.useCallback(
	// 	(e, key: string, name: ?TActiveButtons) => {
	// 		if (name !== '%' && name !== 'px' && name !== 'auto') {
	// 			return;
	// 		}
	// 		const unitNext: TUnit = name;
	// 		const param = paramList.find(p => p.key === key);
	// 		if (!param || param.kind !== 'unit' || !param.info.unitKey) {
	// 			return;
	// 		}

	// 		const unitPath: string = param.info.unitKey;
	// 		const unitPrev: TUnit = _.get(unitPath, paramSource);
	// 		if (unitPrev === unitNext) {
	// 			return;
	// 		}

	// 		const updated = _.set(unitPath, unitNext, {});

	// 		// TODO: default sizes

	// 		save(updated);
	// 	},
	// 	[paramSource, save],
	// );

	return (
		<>
			<ToolbarBtn
				onMouseDown={handleToogleOpen}
				iconName="layout"
				ref={btnEl}
				isActive={isActive}
			/>
			<PopupWhite
				isOpen={isActive}
				onClose={disableActive}
				anchorEl={btnEl}
				offsetTop={10}
				isFixed
				mutex="settings"
			>
				{isActive && (
					<>
						<Position
							data={data}
							position={position}
							save={save}
							unit={unit}
							currentRef={currentRef}
							currentDevice={currentDevice}
						/>
						{/* <ListParams
							listName="layout"
							paramSource={paramSource}
							paramList={paramList}
							unit={1}
							onChange={changeParam}
							onClick={clickUnit}
						/> */}
					</>
				)}
			</PopupWhite>
		</>
	);
};

export default React.memo<TProps>(ToolbarLayoutButton);
