// @flow
import { css } from '@emotion/core';

const GlobalStyle = css`
	[data-draggable='true'] {
		background: rgba(41, 155, 255, 0.2); !important;
		border-color: transparent !important;
		color: transparent !important;
		pointer-events: none !important;
		transition: opacity 0.15s ease-out !important;

		&[draggable] {
			pointer-events: auto !important;
		}

		&[data-drag-panel='true'] {
			min-height: 50px;
			min-width: 50px;
		}

		*,
		&:before,
		&:after {
			pointer-events: none !important;
			opacity: 0 !important;
		}

		&[data-drop-over='true'] {
			opacity: 0 !important;
			transition-duration: 0.3s !important;
			transition-delay: 0.2s !important;
		}

		&[data-drag-source='true'] {
			flex-grow: 0 !important;
			flex-shrink: 0 !important;
			padding: 0 !important;
			border: 0 !important;
			box-sizing: content-box !important;
			pointer-events: auto !important;
			opacity: 0 !important;
		}

		&[data-drag-hidden='true'] {
			opacity: 0 !important;
		}
	}

	/* FixMe: Move it to symbiote */
	[data-drag-stack] {
		position: relative;

		&:after {
			content: '';
			pointer-events: none;
			position: absolute;
			top: -30px;
			left: 6px;
			padding: 0 9px;
			height: 24px;
			background: #299BFF;
			color: #fff;
			font: 500 13px/24px 'Avenir Next Cyr', sans-serif;
			border-radius: 99px;
			opacity: 0;	
			box-shadow:  	0px 0px 1px 0px rgba(0, 0, 0, 0.12),
							0px 4px 12px 0px rgba(41, 155, 255, 0.07),
							0px 8px 21px 3px rgba(0, 0, 0, 0.02);
		}

		&:before {
			content: '';
			pointer-events: none;
			white-space: nowrap;
			overflow: hidden;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(41, 155, 255, 0.33);
			border: 2px solid #299BFF;
			opacity: 0;
		}

		&[data-drag-stack='add-to-stack']:after,
		&[data-drag-stack='create-stack']:after,
		&[data-drag-stack='add-to-stack']:before,
		&[data-drag-stack='create-stack']:before {
			opacity: 1;
			transition: opacity 0.15s 0.05s ease-out;
		}

		&[data-drag-stack='add-to-stack']:after {
			content: 'Add To Stack';
		}
		&[data-drag-stack='create-stack']:after {
			content: 'Create Stack';
		}
	}
`;

export default GlobalStyle;
