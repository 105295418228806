// @flow

import React from 'react';
import _ from 'lodash/fp';
import { Flex } from '@graphite/uneon';
import getDisplayName from '@graphite/get-display-name';

import { getContainerSx } from './getContainerSx';
import { getRowSx } from './getRowSx';

import type { TContainerProps } from '../constants/types';

const withContainer = (Component: React$ComponentType<TContainerProps>) => {
	const WithContainer = (props: TContainerProps, ref) => {
		const { data, gridspec, onClick } = props;

		const containerBoxSx = React.useMemo(
			() =>
				getContainerSx({
					data,
					gridspec,
				}),
			[data, gridspec],
		);

		const containerSx = React.useMemo(
			() =>
				_.assign(
					{
						position: 'relative',
						flexWrap: 'wrap',
					},
					containerBoxSx,
				),
			[containerBoxSx],
		);

		const rowBoxSx = React.useMemo(
			() =>
				getRowSx({
					data,
					gridspec,
				}),
			[data, gridspec],
		);

		const rowSx = React.useMemo(
			() =>
				_.assign(
					{
						position: 'relative',
						flexWrap: 'wrap',
					},
					rowBoxSx,
				),
			[rowBoxSx],
		);

		// Объединил с Row, т.к получается одинаковый код, и лишний однотипный HOC
		return (
			<Flex sx={containerSx} data-kind="block-container">
				<Flex sx={rowSx} ref={ref} onMouseDown={onClick} data-kind="block-row">
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<Component {...props} />
				</Flex>
			</Flex>
		);
	};

	WithContainer.displayName = `withContainer(${getDisplayName(Component)})`;

	return React.memo<TContainerProps>(React.forwardRef(WithContainer));
};

export default withContainer;
