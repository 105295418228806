// @flow
import Transport from 'winston-transport';

import type { TLogEntry, TCallback } from '../types';

type TOpts = $ReadOnly<{|
	logEvent: (string, Object) => void,
	level: string,
|}>;

class AnalyticsTransport extends Transport {
	constructor(opts: TOpts) {
		const { logEvent, ...otherOpts } = opts;
		super(otherOpts);

		this.logEvent = logEvent;
	}

	log(logEntry: TLogEntry, callback: TCallback) {
		const { message, meta = {} } = logEntry;

		this.logEvent(message, meta);
		return callback(null, true);
	}
}

export default AnalyticsTransport;
